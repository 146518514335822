import React from "react"
import {useMeQuery} from "lib/src/generated";
import Login from "./pages/Login";
import {Route} from "react-router-dom";
import {RouteProps} from "react-router";

const UserRoute = ({children, ...props}: RouteProps) => {
    const {data} =  useMeQuery();
    if (!data || !data.me) return <Login/>
    return <Route {...props}>{children}</Route>
}
export default UserRoute

