import React, { memo } from 'react';
import {WrappedProps} from "./Table/TableWrapper";
import './directory-cards.scss'
import DirectoriesCards, {DirectoriesCard} from "./DirectoriesCards";
import VoteButton from "./VoteButton";
import {useMeQuery} from "lib/src/generated";
import SpinnerContainer from "./SpinnerContainer";


export default function DirectoryNFPCards({isVotesList,...props}: WrappedProps & {isVotesList?: boolean}) {
    const {data, loading}=useMeQuery()
  return <SpinnerContainer loading={loading}>
      <DirectoriesCards {...props}
                        loading={loading}
                        ActionComponent={
        (user, index) => <DirectoriesCard
          isVotesList={isVotesList}
          key={index}
          // @ts-ignore
          totalVotes={data && data.me && data.me.totalVotes}
          user={user}
          showVoteBtn={data &&  data.me && data.me.type && data.me.type.indexOf('Individual')>=0 }
          ActionComponent={memo((user) => {
            return <>
              <VoteButton key={1} {...user} isVotesList={isVotesList}/>
              Votes
            </>;
          })}/>
    }/>
    </SpinnerContainer>
}


