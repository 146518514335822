import React, {lazy, Suspense, useEffect, useRef} from 'react';
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import {ToastProvider} from 'react-toast-notifications';
import {ApolloProvider} from "@apollo/client/react/context/ApolloProvider";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import UserRoute from "./UserRoute";
import AdminRoute from "./AdminRoute";
import LayoutPage from "./Components/Layouts/Admin";
import InstallAppButton from "./InstallAppButton";
import AddPWAIosInstructions from "./Components/AddPWAIosInstructions";
import confirmModal from "./Components/common/ConfirmModal";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import DirectoryNFP from "./pages/user/directoryNFP/DirectoryNFP";

const SalesNew = lazy(() => import("./pages/user/sales/SalesNew"));
const AdminVotes = lazy(() => import("./pages/admin/AdminVotes"));
const VerifyToken = lazy(() => import("./pages/VerifyToken"));
const ProfileUpdate = lazy(() => import("./pages/user/account/ProfileUpdate"));
const Users = lazy(() => import("./pages/admin/Users"));
const User = lazy(() => import('./pages/admin/User'));
const Directory = lazy(() => import("./pages/user/directory/Directory"));
const UserNew = lazy(() => import("./pages/admin/UserNew"));
const TaxNew = lazy(() => import("./pages/admin/TaxNew"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const PasswordForgot = lazy(() => import("./pages/PasswordForgotten"));
const PasswordReset = lazy(() => import("./pages/PasswordReset"));
const PasswordNew = lazy(() => import("./pages/PasswordNew"));

const PasswordUpdate = lazy(() => import("./pages/user/account/PasswordUpdate"));
const Purchases = lazy(() => import("./pages/user/purchases/Purchases"));
const Sales = lazy(() => import("./pages/user/sales/Sales"));
const Redirector = lazy(() => import("./pages/Redirector"));


function App({client}: any) {
    const time = useRef(Date.now());
    useEffect(() => {
        serviceWorkerRegistration.register({
            onSuccess(registration) {
                console.log('serviceWorkerRegistration success')
            },
            onUpdate(registration) {
                console.log('serviceWorkerRegistration updated',Date.now()-time.current)
                const  refresh=async ()=>{
                    if (!!registration?.waiting){ //if we send 2 updates, and the user windows is opened, then his going the get the first popup alert, and a second, but registration.waiting is not more existing
                        await registration?.waiting.postMessage({type: 'SKIP_WAITING'});
                    }
                    if ('caches' in window) {
                        const names = await caches.keys()
                        for (const name of names) {
                            await caches.delete(name)
                        }
                    }
                    window.location.reload();
                }
                if (Date.now()-time.current<=2000){
                    return refresh()
                }
                confirmModal({
                    backdropClassName: 'black-backdrop',
                    title: 'An update is available',
                    content: <p>
                        Please click refresh to progress</p>,
                    okText: 'Refresh',
                    cancelText: '',
                    onConfirm: refresh
                }).render()
            }
        })
    }, [])
    useEffect(() => {
        import("./pages/user/sales/SalesNew").catch(console.warn)
        import("./pages/admin/AdminVotes").catch(console.warn)

        import("./pages/Redirector").catch(console.warn)
        import("./pages/VerifyToken").catch(console.warn)
        import("./pages/user/account/ProfileUpdate").catch(console.warn)
        import("./pages/admin/Users").catch(console.warn)
        import('./pages/admin/User').catch(console.warn)
        import("./pages/user/directory/Directory").catch(console.warn)
        import("./pages/admin/UserNew").catch(console.warn)
        import("./pages/admin/TaxNew").catch(console.warn)
        import("./pages/Login").catch(console.warn)
        import("./pages/Register").catch(console.warn)
        import("./pages/PasswordForgotten").catch(console.warn)
        import("./pages/PasswordReset").catch(console.warn)
        import("./pages/PasswordNew").catch(console.warn)
        import("./pages/user/account/PasswordUpdate").catch(console.warn)
        import("./pages/user/purchases/Purchases").catch(console.warn)
        import("./pages/user/sales/Sales").catch(console.warn)
    }, []);
    console.log('process.env.NODE_ENV',process.env.NODE_ENV)
    return (
        <>
            <InstallAppButton/>
            {/*// @ts-ignore*/}
            <Router>
                <Switch>
                    <Route path="/pwa" component={AddPWAIosInstructions}/>
                    <ToastProvider autoDismiss autoDismissTimeout={4000}>
                        <ApolloProvider client={client}>
                            {/*// @ts-ignore*/}
                            <LayoutPage>
                                <Suspense fallback={<Spinner animation={'border'}/>}>
                                    <Switch>
                                        <Route path="/" component={Redirector} exact/>
                                        <Route path="/directory" component={Directory} exact/>
                                        <Route path="/directory-not-for-profit" component={DirectoryNFP} exact/>
                                        <Route path="/directory-not-for-profit/:tab(votes)" component={DirectoryNFP} exact/>
                                        <Route path="/directory/:tab(favourites)" component={Directory}/>
                                        <Route path="/directory" component={Directory}/>
                                        <Route path="/sign-in" component={Login}/>
                                        <Route path="/register" component={Register}/>
                                        <Route path="/password-forgotten" component={PasswordForgot}/>
                                        <Route path="/password-reset/:token" component={PasswordReset}/>
                                        <Route path="/password-new/:token" component={PasswordNew}/>
                                        <Route path="/verify-token/:tokenCode" component={VerifyToken}/>
                                        <UserRoute path="/member">
                                            <Switch>
                                                <Route path="/member/account/password-update"
                                                       component={PasswordUpdate}/>
                                                <Route path="/member/account/profile-update"
                                                       component={ProfileUpdate}/>
                                                <Route path="/member/purchases" component={Purchases}/>
                                                <Route path="/member/sales/new" component={SalesNew}/>
                                                <Route path="/member/sales">
                                                    <p>This page records the sales you have made to members. It also
                                                        shows the community contributions due to
                                                        the Cooperative.</p>
                                                    <p className={'text-center text-sm-right'}>
                                                        <Link to={'/member/sales/new'}>
                                                            <Button variant={'primary'}>Add A New Sale</Button>
                                                        </Link>
                                                    </p>
                                                    <Sales/>
                                                </Route>

                                            </Switch>
                                        </UserRoute>
                                        <AdminRoute path="/admin">
                                            <Switch>
                                                <Route path="/admin/members/new" component={UserNew}/>
                                                <Route path="/admin/votes" component={AdminVotes}/>
                                                <Route path="/admin/member/:userId/tax/new" component={TaxNew}/>
                                                <Route path="/admin/member/:userId/:tab(update|email)" component={User}/>
                                                <Route path="/admin/member/:userId" component={User}/>
                                                <Route path="/admin/members" component={Users}/>
                                            </Switch>
                                        </AdminRoute>
                                    </Switch>
                                </Suspense>
                            </LayoutPage>
                        </ApolloProvider>
                    </ToastProvider>
                </Switch>
            </Router>


        </>

    );
}

export default App;



