import React, {useRef} from 'react';
import Auth from '../Components/Auth/AuthContainer';
import {LoginInput, useLoginMutation} from 'lib/src/generated';
import MyAutoForm from '../Components/common/MyAutoForm';
import loginBridge from 'lib/src/bridges/login/loginBridge';
import {Link, Redirect, useHistory,} from 'react-router-dom';
import {AutoField, ErrorsField, SubmitField} from 'uniforms-bootstrap4';
import {redirect} from '../Components/Auth/util';
import {useMeQuery} from "lib/src/generated";
import RecaptchaField from "../Components/common/RecaptchaField";
import ReCAPTCHA from "react-google-recaptcha";
import {MeDocument} from "lib/src/generated";

function Login() {
    const [login, {loading}] = useLoginMutation({
        refetchQueries: [{query: MeDocument}],
        update(cache, { data: { login: user } }) {
            cache.writeQuery({
                query: MeDocument,
                data: {me:user}
            });
        }
    });
    const recaptcha = useRef<ReCAPTCHA>();
    const history = useHistory();
    const {data}=useMeQuery()
    if (data && data.me && data.me.id) return <Redirect to={'/'}/>
    return (
        <Auth title="Sign In" subTitle="Hello! sign in with your Email or Member ID">
            <MyAutoForm
                loading={loading}
                onSubmit={async (model: LoginInput) => {
                    recaptcha.current && recaptcha.current.reset()
                    const {data} = await login({variables: {data: model}});
                    if (!data || !data.login) return
                    setTimeout(async () => await redirect(data.login, history));
                }}
                schema={loginBridge}
            >
                <AutoField label={'Member ID or Email'}  name={'username'}/>
                <AutoField name={'password'} type={'password'}/>
                <p className={'text-center'}>
                    <RecaptchaField name={'recaptcha'} innerRef={recaptcha}/>
                </p>
                <ErrorsField/>
                <SubmitField value={'Sign In'} inputClassName={'btn btn-primary btn-block'} />
            </MyAutoForm>
            <br/>
            <p>
                Don&apos;t have account?{' '}
                <Link to="/register">
                    Register
                </Link>
            </p>
            <p>
                Forgot your password?{' '}
                <Link to="/password-forgotten">
                    Reset Password
                </Link>
            </p>
        </Auth>
    );
}

export default Login
