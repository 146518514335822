import React, {useEffect, useRef, useState} from "react";
import {Col, Form, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {connectField} from "uniforms";
import {AutoField, wrapField} from "uniforms-bootstrap4";
import {BiCurrentLocation} from "react-icons/bi";
import {useJsApiLoader} from '@react-google-maps/api';
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {Plugins} from "@capacitor/core";
import {reverseGeoCode} from "../../pages/user/directory/DistanceSearchField";
import './address-field.scss'
import {useToasts} from "react-toast-notifications";
import classnames from "classnames";
import {VscEdit} from "react-icons/vsc";

export const libraries: Libraries = ["places"]


interface AddressFieldProps {
    onChange,
    id,
    error,
    name,
    disabled,
    value,
    className?: string,
    showUnit?: boolean
    showNumber?: boolean
    showStreet?: boolean
    showSuburb?: boolean
    showState?: boolean
    showZip?: boolean
    formControlLike?: boolean
    options?: google.maps.places.AutocompleteOptions
    currentLocation?: boolean
    placeholder?: string
}

function AddressField({
                          onChange,
                          id,
                          error,
                          name,
                          disabled,
                          value,
                          className,
                          placeholder='Enter a location',
                          currentLocation = true,
                          showUnit = true,
                          showNumber = true,
                          showStreet = true,
                          showSuburb = true,
                          showState = true,
                          showZip = true,
                          formControlLike = false,
                          options = {types: ["address"], componentRestrictions: {country: "au"}},
                          ...props
                      }: AddressFieldProps) {
    const autoComplete = useRef(null);
    const currentLocationAvailable = window.location.protocol === 'https:'
    function handleScriptLoad(onChange, query, autoCompleteRef) {
        autoComplete.current = new window.google.maps.places.Autocomplete(autoCompleteRef.current, options);
        autoComplete.current.setFields(["place_id", "geometry", "types", "name", "address_components", "formatted_address"]);
        autoComplete.current.addListener("place_changed", () => {
            handlePlaceSelect(onChange, query)
        });
    }


    function handlePlaceSelect(onChange, query) {
        const addressObject = autoComplete.current.getPlace();
        const result = buildResult(addressObject, query)
        onChange(result);
    }


    const autoCompleteRef = useRef(null);
    const {isLoaded} = useJsApiLoader({
        id: 'Map',
        libraries,
        googleMapsApiKey: "AIzaSyDREfT4Rypm8BD2TqwK-8tAHvAQ46Nswbg"
    })
    const {street, suburb, state, zip, country, address} = value || {}
    useEffect(() => {
        if (isLoaded && autoCompleteRef.current) {
            handleScriptLoad((result: any) => {
                onChange(result)
            }, address, autoCompleteRef)
        }
    }, [isLoaded, autoCompleteRef.current]);

    return (wrapField({error, id, disabled, value, ...props},
            <Form.Group>
                {!country &&
                <InputGroup>
                    <Form.Control
                        type={'search'}
                        isInvalid={!!error}
                        disabled={!isLoaded || disabled}
                        ref={autoCompleteRef}
                        onChange={(event) => {
                            if (event.target.value) {
                                onChange({__: null})
                            } else {
                                onChange(undefined)
                            }
                            onChange({address: event.target.value})
                        }}
                        placeholder={placeholder}
                        className={className}
                        value={address}
                        name={name}
                        id={id}
                    />
                    <InputGroup.Append>
                        {currentLocation && currentLocationAvailable && <CurrentPosition onChange={onChange}/>}
                    </InputGroup.Append>
                </InputGroup>}

                {country && <div className={'form-inline'}>
                    <Form.Row className={formControlLike && 'form-control-like'}>
                        <Col>
                            {showUnit && <><AutoField className={classnames('text-inline', className)}
                                                      placeholder={'Unit #'} label={false}
                                                      name={'unit'}/>
                                /</>}
                            {showNumber &&
                            <AutoField className={classnames('text-inline', className)} placeholder={'St #'}
                                       label={false} name={'number'}/>}
                            <div className={classnames('inline text-address edit', className)}
                                 onClick={() => onChange(undefined)}>
                                {[showStreet && street, showSuburb && suburb, showState && state, showZip && zip].filter(Boolean).join(', ')}
                                <VscEdit/>
                            </div>
                            {/*<OverlayTrigger*/}
                            {/*    overlay={*/}
                            {/*        <Tooltip id={`tooltip`}>*/}
                            {/*            Restart*/}
                            {/*        </Tooltip>*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    <button onClick={() => onChange(undefined)}*/}
                            {/*            className={formControlLike ? 'form-control-like' : 'btn'}>*/}
                            {/*        <VscTrash color={'red'}/>*/}
                            {/*    </button>*/}
                            {/*</OverlayTrigger>*/}
                        </Col>
                    </Form.Row>
                </div>}
            </Form.Group>
        )

    );
}

const CurrentPosition = ({onChange}) => {
    const [loading, setLoading] = useState(false)
    const {addToast} = useToasts()
    return <OverlayTrigger
        overlay={
            <Tooltip id={`tooltip`}>
                Use your current location
            </Tooltip>
        }
    >
        <button
            type={'button'}
            aria-label={'Get current position'}
            className={'form-control-like'}
            onClick={(e) => {
                setLoading(true)
                Plugins.Geolocation.getCurrentPosition({enableHighAccuracy: false})
                    .then(({coords: {latitude: lat, longitude: lng}}) => {
                        return reverseGeoCode({lat, lng})
                    })
                    .then(address => {
                        onChange(address)
                        setTimeout(() => setLoading(false))
                    })
                    .catch((e) => {
                        addToast(e.message, {appearance: 'error'})
                        setLoading(false)
                    })
            }}><BiCurrentLocation className={loading ? 'blink' : ''}/></button>
    </OverlayTrigger>
}
export const buildResult = (place: google.maps.GeocoderResult, fullText?: string) => {
        const componentForm = {
            subpremise: 'short_name',
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            colloquial_area: 'long_name',
            sublocality: "sublocality",
            sublocality_level_1: "sublocality",
            administrative_area_level_1: 'short_name',
            administrative_area_level_2: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };
        const result = {};
        for (let i = 0; i < place.address_components.length; i++) {
            for (const addressType of place.address_components[i].types) {
                if (componentForm[addressType]) {
                    result[addressType] = place.address_components[i][componentForm[addressType]] || '';
                }
            }
        }
        if (fullText && !result['subpremise']) {
            const {unit, number} = getUnitNumber(fullText, result['street_number'], result['route']);
            result['street_number'] = number;
            result['subpremise'] = unit
        }
        const {lat, lng} = place.geometry.location.toJSON();
        let suburb = result["locality"] || result["sublocality"] || result["sublocality_level_1"];
        let city = result["administrative_area_level_2"] || result["locality"] ||  result["colloquial_area"];
        if (!city && suburb) {
            city = suburb;
            suburb = undefined
        }
        const finalResult = {
            address: place.formatted_address,
            unit: result['subpremise'],
            number: result['street_number'],
            street: result['route'],
            city,
            zip: result["postal_code"],
            suburb,
            country: result["country"],
            state: result["administrative_area_level_1"],
            lat,
            lng,
            placeId: place.place_id,
        }
        return finalResult
    }
;

const getUnitNumber = (fullText: string, number: string, street: string) => {
        if (!number || !street) return {number};
        const regex = RegExp("^([^/\\- ]*)[/\\- ]?(.*) " + street); // get all the user entered values before a match with the street name; group #1 = unit number, group #2 = street number
        const results = regex.exec(fullText);
        if (Array.isArray(results)) { // it was in unit number/street number format
            if (number) {
                return {unit: results[1], number}
            } else {
                return {unit: results[1], number: results[2]}
            }
        }
        return {number}

    }
;


// @ts-ignore
export default connectField(AddressField, {
    initialValue: undefined,
    kind: "node"
});
