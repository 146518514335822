import React from 'react'
import {IoIosContact} from "react-icons/io";
import {OverlayTrigger, Popover} from "react-bootstrap";
import CellAccessibility from "./CellAccessibility";

function ContactDetailButton({accessibility}) {
    const popover = (
        <Popover id={accessibility.id}>
            <Popover.Title as="h3">Contact Details</Popover.Title>
            <Popover.Content>
                <CellAccessibility accessibility={accessibility}/>
            </Popover.Content>
        </Popover>
    );

    return <OverlayTrigger trigger="click" placement="left" rootClose rootCloseEvent={'mousedown'} overlay={popover}>
        <IoIosContact size={40}/>
    </OverlayTrigger>


}

export default ContactDetailButton