import React from 'react';
import {AutoForm, SubmitField, SubmitFieldProps} from 'uniforms-bootstrap4';
import SpinnerContainer from './SpinnerContainer';

const MyAutoForm = ({schema, innerRef, model, submitText = 'Submit', loading, ...props}: any) => {
    model = removeEmpty(model || {})
    return (
        <SpinnerContainer loading={loading}>
            <AutoForm
                ref={innerRef}
                disabled={loading}
                // @ts-ignore
                submitField={(props: SubmitFieldProps) => <SubmitField {...props} value={'Save'}/>}
                id={schema.name}
                schema={schema}
                model={model}
                {...props}
            />
        </SpinnerContainer>
    );
};
export default MyAutoForm;

function removeEmpty(obj: any): any {
    return Object.entries(obj)
        .filter(([_, v]) => v != null)
        .reduce(
            (acc, [k, v]) => ({...acc, [k]: v === Object(v) ? removeEmpty(v) : v}),
            {}
        );
}
