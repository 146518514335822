import React, {memo, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap';
import {VscClose} from "react-icons/vsc";
import AddPWAIosInstructions from "./Components/AddPWAIosInstructions";


let deferredPrompt;


function InstallAppButton() {
    const isSafariIos = /iphone|ipad|ipod|macintosh/.test(window.navigator.userAgent.toLowerCase()) && navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
    const [showInstallPromotion, setShowInstallPromotion] = useState(isSafariIos);
    const [showIosInstructions, setShowIosInstructions] = useState(false);

    useEffect(() => {
        function beforeInstallPrompt(e) {
            e.preventDefault();
            deferredPrompt = e;
            setShowInstallPromotion(true);
        }

        function appInstalled() {
            deferredPrompt = null;
            setShowInstallPromotion(false);
        }

        window.addEventListener('beforeinstallprompt', beforeInstallPrompt)
        window.addEventListener('appinstalled', appInstalled)
        return () => {
            window.removeEventListener('beforeinstallprompt', beforeInstallPrompt)
            window.removeEventListener('appinstalled', appInstalled)
        }
    }, []);
    const installApp = async () => {
        if (!deferredPrompt) return
        deferredPrompt.prompt();

        await deferredPrompt.userChoice;
        // const { outcome } = await deferredPrompt.userChoice;
        deferredPrompt = null;
    }
    if (window.matchMedia('(display-mode: standalone)').matches) return null
    if (!showInstallPromotion) return null

    if (isSafariIos) {
        return <div className={'install-app-container'}>
            <div className={'install-app-add'} onClick={()=>{
                setShowIosInstructions(true)
            }}>See how add Fractal app to your phone</div>
            <div className={'install-app-cancel'}  aria-label={'Close install app banner'}>
                <Button variant={'link'} onClick={() => setShowInstallPromotion(false)}>
                    <VscClose size={25} color={'red'}/>
                </Button>
            </div>
            <Modal show={showIosInstructions} fullscreen onHide={() => setShowIosInstructions(false)}>
                <Modal.Header closeButton placeholder>
                    <Modal.Title>Adding to your home screen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddPWAIosInstructions/>
                </Modal.Body>
            </Modal>
        </div>
    }

    return <div className={'install-app-container'}>
        <div className={'install-app-add'} onClick={installApp}>Add Fractal app to your home screen</div>
        <div className={'install-app-cancel'}>
            <Button variant={'link'} onClick={() => setShowInstallPromotion(false)} aria-label={'Close install app banner'}>
                <VscClose size={25} color={'red'}/>
            </Button>
        </div>
    </div>
}

export default memo(InstallAppButton)
