import React, {CSSProperties, ReactNode} from 'react';
import {Card, Col, Row, Spinner} from "react-bootstrap";
import {VscInbox} from "react-icons/vsc";
import {WrappedProps} from "./TableWrapper";
import {ShowItem} from "./Table";


type AccessorType = string | ((val: any) => ReactNode)

export type FieldType = {
    label: ReactNode | ((val: any) => ReactNode)
    style?: CSSProperties,
    field: string,
    type: string,
    render?: AccessorType
    [other: string]: any
}


function Rows({fields, data, mocking}: { fields: FieldType[], data: any, mocking?: boolean }) {
    if (!data) return null
    return data.map((item: any, index) => {
        const [first, ...rest] = fields
        return (
            <Card style={{marginBottom: 15}} key={index}>
                <Card.Body>
                    <Card.Title><ShowItem item={item} field={first}/></Card.Title>
                    <Card.Text as={'div'}>
                        {rest.map((field, index) =>
                            <div key={index}>
                                {mocking && <Spinner animation="border"/>}
                                <b>{field.plainLabel || field.label}: </b>
                                <ShowItem item={item} field={field}/>
                                <br/>
                            </div>)}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    })
}

export default function Cards({loading, page = 50, data, total, fields}: WrappedProps) {
    const len = Math.min(page, data ? total - data.length : page) || 1
    const mock = new Array(len).fill(data && data[0]);
    return <>
        {<Rows data={data} fields={fields}/>}
        {loading && <Rows data={mock} mocking fields={fields}/>}
        {data && data.length === 0 && !loading &&
        <Row className={'no-data'}>
            <Col>
                <b>No Data</b>
                <br/>
                <VscInbox size={60}/>
            </Col>
        </Row>}
    </>
}



