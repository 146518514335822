import React from "react"
import {TransactionOrderByWithRelationInput, useVotesQuery} from "lib/src/generated";
import {getRowsAndTotal, Wrapper} from "../../../Components/common/Table/TableWrapper";
import DirectorySearch from "./DirectorySearch";
import {directoryNFPFields} from "./directoryNFPFields";
import DirectoryNFPCards from "../../../Components/common/DirectoryNFPCards";


const Votes = () => {
  const orderBy = {id: 'asc'} as TransactionOrderByWithRelationInput
  const {data, ...queryResult} = useVotesQuery({
    variables: {take: 25, orderBy, skip: 0},
    fetchPolicy: 'cache-and-network',
  });
  const {rows, total} = getRowsAndTotal(data)
  return (
    <>
      <DirectorySearch {...queryResult}/>
      <Wrapper noFilter Component={(props)=><DirectoryNFPCards isVotesList {...props}/>} fields={directoryNFPFields} rows={rows} total={total} {...queryResult} />
    </>
  );
}
export default Votes

//
// const fields: FieldFilterType[] = [
//     {
//         field: 'username',
//         plainLabel: 'Member ID',
//         label: defaultLabel,
//         type: 'String',
//         style: {width: 200},
//     },
//     {
//         field: 'name',
//         label: defaultLabel,
//         plainLabel: `Seller's Name`,
//         type: 'String',
//         style: {width: 200},
//     },
//     {field: 'description', label: defaultLabel, plainLabel: 'Description', type: 'String'},
//     {
//         field: 'accessibility', label: 'Accessibility', plainLabel: 'Address', type: 'String',
//         render: CellAccessibility,
//     },
//     {
//         noFilter: true,
//         field: 'favored.id',
//         label: defaultLabel,
//         plainLabel: 'Fav',
//         type: 'Boolean', style: {width: 90},
//         render: FavoriteButton
//     },
// ]
//
