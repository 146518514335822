import React from "react"
import {Address} from "lib/src/generated";

const ShowShortAddress = (props: Partial<Address>) => {
    if (!props) return 
    const {lat, lng, unit, number, street, suburb, zip, state}=props
    const array = [street, suburb, state, zip].filter(Boolean)
    return <a target={'_blank'}
              rel="noreferrer"
              href={`https://www.google.com/maps/place/${lat}+${lng}/@${lat},${lng},15z`}>{unit ? `${unit}/${number}` : number}, {array.join(', ')}</a>
}
export default ShowShortAddress

