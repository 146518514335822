import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import {connectField} from 'uniforms';


function RecaptchaField({onChange, sitekey = '6Le-71EbAAAAAAHd8w-Ld7GqLfZ4Ufhxm8aw-UJi', theme='light', size='normal', innerRef}) {
    return <ReCAPTCHA
        ref={innerRef}
        sitekey={sitekey}
        theme={theme}
        size={size}
        style={{ display: "inline-block" }}
        onChange={onChange}
    />
}
//@ts-ignore
export default connectField(RecaptchaField)