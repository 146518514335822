import React, {useState} from "react"
import {TransactionOrderByWithRelationInput, useMeQuery, useNotForProfitQuery} from "lib/src/generated";
import {getRowsAndTotal, Wrapper} from "../../../Components/common/Table/TableWrapper";
import DirectorySearch from "../directory/DirectorySearch";
import {useHistory, useParams} from "react-router-dom";
import {Button, Tab, Tabs} from "react-bootstrap";
import Votes from "../directory/Votes";
import {directoryNFPFields} from "../directory/directoryNFPFields";
import DirectoryNFPCards from "../../../Components/common/DirectoryNFPCards";
import {useFindFundQuery} from "lib/src/generated";
import {showCurrency, showDate, showDateTime} from "../../../Components/util";
import confirmModal from "../../../Components/common/ConfirmModal";
import {BsInfoCircle} from 'react-icons/bs'

const DirectoryNFP = () => {
  const {data} = useMeQuery();
  const {data: funds, loading}=useFindFundQuery()
  const sellers = (!data || !data.me || data.me.type.indexOf('Individual')!==0) ? <NFP/> : <NFPTabs/>;
  return (
    <>
      <div className={'text-center'}>
        <h1><span className={'purple'}>Not-For-Profits</span></h1>
        <b>Directory of Not-For-Profits being funded from 3% of recorded membership sales</b>
        <br/>
        <b>As at {' '}
          {loading ? '..........' : showDate(funds.findFund.updatedAt)},  {' '}
          {loading ? '..........' : showCurrency(funds.findFund.available)}  {' '}
          is available in the Community Contributions Fund to allocate to Not-For-Profits
        </b>
        <br/>
        <Button variant={'link'} onClick={()=>{
          confirmModal({
            closeButton: true,
            size:'xl',
            title: 'How to allocate funds',
            content: <p>
              Total available funds are divided by the total number of members who vote.<br/><br/>

              Each member's available funds are then individually allocated to Not-For-Profits (NFP).<br/><br/>

              Members allocate funds by clicking on the ($) signs next to the NFP's name and choosing 1 up to 5 ($).<br/><br/>

              A members available funds are divided by the total number of ($) they allocate to NFPs, with each chosen NFP then receiving 1, 2, 3, 4 or 5 ($) worth of available funds.<br/><br/>

              <u><b>Examples of a member allocating ($) to NFPs, with $100 available funds:</b></u><br/><br/>

              <b>Example 1:</b> A member allocates 3 x ($) to Charity A.  The $100 will be divided by a total of 3 ($) signs = $33.33 each. Charity A will receive 3 (3 x $33.33 = $100)<br/><br/>

              <b>Example 2:</b> A member allocates 3 x ($) to Charity A, and 2 x ($) to Charity B. The $100 will be divided by a total of 5 ($) signs = $20 each. Charity A will receive 3 (3 x $20 = $60)<br/><br/>

              Any NFPs a member votes for will show on their "Votes" page.<br/><br/>

              You may remove a NFP from your "Votes" page or change the number of ($) votes by clicking on the ($) sign again.<br/><br/>

              Only members may vote to fund not-for-profits, from individual accounts and not from business or NFP accounts<br/><br/>
            </p>,
            okText: 'Close',
            cancelText: '',
            onConfirm: async ()=>{

            },
          }).render()
        }}>See how to allocate funds <BsInfoCircle/></Button>

      </div>
      {sellers}
    </>
  )
}

function NFPTabs() {
  const history = useHistory()
  const {tab = 'not-for-profit'} = useParams<{ tab?: string }>()
  return (<Tabs
    activeKey={tab}
    onSelect={(k) => {
      if (k === 'not-for-profit') {
        history.push('/directory-not-for-profit')
      } else {
        history.push('/directory-not-for-profit/votes')
      }
    }}
  >
    <Tab eventKey="not-for-profit" title="All Not-For-Profits">
      <br/>
      <NFP/>
    </Tab>
    <Tab eventKey="votes" title="Votes">
      <br/>
      <Votes/>
    </Tab>
  </Tabs>)
}


const NFP = () => {
    const orderBy = {id: 'asc'} as TransactionOrderByWithRelationInput
    const {data,networkStatus, ...queryResult} = useNotForProfitQuery({
        variables: {take: 25, orderBy, skip: 0},
        fetchPolicy: 'cache-and-network',
    });
    const {rows, total} = getRowsAndTotal(data)
    return (
      <>
          <DirectorySearch placeholder={'Search by NFP name or ID'} {...queryResult}/>
          <Wrapper noFilter Component={DirectoryNFPCards} fields={directoryNFPFields} rows={rows} total={total} {...queryResult} />
      </>
    );
}

export default DirectoryNFP

