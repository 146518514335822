import '@capacitor/core';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {getApollo} from "./apollo";

getApollo()
    .then((client) => {
        if (process.env.NODE_ENV === 'production') {
            ReactDOM.render(<App client={client}/>, document.getElementById('root'));
        } else {
            ReactDOM.render(
                <React.StrictMode>
                    <App client={client}/>
                </React.StrictMode>,
                document.getElementById('root')
            );
            reportWebVitals(console.info);
        }
    })
    .catch(console.error)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

