import {ApolloClient, FieldPolicy, InMemoryCache} from '@apollo/client'
import {Transaction} from "lib/src/generated";
import {CachePersistor, LocalStorageWrapper} from 'apollo3-cache-persist';

const findManyMergePolicy: FieldPolicy<Transaction[], Transaction[]> = {
    keyArgs: ['orderBy', 'where', 'distance'],
    merge(existing=[], incoming, {args, fieldName}) {
        const merged = existing ? existing.slice(0) : [];
        if (args) {
            const {skip = 0} = args;
            if (skip === 0) return incoming
            for (let i = 0; i < incoming.length; ++i) {
                merged[skip + i] = incoming[i];
            }
        }
        return merged;
    },
}

export async function getApollo() {
    const cache = new InMemoryCache({
        typePolicies: {
            Vote:{
                keyFields:['toId','fromId']
            },
            User: {
                fields: {
                    votes: {
                        keyArgs: ['orderBy', 'where', 'distance'],
                        merge(existing = [], incoming = [], {args, field, storeFieldName}) {
                            return incoming
                        },
                    },
                    favored: {
                        keyArgs: ['orderBy', 'where', 'distance'],
                        merge(existing = [], incoming = [], {args, field, storeFieldName}) {
                            return incoming
                        },
                    }
                }
            },
            Query: {
                fields: {
                    votes: findManyMergePolicy,
                    favorites: findManyMergePolicy,
                    sellers: findManyMergePolicy,
                    users: findManyMergePolicy,
                    sales: findManyMergePolicy,
                    purchases: findManyMergePolicy,
                },
            },
        },
    })
    let newPersistor = new CachePersistor({
        cache,
        // @ts-ignore
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        trigger: 'write',
    });
    await newPersistor.restore();
    return new ApolloClient({
        uri: `${window.location.protocol}//${window.location.host}/api`,
        credentials: 'include',
        cache,
        defaultOptions: {
            mutate: {},
        }
    })
}




