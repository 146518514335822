import {AnySchema, ValidationError} from 'yup';
import GraphQLBridge from 'uniforms-bridge-graphql';
import {GraphQLType} from 'graphql/type/definition';
import {SCHEMA} from "../generated/schema";
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';

const transformValidator = (yup: AnySchema & any, context: any) => {
    return (model: any) => {
        try {
            yup.validateSync(model, {abortEarly: false, stripUnknown: true, context});
        } catch (error) {
            return {details: flatErrors(error)};
        }
        return null;
    };
};

type BridgeError = { name: string; message: string };
const flatErrors = (error: ValidationError, parent = '') => {
    const details: BridgeError[] = [];
    if (error.inner) {
        for (const inner of error.inner) {
            if (inner.path) {
                details.push({name: join(inner.path, parent), message: inner.message});
            }
            details.concat(flatErrors(inner, join(inner.path, parent)));
        }
    } else {
        if (error.path) {
            details.push({name: join(error.path, parent), message: error.message});
        }
    }
    return details;
};

const join = (child = '', parent = '') => {
    if (parent) {
        return parent + '.' + child;
    } else {
        return child;
    }
};

export default class Bridge extends GraphQLBridge {
    readonly name: string;
    context: any
    yupValidator: AnySchema

    constructor(typeName: string, validator: AnySchema, data?: any, context?: any) {
        const schema = SCHEMA.getType(typeName) as GraphQLType;
        if (!schema){
            console.log('SCHEMA',SCHEMA)
            throw new Error(`Schema "${typeName}" not found`)
        }

        const clonedValidator = validator.clone()
        // @ts-ignore
        Object.keys(clonedValidator.fields).forEach(field=>{
            // @ts-ignore
            if (!clonedValidator.fields[field].spec.label) {
                // @ts-ignore
                clonedValidator.fields[field].spec.label = toHumanLabel(field)
            }
        })
        const defaultData = getDefaultDataFromYup(clonedValidator);
        super(schema, transformValidator(clonedValidator, context), {...defaultData, ...data});
        this.name = typeName;
        this.yupValidator = clonedValidator;
    }

}

export function getDefaultDataFromYup(yup: AnySchema & any, parent = '') {
    const obj: { label?: string; allowedValues?: string[] } & any = {};
    if (!yup.fields) return obj;
    Object.keys(yup.fields).forEach(function (field) {
        const innerYup = yup.fields[field];
        const allowedValues = Array.from(innerYup._whitelist.list);
        const fieldPath = join(field, parent);
        obj[fieldPath] = {
            ...(innerYup.spec.label ? {label: innerYup.spec.label} : {}),
            ...(allowedValues.length > 0 && allowedValues.every((s) => !!s && innerYup.isValid(s)) ? {allowedValues} : {}),
            ...getDefaultDataFromYup(innerYup, fieldPath),
        };
    });
    return obj;
}

function toHumanLabel(label: string) {
    return upperFirst(lowerCase(label));
}
