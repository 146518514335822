import React from "react"
import {User, useVoteAddMutation, VotesDocument} from "lib/src/generated";
import {HiOutlineCurrencyDollar, HiCurrencyDollar} from "react-icons/hi";
import {IoIosRemoveCircleOutline} from "react-icons/io";
import {useMeQuery} from "lib/src/generated";
import signModal from "./MyModal";
import {useHistory} from "react-router-dom";
import {GOLD} from "lib/src/constants";
import './votes.scss'
import {MeDocument} from "lib/src/generated";
import {MeQuery} from "lib/src/generated";
const SIZE=40

function VoteButton({id, voted, isVotesList=false}: Pick<User, 'id' | 'voted'> & {isVotesList?: boolean}) {
  const {data} = useMeQuery();
  if (!data || !data.me) {
    return <VoteSignIn/>
  }
  const Icon = voted ? HiOutlineCurrencyDollar : HiCurrencyDollar
  const color = voted ? undefined : GOLD
  const value = voted && voted[0] && voted[0].vote
  return <div className={'votes'}>
    <div className={'votes-icon'}>
      <Icon
        color={color}
        size={SIZE}
       />
    </div>
    <div className={'votes-button'}>
      <VoteAdd toId={id} vote={0} actual={value} fromId={data.me.id} isVotesList={isVotesList}/>
      <VoteAdd toId={id} vote={1} actual={value} fromId={data.me.id} isVotesList={isVotesList}/>
      <VoteAdd toId={id} vote={2} actual={value} fromId={data.me.id} isVotesList={isVotesList}/>
      <VoteAdd toId={id} vote={3} actual={value} fromId={data.me.id} isVotesList={isVotesList}/>
      <VoteAdd toId={id} vote={4} actual={value} fromId={data.me.id} isVotesList={isVotesList}/>
      <VoteAdd toId={id} vote={5} actual={value} fromId={data.me.id} isVotesList={isVotesList}/>
    </div>
  </div>
}

function VoteSignIn() {
  const history = useHistory()
  return <HiOutlineCurrencyDollar size={SIZE} onClick={() => {
    signModal().render(history)
  }}/>
}


function VoteAdd({toId, vote, actual, fromId, isVotesList}: { toId: number, vote: number, actual: number, fromId: number, isVotesList: boolean }) {

  const Icon = !actual || vote > actual ? HiOutlineCurrencyDollar : HiCurrencyDollar
  const color = !actual || vote > actual ? undefined : GOLD

  const [voteAdd] = useVoteAddMutation({
    variables: {sellerId: toId, vote},
    optimisticResponse: {
      __typename: 'Mutation',
      voteAdd: {
        vote,
        fromId,
        toId,
        __typename: 'Vote',
      }
    },
    refetchQueries: isVotesList ? [MeDocument] : [VotesDocument,MeDocument],
    update(cache, {data: {voteAdd}}) {
      cache.modify({

        fields: {
          me(existing, {readField,toReference}){
              const original = cache.readQuery<MeQuery>({query: MeDocument})
              if (!original) return existing
              const data=JSON.parse(JSON.stringify(original))
              data.me.totalVotes=(data.me.totalVotes || 0) + (voteAdd.vote || 0) - (actual||0)
              cache.writeQuery({query: MeDocument, data})
              return data.me
          },
          votes(existingVotes, {readField, toReference,}) {
            if (voteAdd.vote === 0) {
              return existingVotes.filter(userRef => voteAdd.toId !== readField('id', userRef))
            }
            return existingVotes
            // if exists the cgange is automatice and if not, then refetchQueris do the job
            //else {
            //   const exists = existingVotes.some(userRef => voteAdd.toId === readField('id', userRef))
            //   if (!exists) {
            //     const newList = [...existingVotes, toReference({__typename:'User',id: voteAdd.toId})]
            //     newList.sort((a, b) => {
            //       return (readField('id', a) as number )- (readField('id', b) as number)
            //     });
            //     return newList
            //   }
            //   return existingVotes
            // }
          },
          votesCount(existingFavoritesCount,{}) {
            if (voteAdd.vote===0) return existingFavoritesCount-1
           // const data=cache.readQuery<NotForProfitQuery>({query: NotForProfitDocument})
            return  existingFavoritesCount
          },
        },
      });
    }
  })
  if (!vote) {
    if (actual){
      return <IoIosRemoveCircleOutline className={'remove'} size={SIZE} color={'red'} onClick={() => {
        voteAdd().catch(console.error)
      }}/>
    }
    return null
  }
  return <Icon
    color={color}
    size={SIZE}
    onClick={() => {
      if (actual === vote) return
      voteAdd().catch(console.error)
    }}/>
}

export default VoteButton

