import React, {useEffect} from 'react'
import {useJsApiLoader} from '@react-google-maps/api';
import AddressField, {buildResult, libraries} from "../../../Components/common/AddressField";
import {connectField} from "uniforms";
import {AddressInput} from "lib/src/generated";
import {AutoField} from "uniforms-bootstrap4";


function DistanceSearch({value, onChange,placeholder}) {

    const {isLoaded} = useJsApiLoader({
        id: 'Map',
        libraries,
        googleMapsApiKey: "AIzaSyDREfT4Rypm8BD2TqwK-8tAHvAQ46Nswbg"
    })
    const hasValue = !!(value && value.address && value.address.lat)
    useEffect(() => {
        if (hasValue && !value.range) {
            onChange({...value, range: 5000})
        }
    }, [hasValue, onChange, value])
    if (!isLoaded) return null


    return <div className={'distance-search'}>
        <AddressField
            name={'address'}
            showUnit={false}
            showNumber={false}
            showStreet={false}
            showSuburb={true}
            showState={true}
            showZip={true}
            formControlLike={true}
            placeholder={placeholder}
            options={{
                types: ["(regions)"], componentRestrictions: {country: "au"}
            }}
        />
        <AutoField
            name={'range'}
            allowedValues={[...(hasValue ? [] : [0]), 2000, 5000, 10000, 20000, 50000, 100000, 250000, 500000]}
            transform={(k => `${k / 1000} km`)}/>
    </div>
}

export const reverseGeoCode = ({lat, lng}): Promise<AddressInput> => new Promise((resolve, rejected) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
        {location: {lat, lng}},
        (
            results: google.maps.GeocoderResult[],
            status: google.maps.GeocoderStatus
        ) => {
            if (status === "OK") {
                if (results[0]) {
                    resolve(buildResult(results[0]))
                } else {
                    resolve({
                        lat,
                        lng,
                        address: ' ',
                        country: 'Australia',
                    })
                }
            } else {
                rejected("Geocoder failed due to: " + status);
            }
        }
    );
})
// @ts-ignore
export default connectField(DistanceSearch)
