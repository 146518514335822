import React, {PropsWithChildren} from 'react';
import Header from './Header';
import Container from 'react-bootstrap/Container';
import SpinnerContainer from '../../common/SpinnerContainer';
import {useMeQuery} from "lib/src/generated";
import WithFooter from "../../common/WithFooter";

const LayoutPage: React.FC = ({children}: PropsWithChildren<{}>) => {
    const {loading} = useMeQuery();
    return (
        <SpinnerContainer loading={loading} style={{height: '100vh', width: '100vw'}}>
            <WithFooter>
                <Container style={{minHeight: '100vh'}}>
                    <>
                        <Header/>
                        {!loading && children}
                    </>
                </Container>
            </WithFooter>
        </SpinnerContainer>
    );
};

export default LayoutPage;
