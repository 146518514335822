import React, {FC, PropsWithChildren, ReactElement} from 'react';
import './with-footer.scss'
import Footer from "./Footer";

const WithFooter: FC<PropsWithChildren<{ footer?: ReactElement }>> = ({children, footer = <Footer/>}) => {
    return <div className={'with-footer'}>
        <div className={'with-footer-content'}>{children}</div>
        <div className={'with-footer-help'}>{footer}
        </div>
    </div>
}

export default WithFooter