import {FieldFilterType} from "./Filters";
import {DateTimeFilter, FloatFilter, StringFilter} from "lib/src/generated";


import sw from "stopword";

export type OperatorType = keyof StringFilter | keyof FloatFilter | keyof DateTimeFilter
export type FilterType = { field: string, operator: OperatorType, value: string }
export type FilterResolver = (filter: FilterType) => any


export function getAnyTextFieldResolver(fields: FieldFilterType[], all: boolean) {
    return function ({value, operator}) {
        const stringFields = fields.filter(({
                                                type, noFilter
                                            }) => type === 'String' && (all || !noFilter))
        const filters = []
        stringFields.forEach((filter) => {
            const confField = fields.find(f => f.field === filter.field)
            if (!confField) return
            const filterResolver = confField.filterResolver || stringFieldResolver
            filters.push(filterResolver({field: filter.field, operator, value}))
        })
        if (filters.length) {
            return {OR: filters}
        }
    }
}



export const getDefaultOperator = (field?: FieldFilterType): OperatorType => {
    switch (field && field.type) {
        case 'String':
            return 'contains'
        default:
            return 'equals'
    }
}
export const getDefaultFilterResolver = ({type}: FieldFilterType): FilterResolver => {
    switch (type) {
        case 'DateTime':
            return stringFieldResolver;
        default:
            return stringFieldResolver;
    }
}
const stringFieldResolver = ({field, operator, value}) => {
    if (!value) return value
    const split=value.split(' ')
    let words=sw.removeStopwords(split).filter(Boolean)
    if (words.length===0) words=split
    return ({OR: words.map((word)=>({[field]: {[operator]: word}}))})
}
