import React, {ReactNode, useState} from 'react'
import ReactDOM from 'react-dom';
import {Button, Modal, ModalProps, Spinner} from "react-bootstrap";

interface ConfirmModalInterface extends ModalProps{
    title: ReactNode,
    content: ReactNode,
    okText?: string,
    cancelText?: string,
    onConfirm?: () => void | Promise<void>,
    onCancel?: () => void | Promise<void>,
}

export default function confirmModal({
                                         title,
                                         content,
                                         onConfirm,
                                         onCancel,
                                         okText = 'Confirm',
                                         cancelText = 'Cancel',
                                         closeButton,
                                         ...rest
                                     }: ConfirmModalInterface) {
    const div = document.createElement('div');
    div.classList.add()
    document.body.appendChild(div);

    function destroy(...args: any[]) {
        const unmountResult = ReactDOM.unmountComponentAtNode(div);
        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div);
        }
    }

    function render() {
        ReactDOM.render(<ConfirmModal
            destroy={destroy}
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={title}
            okText={okText}
            cancelText={cancelText}
            content={content}
            closeButton={closeButton}
            {...rest}
        />, div);
        return null
    }

    return {
        destroy,
        render,
    };
}

const ConfirmModal = ({destroy, title, content, onConfirm, onCancel, cancelText, okText,closeButton, ...rest}) => {
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(false);


    return <Modal centered backdrop show={show} onExited={destroy} onHide={() => {
        if (!cancelText && !closeButton) return
        setShow(false)
    }}
                  {...rest}
    >
        <Modal.Header closeButton={!!cancelText || closeButton} placeholder>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {content}
        </Modal.Body>
        <Modal.Footer>
            {cancelText && <Button variant="danger" onClick={() => {
                setShow(false);
                onCancel && onCancel()
            }}>{cancelText}</Button>}
            <Button variant="primary" onClick={async () => {
                setLoading(true)
                onConfirm && (await onConfirm())
                setShow(false);
            }}>{okText}{loading && <Spinner size={'sm'} animation={'grow'}/>}</Button>
        </Modal.Footer>
    </Modal>
}

