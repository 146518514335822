import React, {useState} from 'react'
import ReactDOM from 'react-dom';
import {History} from 'history';
import {Button, Modal} from "react-bootstrap";


export default function signModal() {
    const div = document.createElement('div');
    div.classList.add()
    document.body.appendChild(div);

    function destroy(...args: any[]) {
        const unmountResult = ReactDOM.unmountComponentAtNode(div);
        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div);
        }
    }

    function render(history: History) {
        ReactDOM.render(<SignModal history={history} destroy={destroy}/>, div);
        return null
    }

    return {
        destroy,
        render,
    };
}

const SignModal = ({destroy, history}) => {
    const [show, setShow] = useState(true);

    const close = () => {
        setShow(false);
    }
    const register = () => {
        history.push('/register')
        setShow(false);
    }
    const signIn = () => {
        history.push('/sign-in')
        setShow(false);
    }
    return <Modal centered backdrop show={show} onExited={destroy} onHide={close}>
        <Modal.Header closeButton placeholder>
            <Modal.Title>Account Required</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>You can only save favourites if you are signed-in.</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="primary" onClick={signIn}>Sign In</Button>
            <Button variant="primary" onClick={register}>Register</Button>
        </Modal.Footer>
    </Modal>
}

