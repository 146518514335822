import React from "react"
import {useFavoriteAddMutation, useFavoriteRemoveMutation, User} from "lib/src/generated";
import {IoIosStarOutline, IoIosStar} from "react-icons/io";
import {useMeQuery} from "lib/src/generated";
import signModal from "./MyModal";
import {useHistory} from "react-router-dom";
import {FavoritesDocument} from "lib/src/generated";
import {GOLD} from "lib/src/constants";

function FavoriteButton({user, isFavouriteList}:  {user: User, isFavouriteList: boolean}) {
    const {favored}=user
    const {data} = useMeQuery();
    if (!data || !data.me) {
        return <FavoriteSignIn/>
    }
    if (!favored) return null
    return favored.length > 0 ? <FavoriteRemove user={user} isFavouriteList={isFavouriteList}/> : <FavoriteAdd user={user} isFavouriteList={isFavouriteList}/>
}

function FavoriteSignIn() {
    const history = useHistory()
    return <IoIosStar size={40} onClick={() => {
        signModal().render(history)
    }}/>
}

function FavoriteRemove({user,isFavouriteList}: { user: User ,isFavouriteList: boolean}) {
    const [favoriteRemove] = useFavoriteRemoveMutation({
        variables: {sellerId: user.id},
        optimisticResponse:{
            __typename:'Mutation',
            favoriteRemove:{
               ...user
            }
        },
        refetchQueries: isFavouriteList ? [] : [FavoritesDocument],
        update(cache, {data: {favoriteRemove}}) {
            cache.modify({
                fields: {
                    favorites(existingFavorites, {readField}) {
                        return existingFavorites.filter(
                            favRef => favoriteRemove.id !== readField('id', favRef)
                        );
                    },
                    favoritesCount(existingFavoritesCount) {
                        return existingFavoritesCount - 1
                    },
                },

            });
        }
    })
    return <IoIosStar
        color={GOLD}

        size={40}
        onClick={() => {
            favoriteRemove().catch(console.error) //TODO
        }}/>
}

function FavoriteAdd({user,isFavouriteList}: { user: User ,isFavouriteList: boolean}) {
    const [favoriteAdd] = useFavoriteAddMutation({
        variables: {sellerId: user.id},
        optimisticResponse:{
          __typename:'Mutation',
          favoriteAdd:{
            ...user,
          }
        },
        refetchQueries: isFavouriteList ? [] : [FavoritesDocument],
            // it dont matter, refetchqueris is doing the job
        // update(cache, {data: {favoriteAdd}}) {
        //     cache.modify({
        //         fields: {
        //             favorites(existingFavorites,{toReference}) {
        //                 return [...existingFavorites,toReference(favoriteAdd)]
        //             },
        //             favoritesCount(existingFavoritesCount) {
        //                 return existingFavoritesCount + 1
        //             },
        //         }
        //     });
        // }
    })
    return <IoIosStarOutline
        size={40}
        onClick={() => {
            favoriteAdd().catch(console.error)
        }}/>
}

export default FavoriteButton

