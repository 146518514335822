import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  DateTime: any;
  /** Decimal custom scalar type */
  Decimal: any;
  /** Json custom scalar type */
  Json: any;
};

export type Accessibility = {
  __typename?: 'Accessibility';
  User?: Maybe<User>;
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityAvgAggregateOutputType = {
  __typename?: 'AccessibilityAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type AccessibilityAvgOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type AccessibilityCountAggregateOutputType = {
  __typename?: 'AccessibilityCountAggregateOutputType';
  _all: Scalars['Int'];
  email: Scalars['Int'];
  id: Scalars['Int'];
  outcall: Scalars['Int'];
  phone: Scalars['Int'];
  userId: Scalars['Int'];
  website: Scalars['Int'];
};

export type AccessibilityCountOrderByAggregateInput = {
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  outcall?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  website?: Maybe<SortOrder>;
};

export type AccessibilityCreateInput = {
  User?: Maybe<UserCreateNestedOneWithoutAccessibilityInput>;
  address?: Maybe<AddressCreateNestedOneWithoutAccessibilityInput>;
  email?: Maybe<Scalars['String']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityCreateManyInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityCreateNestedOneWithoutAddressInput = {
  connect?: Maybe<AccessibilityWhereUniqueInput>;
  connectOrCreate?: Maybe<AccessibilityCreateOrConnectWithoutAddressInput>;
  create?: Maybe<AccessibilityUncheckedCreateWithoutAddressInput>;
};

export type AccessibilityCreateNestedOneWithoutUserInput = {
  connect?: Maybe<AccessibilityWhereUniqueInput>;
  connectOrCreate?: Maybe<AccessibilityCreateOrConnectWithoutUserInput>;
  create?: Maybe<AccessibilityUncheckedCreateWithoutUserInput>;
};

export type AccessibilityCreateOrConnectWithoutAddressInput = {
  create: AccessibilityUncheckedCreateWithoutAddressInput;
  where: AccessibilityWhereUniqueInput;
};

export type AccessibilityCreateOrConnectWithoutUserInput = {
  create: AccessibilityUncheckedCreateWithoutUserInput;
  where: AccessibilityWhereUniqueInput;
};

export type AccessibilityCreateWithoutAddressInput = {
  User?: Maybe<UserCreateNestedOneWithoutAccessibilityInput>;
  email?: Maybe<Scalars['String']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityCreateWithoutUserInput = {
  address?: Maybe<AddressCreateNestedOneWithoutAccessibilityInput>;
  email?: Maybe<Scalars['String']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityInput = {
  address?: Maybe<AddressInput>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityMaxAggregateOutputType = {
  __typename?: 'AccessibilityMaxAggregateOutputType';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityMaxOrderByAggregateInput = {
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  outcall?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  website?: Maybe<SortOrder>;
};

export type AccessibilityMinAggregateOutputType = {
  __typename?: 'AccessibilityMinAggregateOutputType';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityMinOrderByAggregateInput = {
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  outcall?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  website?: Maybe<SortOrder>;
};

export type AccessibilityOrderByWithAggregationInput = {
  _avg?: Maybe<AccessibilityAvgOrderByAggregateInput>;
  _count?: Maybe<AccessibilityCountOrderByAggregateInput>;
  _max?: Maybe<AccessibilityMaxOrderByAggregateInput>;
  _min?: Maybe<AccessibilityMinOrderByAggregateInput>;
  _sum?: Maybe<AccessibilitySumOrderByAggregateInput>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  outcall?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  website?: Maybe<SortOrder>;
};

export type AccessibilityOrderByWithRelationInput = {
  User?: Maybe<UserOrderByWithRelationInput>;
  address?: Maybe<AddressOrderByWithRelationInput>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  outcall?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  website?: Maybe<SortOrder>;
};

export type AccessibilityRelationFilter = {
  is?: Maybe<AccessibilityWhereInput>;
  isNot?: Maybe<AccessibilityWhereInput>;
};

export enum AccessibilityScalarFieldEnum {
  Email = 'email',
  Id = 'id',
  Outcall = 'outcall',
  Phone = 'phone',
  UserId = 'userId',
  Website = 'website'
}

export type AccessibilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<AccessibilityScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<AccessibilityScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<AccessibilityScalarWhereWithAggregatesInput>>>;
  email?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<IntWithAggregatesFilter>;
  outcall?: Maybe<StringNullableWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<IntNullableWithAggregatesFilter>;
  website?: Maybe<StringNullableWithAggregatesFilter>;
};

export type AccessibilitySumAggregateOutputType = {
  __typename?: 'AccessibilitySumAggregateOutputType';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type AccessibilitySumOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type AccessibilityUncheckedCreateInput = {
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutAccessibilityInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityUncheckedCreateNestedOneWithoutUserInput = {
  connect?: Maybe<AccessibilityWhereUniqueInput>;
  connectOrCreate?: Maybe<AccessibilityCreateOrConnectWithoutUserInput>;
  create?: Maybe<AccessibilityUncheckedCreateWithoutUserInput>;
};

export type AccessibilityUncheckedCreateWithoutAddressInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityUncheckedCreateWithoutUserInput = {
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutAccessibilityInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  outcall?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AccessibilityUncheckedUpdateInput = {
  address?: Maybe<AddressUncheckedUpdateOneWithoutAccessibilityInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  outcall?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccessibilityUncheckedUpdateManyInput = {
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  outcall?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccessibilityUncheckedUpdateOneWithoutUserInput = {
  connect?: Maybe<AccessibilityWhereUniqueInput>;
  connectOrCreate?: Maybe<AccessibilityCreateOrConnectWithoutUserInput>;
  create?: Maybe<AccessibilityUncheckedCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccessibilityUncheckedUpdateWithoutUserInput>;
  upsert?: Maybe<AccessibilityUpsertWithoutUserInput>;
};

export type AccessibilityUncheckedUpdateWithoutAddressInput = {
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  outcall?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccessibilityUncheckedUpdateWithoutUserInput = {
  address?: Maybe<AddressUncheckedUpdateOneWithoutAccessibilityInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  outcall?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccessibilityUpdateInput = {
  User?: Maybe<UserUpdateOneWithoutAccessibilityInput>;
  address?: Maybe<AddressUpdateOneWithoutAccessibilityInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  outcall?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccessibilityUpdateManyMutationInput = {
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  outcall?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccessibilityUpdateOneWithoutAddressInput = {
  connect?: Maybe<AccessibilityWhereUniqueInput>;
  connectOrCreate?: Maybe<AccessibilityCreateOrConnectWithoutAddressInput>;
  create?: Maybe<AccessibilityUncheckedCreateWithoutAddressInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccessibilityUncheckedUpdateWithoutAddressInput>;
  upsert?: Maybe<AccessibilityUpsertWithoutAddressInput>;
};

export type AccessibilityUpdateOneWithoutUserInput = {
  connect?: Maybe<AccessibilityWhereUniqueInput>;
  connectOrCreate?: Maybe<AccessibilityCreateOrConnectWithoutUserInput>;
  create?: Maybe<AccessibilityUncheckedCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccessibilityUncheckedUpdateWithoutUserInput>;
  upsert?: Maybe<AccessibilityUpsertWithoutUserInput>;
};

export type AccessibilityUpdateWithoutAddressInput = {
  User?: Maybe<UserUpdateOneWithoutAccessibilityInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  outcall?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccessibilityUpdateWithoutUserInput = {
  address?: Maybe<AddressUpdateOneWithoutAccessibilityInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  outcall?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccessibilityUpsertWithoutAddressInput = {
  create: AccessibilityUncheckedCreateWithoutAddressInput;
  update: AccessibilityUncheckedUpdateWithoutAddressInput;
};

export type AccessibilityUpsertWithoutUserInput = {
  create: AccessibilityUncheckedCreateWithoutUserInput;
  update: AccessibilityUncheckedUpdateWithoutUserInput;
};

export type AccessibilityWhereInput = {
  AND?: Maybe<Array<Maybe<AccessibilityWhereInput>>>;
  NOT?: Maybe<Array<Maybe<AccessibilityWhereInput>>>;
  OR?: Maybe<Array<Maybe<AccessibilityWhereInput>>>;
  User?: Maybe<UserWhereInput>;
  address?: Maybe<AddressWhereInput>;
  email?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  outcall?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  userId?: Maybe<IntNullableFilter>;
  website?: Maybe<StringNullableFilter>;
};

export type AccessibilityWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type Address = {
  __typename?: 'Address';
  accessibility?: Maybe<Accessibility>;
  accessibilityId?: Maybe<Scalars['Int']>;
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['Int'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  number: Scalars['String'];
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  zip: Scalars['String'];
};

export type AddressAvgAggregateOutputType = {
  __typename?: 'AddressAvgAggregateOutputType';
  accessibilityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type AddressAvgOrderByAggregateInput = {
  accessibilityId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  lng?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type AddressCountAggregateOutputType = {
  __typename?: 'AddressCountAggregateOutputType';
  _all: Scalars['Int'];
  accessibilityId: Scalars['Int'];
  address: Scalars['Int'];
  city: Scalars['Int'];
  country: Scalars['Int'];
  id: Scalars['Int'];
  lat: Scalars['Int'];
  lng: Scalars['Int'];
  number: Scalars['Int'];
  placeId: Scalars['Int'];
  state: Scalars['Int'];
  street: Scalars['Int'];
  suburb: Scalars['Int'];
  type: Scalars['Int'];
  unit: Scalars['Int'];
  userId: Scalars['Int'];
  zip: Scalars['Int'];
};

export type AddressCountOrderByAggregateInput = {
  accessibilityId?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  lng?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  placeId?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  street?: Maybe<SortOrder>;
  suburb?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unit?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  zip?: Maybe<SortOrder>;
};

export type AddressCreateInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutAddressInput>;
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  number: Scalars['String'];
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutAddressInput>;
  zip: Scalars['String'];
};

export type AddressCreateManyInput = {
  accessibilityId?: Maybe<Scalars['Int']>;
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  number: Scalars['String'];
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip: Scalars['String'];
};

export type AddressCreateNestedOneWithoutAccessibilityInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutAccessibilityInput>;
  create?: Maybe<AddressUncheckedCreateWithoutAccessibilityInput>;
};

export type AddressCreateNestedOneWithoutUserInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUserInput>;
  create?: Maybe<AddressUncheckedCreateWithoutUserInput>;
};

export type AddressCreateOrConnectWithoutAccessibilityInput = {
  create: AddressUncheckedCreateWithoutAccessibilityInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutUserInput = {
  create: AddressUncheckedCreateWithoutUserInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutAccessibilityInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  number: Scalars['String'];
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutAddressInput>;
  zip: Scalars['String'];
};

export type AddressCreateWithoutUserInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutAddressInput>;
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  number: Scalars['String'];
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type AddressInput = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressMaxAggregateOutputType = {
  __typename?: 'AddressMaxAggregateOutputType';
  accessibilityId?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressMaxOrderByAggregateInput = {
  accessibilityId?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  lng?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  placeId?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  street?: Maybe<SortOrder>;
  suburb?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unit?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  zip?: Maybe<SortOrder>;
};

export type AddressMinAggregateOutputType = {
  __typename?: 'AddressMinAggregateOutputType';
  accessibilityId?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressMinOrderByAggregateInput = {
  accessibilityId?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  lng?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  placeId?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  street?: Maybe<SortOrder>;
  suburb?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unit?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  zip?: Maybe<SortOrder>;
};

export type AddressOrderByWithAggregationInput = {
  _avg?: Maybe<AddressAvgOrderByAggregateInput>;
  _count?: Maybe<AddressCountOrderByAggregateInput>;
  _max?: Maybe<AddressMaxOrderByAggregateInput>;
  _min?: Maybe<AddressMinOrderByAggregateInput>;
  _sum?: Maybe<AddressSumOrderByAggregateInput>;
  accessibilityId?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  lng?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  placeId?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  street?: Maybe<SortOrder>;
  suburb?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unit?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  zip?: Maybe<SortOrder>;
};

export type AddressOrderByWithRelationInput = {
  accessibility?: Maybe<AccessibilityOrderByWithRelationInput>;
  accessibilityId?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  lng?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  placeId?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  street?: Maybe<SortOrder>;
  suburb?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unit?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  zip?: Maybe<SortOrder>;
};

export type AddressRelationFilter = {
  is?: Maybe<AddressWhereInput>;
  isNot?: Maybe<AddressWhereInput>;
};

export enum AddressScalarFieldEnum {
  AccessibilityId = 'accessibilityId',
  Address = 'address',
  City = 'city',
  Country = 'country',
  Id = 'id',
  Lat = 'lat',
  Lng = 'lng',
  Number = 'number',
  PlaceId = 'placeId',
  State = 'state',
  Street = 'street',
  Suburb = 'suburb',
  Type = 'type',
  Unit = 'unit',
  UserId = 'userId',
  Zip = 'zip'
}

export type AddressScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<AddressScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<AddressScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<AddressScalarWhereWithAggregatesInput>>>;
  accessibilityId?: Maybe<IntNullableWithAggregatesFilter>;
  address?: Maybe<StringWithAggregatesFilter>;
  city?: Maybe<StringWithAggregatesFilter>;
  country?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<IntWithAggregatesFilter>;
  lat?: Maybe<FloatWithAggregatesFilter>;
  lng?: Maybe<FloatWithAggregatesFilter>;
  number?: Maybe<StringWithAggregatesFilter>;
  placeId?: Maybe<StringWithAggregatesFilter>;
  state?: Maybe<StringWithAggregatesFilter>;
  street?: Maybe<StringWithAggregatesFilter>;
  suburb?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<StringNullableWithAggregatesFilter>;
  unit?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<IntNullableWithAggregatesFilter>;
  zip?: Maybe<StringWithAggregatesFilter>;
};

export type AddressSumAggregateOutputType = {
  __typename?: 'AddressSumAggregateOutputType';
  accessibilityId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Int']>;
};

export type AddressSumOrderByAggregateInput = {
  accessibilityId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  lng?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type AddressUncheckedCreateInput = {
  accessibilityId?: Maybe<Scalars['Int']>;
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  number: Scalars['String'];
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip: Scalars['String'];
};

export type AddressUncheckedCreateNestedOneWithoutAccessibilityInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutAccessibilityInput>;
  create?: Maybe<AddressUncheckedCreateWithoutAccessibilityInput>;
};

export type AddressUncheckedCreateNestedOneWithoutUserInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUserInput>;
  create?: Maybe<AddressUncheckedCreateWithoutUserInput>;
};

export type AddressUncheckedCreateWithoutAccessibilityInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  number: Scalars['String'];
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip: Scalars['String'];
};

export type AddressUncheckedCreateWithoutUserInput = {
  accessibilityId?: Maybe<Scalars['Int']>;
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  number: Scalars['String'];
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  suburb?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type AddressUncheckedUpdateInput = {
  accessibilityId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<StringFieldUpdateOperationsInput>;
  suburb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  zip?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AddressUncheckedUpdateManyInput = {
  accessibilityId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<StringFieldUpdateOperationsInput>;
  suburb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  zip?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AddressUncheckedUpdateOneWithoutAccessibilityInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutAccessibilityInput>;
  create?: Maybe<AddressUncheckedCreateWithoutAccessibilityInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUncheckedUpdateWithoutAccessibilityInput>;
  upsert?: Maybe<AddressUpsertWithoutAccessibilityInput>;
};

export type AddressUncheckedUpdateOneWithoutUserInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUserInput>;
  create?: Maybe<AddressUncheckedCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUncheckedUpdateWithoutUserInput>;
  upsert?: Maybe<AddressUpsertWithoutUserInput>;
};

export type AddressUncheckedUpdateWithoutAccessibilityInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<StringFieldUpdateOperationsInput>;
  suburb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  zip?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AddressUncheckedUpdateWithoutUserInput = {
  accessibilityId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<StringFieldUpdateOperationsInput>;
  suburb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  zip?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AddressUpdateInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutAddressInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<StringFieldUpdateOperationsInput>;
  suburb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutAddressInput>;
  zip?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AddressUpdateManyMutationInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<StringFieldUpdateOperationsInput>;
  suburb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  zip?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AddressUpdateOneWithoutAccessibilityInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutAccessibilityInput>;
  create?: Maybe<AddressUncheckedCreateWithoutAccessibilityInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUncheckedUpdateWithoutAccessibilityInput>;
  upsert?: Maybe<AddressUpsertWithoutAccessibilityInput>;
};

export type AddressUpdateOneWithoutUserInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUserInput>;
  create?: Maybe<AddressUncheckedCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUncheckedUpdateWithoutUserInput>;
  upsert?: Maybe<AddressUpsertWithoutUserInput>;
};

export type AddressUpdateWithoutAccessibilityInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<StringFieldUpdateOperationsInput>;
  suburb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutAddressInput>;
  zip?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutUserInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutAddressInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<StringFieldUpdateOperationsInput>;
  suburb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  zip?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AddressUpsertWithoutAccessibilityInput = {
  create: AddressUncheckedCreateWithoutAccessibilityInput;
  update: AddressUncheckedUpdateWithoutAccessibilityInput;
};

export type AddressUpsertWithoutUserInput = {
  create: AddressUncheckedCreateWithoutUserInput;
  update: AddressUncheckedUpdateWithoutUserInput;
};

export type AddressWhereInput = {
  AND?: Maybe<Array<Maybe<AddressWhereInput>>>;
  NOT?: Maybe<Array<Maybe<AddressWhereInput>>>;
  OR?: Maybe<Array<Maybe<AddressWhereInput>>>;
  accessibility?: Maybe<AccessibilityWhereInput>;
  accessibilityId?: Maybe<IntNullableFilter>;
  address?: Maybe<StringFilter>;
  city?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  lat?: Maybe<FloatFilter>;
  lng?: Maybe<FloatFilter>;
  number?: Maybe<StringFilter>;
  placeId?: Maybe<StringFilter>;
  state?: Maybe<StringFilter>;
  street?: Maybe<StringFilter>;
  suburb?: Maybe<StringNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  unit?: Maybe<StringNullableFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<IntNullableFilter>;
  zip?: Maybe<StringFilter>;
};

export type AddressWhereUniqueInput = {
  accessibilityId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type AggregateAccessibility = {
  __typename?: 'AggregateAccessibility';
  _avg?: Maybe<AccessibilityAvgAggregateOutputType>;
  _count?: Maybe<AccessibilityCountAggregateOutputType>;
  _max?: Maybe<AccessibilityMaxAggregateOutputType>;
  _min?: Maybe<AccessibilityMinAggregateOutputType>;
  _sum?: Maybe<AccessibilitySumAggregateOutputType>;
};

export type AggregateAddress = {
  __typename?: 'AggregateAddress';
  _avg?: Maybe<AddressAvgAggregateOutputType>;
  _count?: Maybe<AddressCountAggregateOutputType>;
  _max?: Maybe<AddressMaxAggregateOutputType>;
  _min?: Maybe<AddressMinAggregateOutputType>;
  _sum?: Maybe<AddressSumAggregateOutputType>;
};

export type AggregateDistribution = {
  __typename?: 'AggregateDistribution';
  _avg?: Maybe<DistributionAvgAggregateOutputType>;
  _count?: Maybe<DistributionCountAggregateOutputType>;
  _max?: Maybe<DistributionMaxAggregateOutputType>;
  _min?: Maybe<DistributionMinAggregateOutputType>;
  _sum?: Maybe<DistributionSumAggregateOutputType>;
};

export type AggregateEmail = {
  __typename?: 'AggregateEmail';
  _avg?: Maybe<EmailAvgAggregateOutputType>;
  _count?: Maybe<EmailCountAggregateOutputType>;
  _max?: Maybe<EmailMaxAggregateOutputType>;
  _min?: Maybe<EmailMinAggregateOutputType>;
  _sum?: Maybe<EmailSumAggregateOutputType>;
};

export type AggregateFund = {
  __typename?: 'AggregateFund';
  _avg?: Maybe<FundAvgAggregateOutputType>;
  _count?: Maybe<FundCountAggregateOutputType>;
  _max?: Maybe<FundMaxAggregateOutputType>;
  _min?: Maybe<FundMinAggregateOutputType>;
  _sum?: Maybe<FundSumAggregateOutputType>;
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  _avg?: Maybe<TransactionAvgAggregateOutputType>;
  _count?: Maybe<TransactionCountAggregateOutputType>;
  _max?: Maybe<TransactionMaxAggregateOutputType>;
  _min?: Maybe<TransactionMinAggregateOutputType>;
  _sum?: Maybe<TransactionSumAggregateOutputType>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _avg?: Maybe<UserAvgAggregateOutputType>;
  _count?: Maybe<UserCountAggregateOutputType>;
  _max?: Maybe<UserMaxAggregateOutputType>;
  _min?: Maybe<UserMinAggregateOutputType>;
  _sum?: Maybe<UserSumAggregateOutputType>;
};

export type AggregateVote = {
  __typename?: 'AggregateVote';
  _avg?: Maybe<VoteAvgAggregateOutputType>;
  _count?: Maybe<VoteCountAggregateOutputType>;
  _max?: Maybe<VoteMaxAggregateOutputType>;
  _min?: Maybe<VoteMinAggregateOutputType>;
  _sum?: Maybe<VoteSumAggregateOutputType>;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Int'];
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedBoolFilter>;
  _min?: Maybe<NestedBoolFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
};


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};


export type DistanceInput = {
  address: AddressInput;
  range: Scalars['Int'];
};

export type Distribution = {
  __typename?: 'Distribution';
  amount: Scalars['Float'];
  fund: Fund;
  fundId: Scalars['Int'];
  id: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
};

export type DistributionAvgAggregateOutputType = {
  __typename?: 'DistributionAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  fundId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type DistributionAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  fundId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type DistributionCountAggregateOutputType = {
  __typename?: 'DistributionCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  fundId: Scalars['Int'];
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type DistributionCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  fundId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type DistributionCreateInput = {
  amount: Scalars['Float'];
  fund: FundCreateNestedOneWithoutDistributionsInput;
  user: UserCreateNestedOneWithoutDistributionsInput;
};

export type DistributionCreateManyFundInput = {
  amount: Scalars['Float'];
  id?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

export type DistributionCreateManyFundInputEnvelope = {
  data: DistributionCreateManyFundInput;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DistributionCreateManyInput = {
  amount: Scalars['Float'];
  fundId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

export type DistributionCreateManyUserInput = {
  amount: Scalars['Float'];
  fundId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
};

export type DistributionCreateManyUserInputEnvelope = {
  data: DistributionCreateManyUserInput;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DistributionCreateNestedManyWithoutFundInput = {
  connect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<DistributionCreateOrConnectWithoutFundInput>>>;
  create?: Maybe<Array<Maybe<DistributionCreateWithoutFundInput>>>;
  createMany?: Maybe<DistributionCreateManyFundInputEnvelope>;
};

export type DistributionCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<DistributionCreateOrConnectWithoutUserInput>>>;
  create?: Maybe<Array<Maybe<DistributionCreateWithoutUserInput>>>;
  createMany?: Maybe<DistributionCreateManyUserInputEnvelope>;
};

export type DistributionCreateOrConnectWithoutFundInput = {
  create: DistributionUncheckedCreateWithoutFundInput;
  where: DistributionWhereUniqueInput;
};

export type DistributionCreateOrConnectWithoutUserInput = {
  create: DistributionUncheckedCreateWithoutUserInput;
  where: DistributionWhereUniqueInput;
};

export type DistributionCreateWithoutFundInput = {
  amount: Scalars['Float'];
  user: UserCreateNestedOneWithoutDistributionsInput;
};

export type DistributionCreateWithoutUserInput = {
  amount: Scalars['Float'];
  fund: FundCreateNestedOneWithoutDistributionsInput;
};

export type DistributionListRelationFilter = {
  every?: Maybe<DistributionWhereInput>;
  none?: Maybe<DistributionWhereInput>;
  some?: Maybe<DistributionWhereInput>;
};

export type DistributionMaxAggregateOutputType = {
  __typename?: 'DistributionMaxAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  fundId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type DistributionMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  fundId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type DistributionMinAggregateOutputType = {
  __typename?: 'DistributionMinAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  fundId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type DistributionMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  fundId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type DistributionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type DistributionOrderByWithAggregationInput = {
  _avg?: Maybe<DistributionAvgOrderByAggregateInput>;
  _count?: Maybe<DistributionCountOrderByAggregateInput>;
  _max?: Maybe<DistributionMaxOrderByAggregateInput>;
  _min?: Maybe<DistributionMinOrderByAggregateInput>;
  _sum?: Maybe<DistributionSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  fundId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type DistributionOrderByWithRelationInput = {
  amount?: Maybe<SortOrder>;
  fund?: Maybe<FundOrderByWithRelationInput>;
  fundId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum DistributionScalarFieldEnum {
  Amount = 'amount',
  FundId = 'fundId',
  Id = 'id',
  UserId = 'userId'
}

export type DistributionScalarWhereInput = {
  AND?: Maybe<Array<Maybe<DistributionScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<DistributionScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<DistributionScalarWhereInput>>>;
  amount?: Maybe<FloatFilter>;
  fundId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
};

export type DistributionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<DistributionScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<DistributionScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<DistributionScalarWhereWithAggregatesInput>>>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  fundId?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<IntWithAggregatesFilter>;
  userId?: Maybe<IntWithAggregatesFilter>;
};

export type DistributionSumAggregateOutputType = {
  __typename?: 'DistributionSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  fundId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type DistributionSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  fundId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type DistributionUncheckedCreateInput = {
  amount: Scalars['Float'];
  fundId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

export type DistributionUncheckedCreateNestedManyWithoutFundInput = {
  connect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<DistributionCreateOrConnectWithoutFundInput>>>;
  create?: Maybe<Array<Maybe<DistributionCreateWithoutFundInput>>>;
  createMany?: Maybe<DistributionCreateManyFundInputEnvelope>;
};

export type DistributionUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<DistributionCreateOrConnectWithoutUserInput>>>;
  create?: Maybe<Array<Maybe<DistributionCreateWithoutUserInput>>>;
  createMany?: Maybe<DistributionCreateManyUserInputEnvelope>;
};

export type DistributionUncheckedCreateWithoutFundInput = {
  amount: Scalars['Float'];
  id?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

export type DistributionUncheckedCreateWithoutUserInput = {
  amount: Scalars['Float'];
  fundId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
};

export type DistributionUncheckedUpdateInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  fundId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  userId?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DistributionUncheckedUpdateManyInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  fundId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  userId?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DistributionUncheckedUpdateManyWithoutDistributionsInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  fundId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DistributionUncheckedUpdateManyWithoutFundInput = {
  connect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<DistributionCreateOrConnectWithoutFundInput>>>;
  create?: Maybe<Array<Maybe<DistributionCreateWithoutFundInput>>>;
  createMany?: Maybe<DistributionCreateManyFundInputEnvelope>;
  delete?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<DistributionScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<DistributionUpdateWithWhereUniqueWithoutFundInput>>>;
  updateMany?: Maybe<Array<Maybe<DistributionUpdateManyWithWhereWithoutFundInput>>>;
  upsert?: Maybe<Array<Maybe<DistributionUpsertWithWhereUniqueWithoutFundInput>>>;
};

export type DistributionUncheckedUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<DistributionCreateOrConnectWithoutUserInput>>>;
  create?: Maybe<Array<Maybe<DistributionCreateWithoutUserInput>>>;
  createMany?: Maybe<DistributionCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<DistributionScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<DistributionUpdateWithWhereUniqueWithoutUserInput>>>;
  updateMany?: Maybe<Array<Maybe<DistributionUpdateManyWithWhereWithoutUserInput>>>;
  upsert?: Maybe<Array<Maybe<DistributionUpsertWithWhereUniqueWithoutUserInput>>>;
};

export type DistributionUncheckedUpdateWithoutFundInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  userId?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DistributionUncheckedUpdateWithoutUserInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  fundId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DistributionUpdateInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  fund?: Maybe<FundUpdateOneRequiredWithoutDistributionsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutDistributionsInput>;
};

export type DistributionUpdateManyMutationInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type DistributionUpdateManyWithWhereWithoutFundInput = {
  data: DistributionUncheckedUpdateManyWithoutDistributionsInput;
  where: DistributionScalarWhereInput;
};

export type DistributionUpdateManyWithWhereWithoutUserInput = {
  data: DistributionUncheckedUpdateManyWithoutDistributionsInput;
  where: DistributionScalarWhereInput;
};

export type DistributionUpdateManyWithoutFundInput = {
  connect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<DistributionCreateOrConnectWithoutFundInput>>>;
  create?: Maybe<Array<Maybe<DistributionCreateWithoutFundInput>>>;
  createMany?: Maybe<DistributionCreateManyFundInputEnvelope>;
  delete?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<DistributionScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<DistributionUpdateWithWhereUniqueWithoutFundInput>>>;
  updateMany?: Maybe<Array<Maybe<DistributionUpdateManyWithWhereWithoutFundInput>>>;
  upsert?: Maybe<Array<Maybe<DistributionUpsertWithWhereUniqueWithoutFundInput>>>;
};

export type DistributionUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<DistributionCreateOrConnectWithoutUserInput>>>;
  create?: Maybe<Array<Maybe<DistributionCreateWithoutUserInput>>>;
  createMany?: Maybe<DistributionCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<DistributionScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<DistributionWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<DistributionUpdateWithWhereUniqueWithoutUserInput>>>;
  updateMany?: Maybe<Array<Maybe<DistributionUpdateManyWithWhereWithoutUserInput>>>;
  upsert?: Maybe<Array<Maybe<DistributionUpsertWithWhereUniqueWithoutUserInput>>>;
};

export type DistributionUpdateWithWhereUniqueWithoutFundInput = {
  data: DistributionUncheckedUpdateWithoutFundInput;
  where: DistributionWhereUniqueInput;
};

export type DistributionUpdateWithWhereUniqueWithoutUserInput = {
  data: DistributionUncheckedUpdateWithoutUserInput;
  where: DistributionWhereUniqueInput;
};

export type DistributionUpdateWithoutFundInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutDistributionsInput>;
};

export type DistributionUpdateWithoutUserInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  fund?: Maybe<FundUpdateOneRequiredWithoutDistributionsInput>;
};

export type DistributionUpsertWithWhereUniqueWithoutFundInput = {
  create: DistributionUncheckedCreateWithoutFundInput;
  update: DistributionUncheckedUpdateWithoutFundInput;
  where: DistributionWhereUniqueInput;
};

export type DistributionUpsertWithWhereUniqueWithoutUserInput = {
  create: DistributionUncheckedCreateWithoutUserInput;
  update: DistributionUncheckedUpdateWithoutUserInput;
  where: DistributionWhereUniqueInput;
};

export type DistributionWhereInput = {
  AND?: Maybe<Array<Maybe<DistributionWhereInput>>>;
  NOT?: Maybe<Array<Maybe<DistributionWhereInput>>>;
  OR?: Maybe<Array<Maybe<DistributionWhereInput>>>;
  amount?: Maybe<FloatFilter>;
  fund?: Maybe<FundWhereInput>;
  fundId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<IntFilter>;
};

export type DistributionWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Email = {
  __typename?: 'Email';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

export type EmailAvgAggregateOutputType = {
  __typename?: 'EmailAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type EmailAvgOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailCountAggregateOutputType = {
  __typename?: 'EmailCountAggregateOutputType';
  _all: Scalars['Int'];
  body: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  subject: Scalars['Int'];
  userId: Scalars['Int'];
};

export type EmailCountOrderByAggregateInput = {
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailCreateInput = {
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  subject?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutEmailsInput>;
};

export type EmailCreateManyInput = {
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type EmailCreateManyUserInput = {
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
};

export type EmailCreateManyUserInputEnvelope = {
  data: EmailCreateManyUserInput;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<EmailCreateOrConnectWithoutUserInput>>>;
  create?: Maybe<Array<Maybe<EmailCreateWithoutUserInput>>>;
  createMany?: Maybe<EmailCreateManyUserInputEnvelope>;
};

export type EmailCreateOrConnectWithoutUserInput = {
  create: EmailUncheckedCreateWithoutUserInput;
  where: EmailWhereUniqueInput;
};

export type EmailCreateWithoutUserInput = {
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  subject?: Maybe<Scalars['String']>;
};

export type EmailListRelationFilter = {
  every?: Maybe<EmailWhereInput>;
  none?: Maybe<EmailWhereInput>;
  some?: Maybe<EmailWhereInput>;
};

export type EmailMaxAggregateOutputType = {
  __typename?: 'EmailMaxAggregateOutputType';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type EmailMaxOrderByAggregateInput = {
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailMinAggregateOutputType = {
  __typename?: 'EmailMinAggregateOutputType';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type EmailMinOrderByAggregateInput = {
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailNewInput = {
  body: Scalars['String'];
  subject: Scalars['String'];
  userId: Scalars['Int'];
};

export type EmailOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type EmailOrderByWithAggregationInput = {
  _avg?: Maybe<EmailAvgOrderByAggregateInput>;
  _count?: Maybe<EmailCountOrderByAggregateInput>;
  _max?: Maybe<EmailMaxOrderByAggregateInput>;
  _min?: Maybe<EmailMinOrderByAggregateInput>;
  _sum?: Maybe<EmailSumOrderByAggregateInput>;
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailOrderByWithRelationInput = {
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum EmailScalarFieldEnum {
  Body = 'body',
  CreatedAt = 'createdAt',
  Id = 'id',
  Subject = 'subject',
  UserId = 'userId'
}

export type EmailScalarWhereInput = {
  AND?: Maybe<Array<Maybe<EmailScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<EmailScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<EmailScalarWhereInput>>>;
  body?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  subject?: Maybe<StringNullableFilter>;
  userId?: Maybe<IntFilter>;
};

export type EmailScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<EmailScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<EmailScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<EmailScalarWhereWithAggregatesInput>>>;
  body?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<IntWithAggregatesFilter>;
  subject?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<IntWithAggregatesFilter>;
};

export type EmailSumAggregateOutputType = {
  __typename?: 'EmailSumAggregateOutputType';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type EmailSumOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailUncheckedCreateInput = {
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type EmailUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<EmailCreateOrConnectWithoutUserInput>>>;
  create?: Maybe<Array<Maybe<EmailCreateWithoutUserInput>>>;
  createMany?: Maybe<EmailCreateManyUserInputEnvelope>;
};

export type EmailUncheckedCreateWithoutUserInput = {
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
};

export type EmailUncheckedUpdateInput = {
  body?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  subject?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userId?: Maybe<IntFieldUpdateOperationsInput>;
};

export type EmailUncheckedUpdateManyInput = {
  body?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  subject?: Maybe<NullableStringFieldUpdateOperationsInput>;
  userId?: Maybe<IntFieldUpdateOperationsInput>;
};

export type EmailUncheckedUpdateManyWithoutEmailsInput = {
  body?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  subject?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EmailUncheckedUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<EmailCreateOrConnectWithoutUserInput>>>;
  create?: Maybe<Array<Maybe<EmailCreateWithoutUserInput>>>;
  createMany?: Maybe<EmailCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<EmailScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<EmailUpdateWithWhereUniqueWithoutUserInput>>>;
  updateMany?: Maybe<Array<Maybe<EmailUpdateManyWithWhereWithoutUserInput>>>;
  upsert?: Maybe<Array<Maybe<EmailUpsertWithWhereUniqueWithoutUserInput>>>;
};

export type EmailUncheckedUpdateWithoutUserInput = {
  body?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  subject?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EmailUpdateInput = {
  body?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subject?: Maybe<NullableStringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutEmailsInput>;
};

export type EmailUpdateManyMutationInput = {
  body?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subject?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EmailUpdateManyWithWhereWithoutUserInput = {
  data: EmailUncheckedUpdateManyWithoutEmailsInput;
  where: EmailScalarWhereInput;
};

export type EmailUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<EmailCreateOrConnectWithoutUserInput>>>;
  create?: Maybe<Array<Maybe<EmailCreateWithoutUserInput>>>;
  createMany?: Maybe<EmailCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<EmailScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<EmailWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<EmailUpdateWithWhereUniqueWithoutUserInput>>>;
  updateMany?: Maybe<Array<Maybe<EmailUpdateManyWithWhereWithoutUserInput>>>;
  upsert?: Maybe<Array<Maybe<EmailUpsertWithWhereUniqueWithoutUserInput>>>;
};

export type EmailUpdateWithWhereUniqueWithoutUserInput = {
  data: EmailUncheckedUpdateWithoutUserInput;
  where: EmailWhereUniqueInput;
};

export type EmailUpdateWithoutUserInput = {
  body?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subject?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EmailUpsertWithWhereUniqueWithoutUserInput = {
  create: EmailUncheckedCreateWithoutUserInput;
  update: EmailUncheckedUpdateWithoutUserInput;
  where: EmailWhereUniqueInput;
};

export type EmailWhereInput = {
  AND?: Maybe<Array<Maybe<EmailWhereInput>>>;
  NOT?: Maybe<Array<Maybe<EmailWhereInput>>>;
  OR?: Maybe<Array<Maybe<EmailWhereInput>>>;
  body?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  subject?: Maybe<StringNullableFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<IntFilter>;
};

export type EmailWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type Fund = {
  __typename?: 'Fund';
  available?: Maybe<Scalars['Float']>;
  distributedAt?: Maybe<Scalars['DateTime']>;
  distributions: Array<Distribution>;
  id: Scalars['Int'];
  totalVotes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type FundDistributionsArgs = {
  cursor?: Maybe<DistributionWhereUniqueInput>;
  distinct?: Maybe<DistributionScalarFieldEnum>;
  orderBy?: Maybe<DistributionOrderByWithRelationInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DistributionWhereInput>;
};

export type FundAvgAggregateOutputType = {
  __typename?: 'FundAvgAggregateOutputType';
  available?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalVotes?: Maybe<Scalars['Float']>;
};

export type FundAvgOrderByAggregateInput = {
  available?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
};

export type FundCountAggregateOutputType = {
  __typename?: 'FundCountAggregateOutputType';
  _all: Scalars['Int'];
  available: Scalars['Int'];
  distributedAt: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  totalVotes: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FundCountOrderByAggregateInput = {
  available?: Maybe<SortOrder>;
  distributedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FundCreateInput = {
  available?: Maybe<Scalars['Float']>;
  distributedAt?: Maybe<Scalars['DateTime']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutFundInput>;
  status: Scalars['String'];
  totalVotes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FundCreateManyInput = {
  available?: Maybe<Scalars['Float']>;
  distributedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  totalVotes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FundCreateNestedOneWithoutDistributionsInput = {
  connect?: Maybe<FundWhereUniqueInput>;
  connectOrCreate?: Maybe<FundCreateOrConnectWithoutDistributionsInput>;
  create?: Maybe<FundUncheckedCreateWithoutDistributionsInput>;
};

export type FundCreateOrConnectWithoutDistributionsInput = {
  create: FundUncheckedCreateWithoutDistributionsInput;
  where: FundWhereUniqueInput;
};

export type FundCreateWithoutDistributionsInput = {
  available?: Maybe<Scalars['Float']>;
  distributedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  totalVotes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FundMaxAggregateOutputType = {
  __typename?: 'FundMaxAggregateOutputType';
  available?: Maybe<Scalars['Float']>;
  distributedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  totalVotes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FundMaxOrderByAggregateInput = {
  available?: Maybe<SortOrder>;
  distributedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FundMinAggregateOutputType = {
  __typename?: 'FundMinAggregateOutputType';
  available?: Maybe<Scalars['Float']>;
  distributedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  totalVotes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FundMinOrderByAggregateInput = {
  available?: Maybe<SortOrder>;
  distributedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FundOrderByWithAggregationInput = {
  _avg?: Maybe<FundAvgOrderByAggregateInput>;
  _count?: Maybe<FundCountOrderByAggregateInput>;
  _max?: Maybe<FundMaxOrderByAggregateInput>;
  _min?: Maybe<FundMinOrderByAggregateInput>;
  _sum?: Maybe<FundSumOrderByAggregateInput>;
  available?: Maybe<SortOrder>;
  distributedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FundOrderByWithRelationInput = {
  available?: Maybe<SortOrder>;
  distributedAt?: Maybe<SortOrder>;
  distributions?: Maybe<DistributionOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FundRelationFilter = {
  is?: Maybe<FundWhereInput>;
  isNot?: Maybe<FundWhereInput>;
};

export enum FundScalarFieldEnum {
  Available = 'available',
  DistributedAt = 'distributedAt',
  Id = 'id',
  Status = 'status',
  TotalVotes = 'totalVotes',
  UpdatedAt = 'updatedAt'
}

export type FundScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<FundScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<FundScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<FundScalarWhereWithAggregatesInput>>>;
  available?: Maybe<FloatNullableWithAggregatesFilter>;
  distributedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  totalVotes?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type FundSumAggregateOutputType = {
  __typename?: 'FundSumAggregateOutputType';
  available?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
};

export type FundSumOrderByAggregateInput = {
  available?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
};

export type FundUncheckedCreateInput = {
  available?: Maybe<Scalars['Float']>;
  distributedAt?: Maybe<Scalars['DateTime']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutFundInput>;
  id?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  totalVotes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FundUncheckedCreateWithoutDistributionsInput = {
  available?: Maybe<Scalars['Float']>;
  distributedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  totalVotes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FundUncheckedUpdateInput = {
  available?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  distributedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutFundInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FundUncheckedUpdateManyInput = {
  available?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  distributedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FundUncheckedUpdateWithoutDistributionsInput = {
  available?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  distributedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FundUpdateInput = {
  available?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  distributedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutFundInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FundUpdateManyMutationInput = {
  available?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  distributedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FundUpdateOneRequiredWithoutDistributionsInput = {
  connect?: Maybe<FundWhereUniqueInput>;
  connectOrCreate?: Maybe<FundCreateOrConnectWithoutDistributionsInput>;
  create?: Maybe<FundUncheckedCreateWithoutDistributionsInput>;
  update?: Maybe<FundUncheckedUpdateWithoutDistributionsInput>;
  upsert?: Maybe<FundUpsertWithoutDistributionsInput>;
};

export type FundUpdateWithoutDistributionsInput = {
  available?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  distributedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FundUpsertWithoutDistributionsInput = {
  create: FundUncheckedCreateWithoutDistributionsInput;
  update: FundUncheckedUpdateWithoutDistributionsInput;
};

export type FundWhereInput = {
  AND?: Maybe<Array<Maybe<FundWhereInput>>>;
  NOT?: Maybe<Array<Maybe<FundWhereInput>>>;
  OR?: Maybe<Array<Maybe<FundWhereInput>>>;
  available?: Maybe<FloatNullableFilter>;
  distributedAt?: Maybe<DateTimeNullableFilter>;
  distributions?: Maybe<DistributionListRelationFilter>;
  id?: Maybe<IntFilter>;
  status?: Maybe<StringFilter>;
  totalVotes?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FundWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type IntWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _sum?: Maybe<NestedIntFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type LoginInput = {
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  username: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  emailNewMutation: Email;
  favoriteAdd: User;
  favoriteRemove: User;
  login: User;
  logout?: Maybe<Scalars['Boolean']>;
  passwordForgot: Scalars['Boolean'];
  passwordReset: User;
  passwordUpdate: User;
  resendCode: User;
  resetFund: Fund;
  sellNew: Transaction;
  signUp1: User;
  signUp2: User;
  taxNew: Transaction;
  updateFund: Fund;
  userNew: User;
  userUpdate: User;
  usernameUpdate: User;
  verifyCode: User;
  verifyToken: User;
  voteAdd: Vote;
};


export type MutationEmailNewMutationArgs = {
  data: EmailNewInput;
};


export type MutationFavoriteAddArgs = {
  sellerId: Scalars['Int'];
};


export type MutationFavoriteRemoveArgs = {
  sellerId: Scalars['Int'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationPasswordForgotArgs = {
  data: PasswordForgotInput;
};


export type MutationPasswordResetArgs = {
  data: PasswordResetInput;
};


export type MutationPasswordUpdateArgs = {
  data?: Maybe<PasswordUpdateInput>;
};


export type MutationResendCodeArgs = {
  data: ResendCodeInput;
};


export type MutationSellNewArgs = {
  data: SellNewInput;
};


export type MutationSignUp1Args = {
  data: SignUp1Input;
};


export type MutationSignUp2Args = {
  data: SignUp2Input;
};


export type MutationTaxNewArgs = {
  data: TaxNewInput;
};


export type MutationUpdateFundArgs = {
  data: UpdateFundInput;
};


export type MutationUserNewArgs = {
  data: UserNewInput;
};


export type MutationUserUpdateArgs = {
  data: SignUp2Input;
  userId: Scalars['Int'];
};


export type MutationUsernameUpdateArgs = {
  data: UsernameInput;
  userId: Scalars['Int'];
};


export type MutationVerifyCodeArgs = {
  data: VerifyCodeInput;
};


export type MutationVerifyTokenArgs = {
  tokenCode: Scalars['String'];
};


export type MutationVoteAddArgs = {
  sellerId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedBoolFilter>;
  _min?: Maybe<NestedBoolFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _sum?: Maybe<NestedIntFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedStringFilter>;
  _min?: Maybe<NestedStringFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type PasswordForgotInput = {
  username: Scalars['String'];
};

export type PasswordResetInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordUpdateInput = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  email: Email;
  emails: Array<Email>;
  emailsCount?: Maybe<Scalars['Int']>;
  favorites: Array<User>;
  favoritesCount: Scalars['Int'];
  findFund?: Maybe<Fund>;
  me?: Maybe<User>;
  notForProfit: Array<User>;
  notForProfitCount: Scalars['Int'];
  purchases: Array<Transaction>;
  purchasesCount: Scalars['Int'];
  sales: Array<Transaction>;
  salesCount: Scalars['Int'];
  sellers: Array<User>;
  sellersCount: Scalars['Int'];
  totalVotes: Scalars['Int'];
  user?: Maybe<User>;
  username?: Maybe<User>;
  users: Array<User>;
  usersCount: Scalars['Int'];
  votes: Array<User>;
  votesCount: Scalars['Int'];
};


export type QueryEmailArgs = {
  emailId: Scalars['Int'];
};


export type QueryEmailsArgs = {
  cursor?: Maybe<EmailWhereUniqueInput>;
  distinct?: Maybe<EmailScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<EmailOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailWhereInput>;
};


export type QueryEmailsCountArgs = {
  cursor?: Maybe<EmailWhereUniqueInput>;
  distinct?: Maybe<EmailScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<EmailOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailWhereInput>;
};


export type QueryFavoritesArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distance?: Maybe<DistanceInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryFavoritesCountArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distance?: Maybe<DistanceInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryNotForProfitArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distance?: Maybe<DistanceInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryNotForProfitCountArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distance?: Maybe<DistanceInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryPurchasesArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type QueryPurchasesCountArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type QuerySalesArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type QuerySalesCountArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type QuerySellersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distance?: Maybe<DistanceInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QuerySellersCountArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distance?: Maybe<DistanceInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsernameArgs = {
  username: Scalars['String'];
};


export type QueryUsersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryUsersCountArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryVotesArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distance?: Maybe<DistanceInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryVotesCountArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distance?: Maybe<DistanceInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};

export type ResendCodeInput = {
  email: Scalars['String'];
};

export type SellNewInput = {
  buyerId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
};

export type SignUp1Input = {
  accept: Scalars['Boolean'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  repeatPassword: Scalars['String'];
  type: Scalars['String'];
};

export type SignUp2Input = {
  accessibility?: Maybe<AccessibilityInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  bsb?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tax?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedStringFilter>;
  _min?: Maybe<NestedStringFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type TaxNewInput = {
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  sellerId: Scalars['Int'];
  tax: Scalars['Float'];
};

export type Transaction = {
  __typename?: 'Transaction';
  balance: Scalars['Float'];
  buyer: User;
  buyerId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  price: Scalars['Float'];
  seller: User;
  sellerId: Scalars['Int'];
  tax: Scalars['Float'];
  type: Scalars['String'];
};

export type TransactionAvgAggregateOutputType = {
  __typename?: 'TransactionAvgAggregateOutputType';
  balance?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sellerId?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export type TransactionAvgOrderByAggregateInput = {
  balance?: Maybe<SortOrder>;
  buyerId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sellerId?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
};

export type TransactionCountAggregateOutputType = {
  __typename?: 'TransactionCountAggregateOutputType';
  _all: Scalars['Int'];
  balance: Scalars['Int'];
  buyerId: Scalars['Int'];
  createdAt: Scalars['Int'];
  date: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  price: Scalars['Int'];
  sellerId: Scalars['Int'];
  tax: Scalars['Int'];
  type: Scalars['Int'];
};

export type TransactionCountOrderByAggregateInput = {
  balance?: Maybe<SortOrder>;
  buyerId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sellerId?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type TransactionCreateInput = {
  balance: Scalars['Float'];
  buyer: UserCreateNestedOneWithoutPurchasesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  seller: UserCreateNestedOneWithoutSalesInput;
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionCreateManyBuyerInput = {
  balance: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  sellerId: Scalars['Int'];
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionCreateManyBuyerInputEnvelope = {
  data: TransactionCreateManyBuyerInput;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyInput = {
  balance: Scalars['Float'];
  buyerId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  sellerId: Scalars['Int'];
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionCreateManySellerInput = {
  balance: Scalars['Float'];
  buyerId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionCreateManySellerInputEnvelope = {
  data: TransactionCreateManySellerInput;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateNestedManyWithoutBuyerInput = {
  connect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<TransactionCreateOrConnectWithoutBuyerInput>>>;
  create?: Maybe<Array<Maybe<TransactionCreateWithoutBuyerInput>>>;
  createMany?: Maybe<TransactionCreateManyBuyerInputEnvelope>;
};

export type TransactionCreateNestedManyWithoutSellerInput = {
  connect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<TransactionCreateOrConnectWithoutSellerInput>>>;
  create?: Maybe<Array<Maybe<TransactionCreateWithoutSellerInput>>>;
  createMany?: Maybe<TransactionCreateManySellerInputEnvelope>;
};

export type TransactionCreateOrConnectWithoutBuyerInput = {
  create: TransactionUncheckedCreateWithoutBuyerInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutSellerInput = {
  create: TransactionUncheckedCreateWithoutSellerInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutBuyerInput = {
  balance: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  seller: UserCreateNestedOneWithoutSalesInput;
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionCreateWithoutSellerInput = {
  balance: Scalars['Float'];
  buyer: UserCreateNestedOneWithoutPurchasesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionListRelationFilter = {
  every?: Maybe<TransactionWhereInput>;
  none?: Maybe<TransactionWhereInput>;
  some?: Maybe<TransactionWhereInput>;
};

export type TransactionMaxAggregateOutputType = {
  __typename?: 'TransactionMaxAggregateOutputType';
  balance?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  sellerId?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type TransactionMaxOrderByAggregateInput = {
  balance?: Maybe<SortOrder>;
  buyerId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sellerId?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type TransactionMinAggregateOutputType = {
  __typename?: 'TransactionMinAggregateOutputType';
  balance?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  sellerId?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type TransactionMinOrderByAggregateInput = {
  balance?: Maybe<SortOrder>;
  buyerId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sellerId?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type TransactionOrderByWithAggregationInput = {
  _avg?: Maybe<TransactionAvgOrderByAggregateInput>;
  _count?: Maybe<TransactionCountOrderByAggregateInput>;
  _max?: Maybe<TransactionMaxOrderByAggregateInput>;
  _min?: Maybe<TransactionMinOrderByAggregateInput>;
  _sum?: Maybe<TransactionSumOrderByAggregateInput>;
  balance?: Maybe<SortOrder>;
  buyerId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sellerId?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type TransactionOrderByWithRelationInput = {
  balance?: Maybe<SortOrder>;
  buyer?: Maybe<UserOrderByWithRelationInput>;
  buyerId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  seller?: Maybe<UserOrderByWithRelationInput>;
  sellerId?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export enum TransactionScalarFieldEnum {
  Balance = 'balance',
  BuyerId = 'buyerId',
  CreatedAt = 'createdAt',
  Date = 'date',
  Description = 'description',
  Id = 'id',
  Price = 'price',
  SellerId = 'sellerId',
  Tax = 'tax',
  Type = 'type'
}

export type TransactionScalarWhereInput = {
  AND?: Maybe<Array<Maybe<TransactionScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<TransactionScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<TransactionScalarWhereInput>>>;
  balance?: Maybe<FloatFilter>;
  buyerId?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  date?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  price?: Maybe<FloatFilter>;
  sellerId?: Maybe<IntFilter>;
  tax?: Maybe<FloatFilter>;
  type?: Maybe<StringFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<TransactionScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<TransactionScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<TransactionScalarWhereWithAggregatesInput>>>;
  balance?: Maybe<FloatWithAggregatesFilter>;
  buyerId?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  date?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<IntWithAggregatesFilter>;
  price?: Maybe<FloatWithAggregatesFilter>;
  sellerId?: Maybe<IntWithAggregatesFilter>;
  tax?: Maybe<FloatWithAggregatesFilter>;
  type?: Maybe<StringWithAggregatesFilter>;
};

export type TransactionSumAggregateOutputType = {
  __typename?: 'TransactionSumAggregateOutputType';
  balance?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  sellerId?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Float']>;
};

export type TransactionSumOrderByAggregateInput = {
  balance?: Maybe<SortOrder>;
  buyerId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sellerId?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
};

export type TransactionUncheckedCreateInput = {
  balance: Scalars['Float'];
  buyerId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  sellerId: Scalars['Int'];
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionUncheckedCreateNestedManyWithoutBuyerInput = {
  connect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<TransactionCreateOrConnectWithoutBuyerInput>>>;
  create?: Maybe<Array<Maybe<TransactionCreateWithoutBuyerInput>>>;
  createMany?: Maybe<TransactionCreateManyBuyerInputEnvelope>;
};

export type TransactionUncheckedCreateNestedManyWithoutSellerInput = {
  connect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<TransactionCreateOrConnectWithoutSellerInput>>>;
  create?: Maybe<Array<Maybe<TransactionCreateWithoutSellerInput>>>;
  createMany?: Maybe<TransactionCreateManySellerInputEnvelope>;
};

export type TransactionUncheckedCreateWithoutBuyerInput = {
  balance: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  sellerId: Scalars['Int'];
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionUncheckedCreateWithoutSellerInput = {
  balance: Scalars['Float'];
  buyerId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  tax: Scalars['Float'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionUncheckedUpdateInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  buyerId?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sellerId?: Maybe<IntFieldUpdateOperationsInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUncheckedUpdateManyInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  buyerId?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sellerId?: Maybe<IntFieldUpdateOperationsInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUncheckedUpdateManyWithoutBuyerInput = {
  connect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<TransactionCreateOrConnectWithoutBuyerInput>>>;
  create?: Maybe<Array<Maybe<TransactionCreateWithoutBuyerInput>>>;
  createMany?: Maybe<TransactionCreateManyBuyerInputEnvelope>;
  delete?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<TransactionScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<TransactionUpdateWithWhereUniqueWithoutBuyerInput>>>;
  updateMany?: Maybe<Array<Maybe<TransactionUpdateManyWithWhereWithoutBuyerInput>>>;
  upsert?: Maybe<Array<Maybe<TransactionUpsertWithWhereUniqueWithoutBuyerInput>>>;
};

export type TransactionUncheckedUpdateManyWithoutPurchasesInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sellerId?: Maybe<IntFieldUpdateOperationsInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUncheckedUpdateManyWithoutSalesInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  buyerId?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUncheckedUpdateManyWithoutSellerInput = {
  connect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<TransactionCreateOrConnectWithoutSellerInput>>>;
  create?: Maybe<Array<Maybe<TransactionCreateWithoutSellerInput>>>;
  createMany?: Maybe<TransactionCreateManySellerInputEnvelope>;
  delete?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<TransactionScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<TransactionUpdateWithWhereUniqueWithoutSellerInput>>>;
  updateMany?: Maybe<Array<Maybe<TransactionUpdateManyWithWhereWithoutSellerInput>>>;
  upsert?: Maybe<Array<Maybe<TransactionUpsertWithWhereUniqueWithoutSellerInput>>>;
};

export type TransactionUncheckedUpdateWithoutBuyerInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sellerId?: Maybe<IntFieldUpdateOperationsInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUncheckedUpdateWithoutSellerInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  buyerId?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUpdateInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  buyer?: Maybe<UserUpdateOneRequiredWithoutPurchasesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  seller?: Maybe<UserUpdateOneRequiredWithoutSalesInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyMutationInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyWithWhereWithoutBuyerInput = {
  data: TransactionUncheckedUpdateManyWithoutPurchasesInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithWhereWithoutSellerInput = {
  data: TransactionUncheckedUpdateManyWithoutSalesInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutBuyerInput = {
  connect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<TransactionCreateOrConnectWithoutBuyerInput>>>;
  create?: Maybe<Array<Maybe<TransactionCreateWithoutBuyerInput>>>;
  createMany?: Maybe<TransactionCreateManyBuyerInputEnvelope>;
  delete?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<TransactionScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<TransactionUpdateWithWhereUniqueWithoutBuyerInput>>>;
  updateMany?: Maybe<Array<Maybe<TransactionUpdateManyWithWhereWithoutBuyerInput>>>;
  upsert?: Maybe<Array<Maybe<TransactionUpsertWithWhereUniqueWithoutBuyerInput>>>;
};

export type TransactionUpdateManyWithoutSellerInput = {
  connect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<TransactionCreateOrConnectWithoutSellerInput>>>;
  create?: Maybe<Array<Maybe<TransactionCreateWithoutSellerInput>>>;
  createMany?: Maybe<TransactionCreateManySellerInputEnvelope>;
  delete?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<TransactionScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<TransactionWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<TransactionUpdateWithWhereUniqueWithoutSellerInput>>>;
  updateMany?: Maybe<Array<Maybe<TransactionUpdateManyWithWhereWithoutSellerInput>>>;
  upsert?: Maybe<Array<Maybe<TransactionUpsertWithWhereUniqueWithoutSellerInput>>>;
};

export type TransactionUpdateWithWhereUniqueWithoutBuyerInput = {
  data: TransactionUncheckedUpdateWithoutBuyerInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutSellerInput = {
  data: TransactionUncheckedUpdateWithoutSellerInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutBuyerInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  seller?: Maybe<UserUpdateOneRequiredWithoutSalesInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUpdateWithoutSellerInput = {
  balance?: Maybe<FloatFieldUpdateOperationsInput>;
  buyer?: Maybe<UserUpdateOneRequiredWithoutPurchasesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  tax?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TransactionUpsertWithWhereUniqueWithoutBuyerInput = {
  create: TransactionUncheckedCreateWithoutBuyerInput;
  update: TransactionUncheckedUpdateWithoutBuyerInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutSellerInput = {
  create: TransactionUncheckedCreateWithoutSellerInput;
  update: TransactionUncheckedUpdateWithoutSellerInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionWhereInput = {
  AND?: Maybe<Array<Maybe<TransactionWhereInput>>>;
  NOT?: Maybe<Array<Maybe<TransactionWhereInput>>>;
  OR?: Maybe<Array<Maybe<TransactionWhereInput>>>;
  balance?: Maybe<FloatFilter>;
  buyer?: Maybe<UserWhereInput>;
  buyerId?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  date?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  price?: Maybe<FloatFilter>;
  seller?: Maybe<UserWhereInput>;
  sellerId?: Maybe<IntFilter>;
  tax?: Maybe<FloatFilter>;
  type?: Maybe<StringFilter>;
};

export type TransactionWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type UpdateFundInput = {
  available: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  accessibility?: Maybe<Accessibility>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  bsb?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  favored: Array<User>;
  favorite: Array<User>;
  hash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  purchases: Array<Transaction>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales: Array<Transaction>;
  status: Scalars['String'];
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance: Scalars['Float'];
  totalSales: Scalars['Float'];
  totalTaxes: Scalars['Float'];
  totalVoted: Scalars['Int'];
  totalVotes: Scalars['Int'];
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<Array<Vote>>;
  votes: Array<User>;
};


export type UserFavoredArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type UserFavoriteArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type UserPurchasesArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
  orderBy?: Maybe<TransactionOrderByWithRelationInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type UserSalesArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
  orderBy?: Maybe<TransactionOrderByWithRelationInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};

export type UserAvgAggregateOutputType = {
  __typename?: 'UserAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Float']>;
  totalVotes?: Maybe<Scalars['Float']>;
};

export type UserAvgOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  totalBalance?: Maybe<SortOrder>;
  totalSales?: Maybe<SortOrder>;
  totalTaxes?: Maybe<SortOrder>;
  totalVoted?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
};

export type UserCountAggregateOutputType = {
  __typename?: 'UserCountAggregateOutputType';
  _all: Scalars['Int'];
  accountName: Scalars['Int'];
  accountNumber: Scalars['Int'];
  bsb: Scalars['Int'];
  createdAt: Scalars['Int'];
  dateOfBirth: Scalars['Int'];
  description: Scalars['Int'];
  email: Scalars['Int'];
  hash: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  name: Scalars['Int'];
  notification: Scalars['Int'];
  phone: Scalars['Int'];
  representativesId: Scalars['Int'];
  representativesName: Scalars['Int'];
  representativesPosition: Scalars['Int'];
  status: Scalars['Int'];
  step2: Scalars['Int'];
  tax: Scalars['Int'];
  totalBalance: Scalars['Int'];
  totalSales: Scalars['Int'];
  totalTaxes: Scalars['Int'];
  totalVoted: Scalars['Int'];
  totalVotes: Scalars['Int'];
  type: Scalars['Int'];
  username: Scalars['Int'];
  verificationCode: Scalars['Int'];
  verified: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  bsb?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notification?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  representativesId?: Maybe<SortOrder>;
  representativesName?: Maybe<SortOrder>;
  representativesPosition?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  step2?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  totalBalance?: Maybe<SortOrder>;
  totalSales?: Maybe<SortOrder>;
  totalTaxes?: Maybe<SortOrder>;
  totalVoted?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  verificationCode?: Maybe<SortOrder>;
  verified?: Maybe<SortOrder>;
};

export type UserCreateInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateManyInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutFavoredInput = {
  connect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<UserCreateOrConnectWithoutFavoredInput>>>;
  create?: Maybe<Array<Maybe<UserCreateWithoutFavoredInput>>>;
};

export type UserCreateNestedManyWithoutFavoriteInput = {
  connect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<UserCreateOrConnectWithoutFavoriteInput>>>;
  create?: Maybe<Array<Maybe<UserCreateWithoutFavoriteInput>>>;
};

export type UserCreateNestedOneWithoutAccessibilityInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAccessibilityInput>;
  create?: Maybe<UserUncheckedCreateWithoutAccessibilityInput>;
};

export type UserCreateNestedOneWithoutAddressInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAddressInput>;
  create?: Maybe<UserUncheckedCreateWithoutAddressInput>;
};

export type UserCreateNestedOneWithoutDistributionsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutDistributionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutDistributionsInput>;
};

export type UserCreateNestedOneWithoutEmailsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailsInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailsInput>;
};

export type UserCreateNestedOneWithoutPurchasesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPurchasesInput>;
  create?: Maybe<UserUncheckedCreateWithoutPurchasesInput>;
};

export type UserCreateNestedOneWithoutSalesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSalesInput>;
  create?: Maybe<UserUncheckedCreateWithoutSalesInput>;
};

export type UserCreateNestedOneWithoutVotedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVotedInput>;
  create?: Maybe<UserUncheckedCreateWithoutVotedInput>;
};

export type UserCreateNestedOneWithoutVotesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVotesInput>;
  create?: Maybe<UserUncheckedCreateWithoutVotesInput>;
};

export type UserCreateOrConnectWithoutAccessibilityInput = {
  create: UserUncheckedCreateWithoutAccessibilityInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAddressInput = {
  create: UserUncheckedCreateWithoutAddressInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDistributionsInput = {
  create: UserUncheckedCreateWithoutDistributionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailsInput = {
  create: UserUncheckedCreateWithoutEmailsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFavoredInput = {
  create: UserUncheckedCreateWithoutFavoredInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFavoriteInput = {
  create: UserUncheckedCreateWithoutFavoriteInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPurchasesInput = {
  create: UserUncheckedCreateWithoutPurchasesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSalesInput = {
  create: UserUncheckedCreateWithoutSalesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutVotedInput = {
  create: UserUncheckedCreateWithoutVotedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutVotesInput = {
  create: UserUncheckedCreateWithoutVotesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAccessibilityInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutAddressInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutDistributionsInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutEmailsInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutFavoredInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutFavoriteInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutPurchasesInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutSalesInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutVotedInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  votes?: Maybe<VoteCreateNestedManyWithoutFromInput>;
};

export type UserCreateWithoutVotesInput = {
  accessibility?: Maybe<AccessibilityCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailCreateNestedManyWithoutUserInput>;
  favored?: Maybe<UserCreateNestedManyWithoutFavoriteInput>;
  favorite?: Maybe<UserCreateNestedManyWithoutFavoredInput>;
  hash?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteCreateNestedManyWithoutToInput>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
};

export type UserMaxAggregateOutputType = {
  __typename?: 'UserMaxAggregateOutputType';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserMaxOrderByAggregateInput = {
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  bsb?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notification?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  representativesId?: Maybe<SortOrder>;
  representativesName?: Maybe<SortOrder>;
  representativesPosition?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  step2?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  totalBalance?: Maybe<SortOrder>;
  totalSales?: Maybe<SortOrder>;
  totalTaxes?: Maybe<SortOrder>;
  totalVoted?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  verificationCode?: Maybe<SortOrder>;
  verified?: Maybe<SortOrder>;
};

export type UserMinAggregateOutputType = {
  __typename?: 'UserMinAggregateOutputType';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserMinOrderByAggregateInput = {
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  bsb?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notification?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  representativesId?: Maybe<SortOrder>;
  representativesName?: Maybe<SortOrder>;
  representativesPosition?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  step2?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  totalBalance?: Maybe<SortOrder>;
  totalSales?: Maybe<SortOrder>;
  totalTaxes?: Maybe<SortOrder>;
  totalVoted?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  verificationCode?: Maybe<SortOrder>;
  verified?: Maybe<SortOrder>;
};

export type UserNewInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  type: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _avg?: Maybe<UserAvgOrderByAggregateInput>;
  _count?: Maybe<UserCountOrderByAggregateInput>;
  _max?: Maybe<UserMaxOrderByAggregateInput>;
  _min?: Maybe<UserMinOrderByAggregateInput>;
  _sum?: Maybe<UserSumOrderByAggregateInput>;
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  bsb?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notification?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  representativesId?: Maybe<SortOrder>;
  representativesName?: Maybe<SortOrder>;
  representativesPosition?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  step2?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  totalBalance?: Maybe<SortOrder>;
  totalSales?: Maybe<SortOrder>;
  totalTaxes?: Maybe<SortOrder>;
  totalVoted?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  verificationCode?: Maybe<SortOrder>;
  verified?: Maybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  accessibility?: Maybe<AccessibilityOrderByWithRelationInput>;
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  address?: Maybe<AddressOrderByWithRelationInput>;
  bsb?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  distributions?: Maybe<DistributionOrderByRelationAggregateInput>;
  email?: Maybe<SortOrder>;
  emails?: Maybe<EmailOrderByRelationAggregateInput>;
  favored?: Maybe<UserOrderByRelationAggregateInput>;
  favorite?: Maybe<UserOrderByRelationAggregateInput>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notification?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  purchases?: Maybe<TransactionOrderByRelationAggregateInput>;
  representativesId?: Maybe<SortOrder>;
  representativesName?: Maybe<SortOrder>;
  representativesPosition?: Maybe<SortOrder>;
  sales?: Maybe<TransactionOrderByRelationAggregateInput>;
  status?: Maybe<SortOrder>;
  step2?: Maybe<SortOrder>;
  tax?: Maybe<SortOrder>;
  totalBalance?: Maybe<SortOrder>;
  totalSales?: Maybe<SortOrder>;
  totalTaxes?: Maybe<SortOrder>;
  totalVoted?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  verificationCode?: Maybe<SortOrder>;
  verified?: Maybe<SortOrder>;
  voted?: Maybe<VoteOrderByRelationAggregateInput>;
  votes?: Maybe<VoteOrderByRelationAggregateInput>;
};

export type UserPhoneVerifiedCompoundUniqueInput = {
  phone: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  AccountName = 'accountName',
  AccountNumber = 'accountNumber',
  Bsb = 'bsb',
  CreatedAt = 'createdAt',
  DateOfBirth = 'dateOfBirth',
  Description = 'description',
  Email = 'email',
  Hash = 'hash',
  Id = 'id',
  Image = 'image',
  Name = 'name',
  Notification = 'notification',
  Phone = 'phone',
  RepresentativesId = 'representativesId',
  RepresentativesName = 'representativesName',
  RepresentativesPosition = 'representativesPosition',
  Status = 'status',
  Step2 = 'step2',
  Tax = 'tax',
  TotalBalance = 'totalBalance',
  TotalSales = 'totalSales',
  TotalTaxes = 'totalTaxes',
  TotalVoted = 'totalVoted',
  TotalVotes = 'totalVotes',
  Type = 'type',
  Username = 'username',
  VerificationCode = 'verificationCode',
  Verified = 'verified'
}

export type UserScalarWhereInput = {
  AND?: Maybe<Array<Maybe<UserScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<UserScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserScalarWhereInput>>>;
  accountName?: Maybe<StringNullableFilter>;
  accountNumber?: Maybe<StringNullableFilter>;
  bsb?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dateOfBirth?: Maybe<DateTimeNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  email?: Maybe<StringFilter>;
  hash?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  image?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  notification?: Maybe<BoolFilter>;
  phone?: Maybe<StringNullableFilter>;
  representativesId?: Maybe<StringNullableFilter>;
  representativesName?: Maybe<StringNullableFilter>;
  representativesPosition?: Maybe<StringNullableFilter>;
  status?: Maybe<StringFilter>;
  step2?: Maybe<BoolNullableFilter>;
  tax?: Maybe<BoolNullableFilter>;
  totalBalance?: Maybe<FloatFilter>;
  totalSales?: Maybe<FloatFilter>;
  totalTaxes?: Maybe<FloatFilter>;
  totalVoted?: Maybe<IntFilter>;
  totalVotes?: Maybe<IntFilter>;
  type?: Maybe<StringFilter>;
  username?: Maybe<StringFilter>;
  verificationCode?: Maybe<StringNullableFilter>;
  verified?: Maybe<BoolNullableFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<UserScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<UserScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<UserScalarWhereWithAggregatesInput>>>;
  accountName?: Maybe<StringNullableWithAggregatesFilter>;
  accountNumber?: Maybe<StringNullableWithAggregatesFilter>;
  bsb?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  dateOfBirth?: Maybe<DateTimeNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  hash?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<IntWithAggregatesFilter>;
  image?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringNullableWithAggregatesFilter>;
  notification?: Maybe<BoolWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  representativesId?: Maybe<StringNullableWithAggregatesFilter>;
  representativesName?: Maybe<StringNullableWithAggregatesFilter>;
  representativesPosition?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  step2?: Maybe<BoolNullableWithAggregatesFilter>;
  tax?: Maybe<BoolNullableWithAggregatesFilter>;
  totalBalance?: Maybe<FloatWithAggregatesFilter>;
  totalSales?: Maybe<FloatWithAggregatesFilter>;
  totalTaxes?: Maybe<FloatWithAggregatesFilter>;
  totalVoted?: Maybe<IntWithAggregatesFilter>;
  totalVotes?: Maybe<IntWithAggregatesFilter>;
  type?: Maybe<StringWithAggregatesFilter>;
  username?: Maybe<StringWithAggregatesFilter>;
  verificationCode?: Maybe<StringNullableWithAggregatesFilter>;
  verified?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type UserSumAggregateOutputType = {
  __typename?: 'UserSumAggregateOutputType';
  id?: Maybe<Scalars['Int']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
};

export type UserSumOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  totalBalance?: Maybe<SortOrder>;
  totalSales?: Maybe<SortOrder>;
  totalTaxes?: Maybe<SortOrder>;
  totalVoted?: Maybe<SortOrder>;
  totalVotes?: Maybe<SortOrder>;
};

export type UserUncheckedCreateInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutAccessibilityInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutAddressInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutDistributionsInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutEmailsInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutFavoredInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutFavoriteInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutPurchasesInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutSalesInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutVotedInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  votes?: Maybe<VoteUncheckedCreateNestedManyWithoutFromInput>;
};

export type UserUncheckedCreateWithoutVotesInput = {
  accessibility?: Maybe<AccessibilityUncheckedCreateNestedOneWithoutUserInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUncheckedCreateNestedOneWithoutUserInput>;
  bsb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  distributions?: Maybe<DistributionUncheckedCreateNestedManyWithoutUserInput>;
  email: Scalars['String'];
  emails?: Maybe<EmailUncheckedCreateNestedManyWithoutUserInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  purchases?: Maybe<TransactionUncheckedCreateNestedManyWithoutBuyerInput>;
  representativesId?: Maybe<Scalars['String']>;
  representativesName?: Maybe<Scalars['String']>;
  representativesPosition?: Maybe<Scalars['String']>;
  sales?: Maybe<TransactionUncheckedCreateNestedManyWithoutSellerInput>;
  status?: Maybe<Scalars['String']>;
  step2?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  totalTaxes?: Maybe<Scalars['Float']>;
  totalVoted?: Maybe<Scalars['Int']>;
  totalVotes?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  voted?: Maybe<VoteUncheckedCreateNestedManyWithoutToInput>;
};

export type UserUncheckedUpdateInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateManyInput = {
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type UserUncheckedUpdateManyWithoutFavoredInput = {
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type UserUncheckedUpdateManyWithoutFavoriteInput = {
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type UserUncheckedUpdateWithoutAccessibilityInput = {
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutAddressInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutDistributionsInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutEmailsInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutFavoredInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutFavoriteInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutPurchasesInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutSalesInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutVotedInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  votes?: Maybe<VoteUncheckedUpdateManyWithoutFromInput>;
};

export type UserUncheckedUpdateWithoutVotesInput = {
  accessibility?: Maybe<AccessibilityUncheckedUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUncheckedUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUncheckedUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUncheckedUpdateManyWithoutUserInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<IntFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUncheckedUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUncheckedUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUncheckedUpdateManyWithoutToInput>;
};

export type UserUpdateInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateManyMutationInput = {
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutFavoredInput = {
  data: UserUncheckedUpdateManyWithoutFavoriteInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutFavoriteInput = {
  data: UserUncheckedUpdateManyWithoutFavoredInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutFavoredInput = {
  connect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<UserCreateOrConnectWithoutFavoredInput>>>;
  create?: Maybe<Array<Maybe<UserCreateWithoutFavoredInput>>>;
  delete?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<UserScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<UserUpdateWithWhereUniqueWithoutFavoredInput>>>;
  updateMany?: Maybe<Array<Maybe<UserUpdateManyWithWhereWithoutFavoredInput>>>;
  upsert?: Maybe<Array<Maybe<UserUpsertWithWhereUniqueWithoutFavoredInput>>>;
};

export type UserUpdateManyWithoutFavoriteInput = {
  connect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<UserCreateOrConnectWithoutFavoriteInput>>>;
  create?: Maybe<Array<Maybe<UserCreateWithoutFavoriteInput>>>;
  delete?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<UserScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<UserUpdateWithWhereUniqueWithoutFavoriteInput>>>;
  updateMany?: Maybe<Array<Maybe<UserUpdateManyWithWhereWithoutFavoriteInput>>>;
  upsert?: Maybe<Array<Maybe<UserUpsertWithWhereUniqueWithoutFavoriteInput>>>;
};

export type UserUpdateOneRequiredWithoutDistributionsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutDistributionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutDistributionsInput>;
  update?: Maybe<UserUncheckedUpdateWithoutDistributionsInput>;
  upsert?: Maybe<UserUpsertWithoutDistributionsInput>;
};

export type UserUpdateOneRequiredWithoutPurchasesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPurchasesInput>;
  create?: Maybe<UserUncheckedCreateWithoutPurchasesInput>;
  update?: Maybe<UserUncheckedUpdateWithoutPurchasesInput>;
  upsert?: Maybe<UserUpsertWithoutPurchasesInput>;
};

export type UserUpdateOneRequiredWithoutSalesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSalesInput>;
  create?: Maybe<UserUncheckedCreateWithoutSalesInput>;
  update?: Maybe<UserUncheckedUpdateWithoutSalesInput>;
  upsert?: Maybe<UserUpsertWithoutSalesInput>;
};

export type UserUpdateOneRequiredWithoutVotedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVotedInput>;
  create?: Maybe<UserUncheckedCreateWithoutVotedInput>;
  update?: Maybe<UserUncheckedUpdateWithoutVotedInput>;
  upsert?: Maybe<UserUpsertWithoutVotedInput>;
};

export type UserUpdateOneRequiredWithoutVotesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVotesInput>;
  create?: Maybe<UserUncheckedCreateWithoutVotesInput>;
  update?: Maybe<UserUncheckedUpdateWithoutVotesInput>;
  upsert?: Maybe<UserUpsertWithoutVotesInput>;
};

export type UserUpdateOneWithoutAccessibilityInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAccessibilityInput>;
  create?: Maybe<UserUncheckedCreateWithoutAccessibilityInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutAccessibilityInput>;
  upsert?: Maybe<UserUpsertWithoutAccessibilityInput>;
};

export type UserUpdateOneWithoutAddressInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAddressInput>;
  create?: Maybe<UserUncheckedCreateWithoutAddressInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutAddressInput>;
  upsert?: Maybe<UserUpsertWithoutAddressInput>;
};

export type UserUpdateOneWithoutEmailsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailsInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailsInput>;
  upsert?: Maybe<UserUpsertWithoutEmailsInput>;
};

export type UserUpdateWithWhereUniqueWithoutFavoredInput = {
  data: UserUncheckedUpdateWithoutFavoredInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutFavoriteInput = {
  data: UserUncheckedUpdateWithoutFavoriteInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutAccessibilityInput = {
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutAddressInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutDistributionsInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutEmailsInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutFavoredInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutFavoriteInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutPurchasesInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutSalesInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutVotedInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  votes?: Maybe<VoteUpdateManyWithoutFromInput>;
};

export type UserUpdateWithoutVotesInput = {
  accessibility?: Maybe<AccessibilityUpdateOneWithoutUserInput>;
  accountName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutUserInput>;
  bsb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  distributions?: Maybe<DistributionUpdateManyWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emails?: Maybe<EmailUpdateManyWithoutUserInput>;
  favored?: Maybe<UserUpdateManyWithoutFavoriteInput>;
  favorite?: Maybe<UserUpdateManyWithoutFavoredInput>;
  hash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  image?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notification?: Maybe<BoolFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  purchases?: Maybe<TransactionUpdateManyWithoutBuyerInput>;
  representativesId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  representativesPosition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales?: Maybe<TransactionUpdateManyWithoutSellerInput>;
  status?: Maybe<StringFieldUpdateOperationsInput>;
  step2?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tax?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  totalBalance?: Maybe<FloatFieldUpdateOperationsInput>;
  totalSales?: Maybe<FloatFieldUpdateOperationsInput>;
  totalTaxes?: Maybe<FloatFieldUpdateOperationsInput>;
  totalVoted?: Maybe<IntFieldUpdateOperationsInput>;
  totalVotes?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  verificationCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  verified?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  voted?: Maybe<VoteUpdateManyWithoutToInput>;
};

export type UserUpsertWithWhereUniqueWithoutFavoredInput = {
  create: UserUncheckedCreateWithoutFavoredInput;
  update: UserUncheckedUpdateWithoutFavoredInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutFavoriteInput = {
  create: UserUncheckedCreateWithoutFavoriteInput;
  update: UserUncheckedUpdateWithoutFavoriteInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutAccessibilityInput = {
  create: UserUncheckedCreateWithoutAccessibilityInput;
  update: UserUncheckedUpdateWithoutAccessibilityInput;
};

export type UserUpsertWithoutAddressInput = {
  create: UserUncheckedCreateWithoutAddressInput;
  update: UserUncheckedUpdateWithoutAddressInput;
};

export type UserUpsertWithoutDistributionsInput = {
  create: UserUncheckedCreateWithoutDistributionsInput;
  update: UserUncheckedUpdateWithoutDistributionsInput;
};

export type UserUpsertWithoutEmailsInput = {
  create: UserUncheckedCreateWithoutEmailsInput;
  update: UserUncheckedUpdateWithoutEmailsInput;
};

export type UserUpsertWithoutPurchasesInput = {
  create: UserUncheckedCreateWithoutPurchasesInput;
  update: UserUncheckedUpdateWithoutPurchasesInput;
};

export type UserUpsertWithoutSalesInput = {
  create: UserUncheckedCreateWithoutSalesInput;
  update: UserUncheckedUpdateWithoutSalesInput;
};

export type UserUpsertWithoutVotedInput = {
  create: UserUncheckedCreateWithoutVotedInput;
  update: UserUncheckedUpdateWithoutVotedInput;
};

export type UserUpsertWithoutVotesInput = {
  create: UserUncheckedCreateWithoutVotesInput;
  update: UserUncheckedUpdateWithoutVotesInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<Maybe<UserWhereInput>>>;
  NOT?: Maybe<Array<Maybe<UserWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserWhereInput>>>;
  accessibility?: Maybe<AccessibilityWhereInput>;
  accountName?: Maybe<StringNullableFilter>;
  accountNumber?: Maybe<StringNullableFilter>;
  address?: Maybe<AddressWhereInput>;
  bsb?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dateOfBirth?: Maybe<DateTimeNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  distributions?: Maybe<DistributionListRelationFilter>;
  email?: Maybe<StringFilter>;
  emails?: Maybe<EmailListRelationFilter>;
  favored?: Maybe<UserListRelationFilter>;
  favorite?: Maybe<UserListRelationFilter>;
  hash?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  image?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  notification?: Maybe<BoolFilter>;
  phone?: Maybe<StringNullableFilter>;
  purchases?: Maybe<TransactionListRelationFilter>;
  representativesId?: Maybe<StringNullableFilter>;
  representativesName?: Maybe<StringNullableFilter>;
  representativesPosition?: Maybe<StringNullableFilter>;
  sales?: Maybe<TransactionListRelationFilter>;
  status?: Maybe<StringFilter>;
  step2?: Maybe<BoolNullableFilter>;
  tax?: Maybe<BoolNullableFilter>;
  totalBalance?: Maybe<FloatFilter>;
  totalSales?: Maybe<FloatFilter>;
  totalTaxes?: Maybe<FloatFilter>;
  totalVoted?: Maybe<IntFilter>;
  totalVotes?: Maybe<IntFilter>;
  type?: Maybe<StringFilter>;
  username?: Maybe<StringFilter>;
  verificationCode?: Maybe<StringNullableFilter>;
  verified?: Maybe<BoolNullableFilter>;
  voted?: Maybe<VoteListRelationFilter>;
  votes?: Maybe<VoteListRelationFilter>;
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  phone_verified?: Maybe<UserPhoneVerifiedCompoundUniqueInput>;
  username?: Maybe<Scalars['String']>;
};

export type UsernameInput = {
  username: Scalars['String'];
};

export type VerifyCodeInput = {
  verificationCode?: Maybe<Scalars['String']>;
};

export type Vote = {
  __typename?: 'Vote';
  from: User;
  fromId: Scalars['Int'];
  to: User;
  toId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type VoteAvgAggregateOutputType = {
  __typename?: 'VoteAvgAggregateOutputType';
  fromId?: Maybe<Scalars['Float']>;
  toId?: Maybe<Scalars['Float']>;
  vote?: Maybe<Scalars['Float']>;
};

export type VoteAvgOrderByAggregateInput = {
  fromId?: Maybe<SortOrder>;
  toId?: Maybe<SortOrder>;
  vote?: Maybe<SortOrder>;
};

export type VoteCountAggregateOutputType = {
  __typename?: 'VoteCountAggregateOutputType';
  _all: Scalars['Int'];
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type VoteCountOrderByAggregateInput = {
  fromId?: Maybe<SortOrder>;
  toId?: Maybe<SortOrder>;
  vote?: Maybe<SortOrder>;
};

export type VoteCreateInput = {
  from: UserCreateNestedOneWithoutVotesInput;
  to: UserCreateNestedOneWithoutVotedInput;
  vote: Scalars['Int'];
};

export type VoteCreateManyFromInput = {
  toId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type VoteCreateManyFromInputEnvelope = {
  data: VoteCreateManyFromInput;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VoteCreateManyInput = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type VoteCreateManyToInput = {
  fromId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type VoteCreateManyToInputEnvelope = {
  data: VoteCreateManyToInput;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VoteCreateNestedManyWithoutFromInput = {
  connect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<VoteCreateOrConnectWithoutFromInput>>>;
  create?: Maybe<Array<Maybe<VoteCreateWithoutFromInput>>>;
  createMany?: Maybe<VoteCreateManyFromInputEnvelope>;
};

export type VoteCreateNestedManyWithoutToInput = {
  connect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<VoteCreateOrConnectWithoutToInput>>>;
  create?: Maybe<Array<Maybe<VoteCreateWithoutToInput>>>;
  createMany?: Maybe<VoteCreateManyToInputEnvelope>;
};

export type VoteCreateOrConnectWithoutFromInput = {
  create: VoteUncheckedCreateWithoutFromInput;
  where: VoteWhereUniqueInput;
};

export type VoteCreateOrConnectWithoutToInput = {
  create: VoteUncheckedCreateWithoutToInput;
  where: VoteWhereUniqueInput;
};

export type VoteCreateWithoutFromInput = {
  to: UserCreateNestedOneWithoutVotedInput;
  vote: Scalars['Int'];
};

export type VoteCreateWithoutToInput = {
  from: UserCreateNestedOneWithoutVotesInput;
  vote: Scalars['Int'];
};

export type VoteFromIdToIdCompoundUniqueInput = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
};

export type VoteListRelationFilter = {
  every?: Maybe<VoteWhereInput>;
  none?: Maybe<VoteWhereInput>;
  some?: Maybe<VoteWhereInput>;
};

export type VoteMaxAggregateOutputType = {
  __typename?: 'VoteMaxAggregateOutputType';
  fromId?: Maybe<Scalars['Int']>;
  toId?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['Int']>;
};

export type VoteMaxOrderByAggregateInput = {
  fromId?: Maybe<SortOrder>;
  toId?: Maybe<SortOrder>;
  vote?: Maybe<SortOrder>;
};

export type VoteMinAggregateOutputType = {
  __typename?: 'VoteMinAggregateOutputType';
  fromId?: Maybe<Scalars['Int']>;
  toId?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['Int']>;
};

export type VoteMinOrderByAggregateInput = {
  fromId?: Maybe<SortOrder>;
  toId?: Maybe<SortOrder>;
  vote?: Maybe<SortOrder>;
};

export type VoteOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VoteOrderByWithAggregationInput = {
  _avg?: Maybe<VoteAvgOrderByAggregateInput>;
  _count?: Maybe<VoteCountOrderByAggregateInput>;
  _max?: Maybe<VoteMaxOrderByAggregateInput>;
  _min?: Maybe<VoteMinOrderByAggregateInput>;
  _sum?: Maybe<VoteSumOrderByAggregateInput>;
  fromId?: Maybe<SortOrder>;
  toId?: Maybe<SortOrder>;
  vote?: Maybe<SortOrder>;
};

export type VoteOrderByWithRelationInput = {
  from?: Maybe<UserOrderByWithRelationInput>;
  fromId?: Maybe<SortOrder>;
  to?: Maybe<UserOrderByWithRelationInput>;
  toId?: Maybe<SortOrder>;
  vote?: Maybe<SortOrder>;
};

export enum VoteScalarFieldEnum {
  FromId = 'fromId',
  ToId = 'toId',
  Vote = 'vote'
}

export type VoteScalarWhereInput = {
  AND?: Maybe<Array<Maybe<VoteScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<VoteScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<VoteScalarWhereInput>>>;
  fromId?: Maybe<IntFilter>;
  toId?: Maybe<IntFilter>;
  vote?: Maybe<IntFilter>;
};

export type VoteScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<VoteScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<VoteScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<VoteScalarWhereWithAggregatesInput>>>;
  fromId?: Maybe<IntWithAggregatesFilter>;
  toId?: Maybe<IntWithAggregatesFilter>;
  vote?: Maybe<IntWithAggregatesFilter>;
};

export type VoteSumAggregateOutputType = {
  __typename?: 'VoteSumAggregateOutputType';
  fromId?: Maybe<Scalars['Int']>;
  toId?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['Int']>;
};

export type VoteSumOrderByAggregateInput = {
  fromId?: Maybe<SortOrder>;
  toId?: Maybe<SortOrder>;
  vote?: Maybe<SortOrder>;
};

export type VoteUncheckedCreateInput = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type VoteUncheckedCreateNestedManyWithoutFromInput = {
  connect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<VoteCreateOrConnectWithoutFromInput>>>;
  create?: Maybe<Array<Maybe<VoteCreateWithoutFromInput>>>;
  createMany?: Maybe<VoteCreateManyFromInputEnvelope>;
};

export type VoteUncheckedCreateNestedManyWithoutToInput = {
  connect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<VoteCreateOrConnectWithoutToInput>>>;
  create?: Maybe<Array<Maybe<VoteCreateWithoutToInput>>>;
  createMany?: Maybe<VoteCreateManyToInputEnvelope>;
};

export type VoteUncheckedCreateWithoutFromInput = {
  toId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type VoteUncheckedCreateWithoutToInput = {
  fromId: Scalars['Int'];
  vote: Scalars['Int'];
};

export type VoteUncheckedUpdateInput = {
  fromId?: Maybe<IntFieldUpdateOperationsInput>;
  toId?: Maybe<IntFieldUpdateOperationsInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUncheckedUpdateManyInput = {
  fromId?: Maybe<IntFieldUpdateOperationsInput>;
  toId?: Maybe<IntFieldUpdateOperationsInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUncheckedUpdateManyWithoutFromInput = {
  connect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<VoteCreateOrConnectWithoutFromInput>>>;
  create?: Maybe<Array<Maybe<VoteCreateWithoutFromInput>>>;
  createMany?: Maybe<VoteCreateManyFromInputEnvelope>;
  delete?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<VoteScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<VoteUpdateWithWhereUniqueWithoutFromInput>>>;
  updateMany?: Maybe<Array<Maybe<VoteUpdateManyWithWhereWithoutFromInput>>>;
  upsert?: Maybe<Array<Maybe<VoteUpsertWithWhereUniqueWithoutFromInput>>>;
};

export type VoteUncheckedUpdateManyWithoutToInput = {
  connect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<VoteCreateOrConnectWithoutToInput>>>;
  create?: Maybe<Array<Maybe<VoteCreateWithoutToInput>>>;
  createMany?: Maybe<VoteCreateManyToInputEnvelope>;
  delete?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<VoteScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<VoteUpdateWithWhereUniqueWithoutToInput>>>;
  updateMany?: Maybe<Array<Maybe<VoteUpdateManyWithWhereWithoutToInput>>>;
  upsert?: Maybe<Array<Maybe<VoteUpsertWithWhereUniqueWithoutToInput>>>;
};

export type VoteUncheckedUpdateManyWithoutVotedInput = {
  fromId?: Maybe<IntFieldUpdateOperationsInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUncheckedUpdateManyWithoutVotesInput = {
  toId?: Maybe<IntFieldUpdateOperationsInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUncheckedUpdateWithoutFromInput = {
  toId?: Maybe<IntFieldUpdateOperationsInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUncheckedUpdateWithoutToInput = {
  fromId?: Maybe<IntFieldUpdateOperationsInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUpdateInput = {
  from?: Maybe<UserUpdateOneRequiredWithoutVotesInput>;
  to?: Maybe<UserUpdateOneRequiredWithoutVotedInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUpdateManyMutationInput = {
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUpdateManyWithWhereWithoutFromInput = {
  data: VoteUncheckedUpdateManyWithoutVotesInput;
  where: VoteScalarWhereInput;
};

export type VoteUpdateManyWithWhereWithoutToInput = {
  data: VoteUncheckedUpdateManyWithoutVotedInput;
  where: VoteScalarWhereInput;
};

export type VoteUpdateManyWithoutFromInput = {
  connect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<VoteCreateOrConnectWithoutFromInput>>>;
  create?: Maybe<Array<Maybe<VoteCreateWithoutFromInput>>>;
  createMany?: Maybe<VoteCreateManyFromInputEnvelope>;
  delete?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<VoteScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<VoteUpdateWithWhereUniqueWithoutFromInput>>>;
  updateMany?: Maybe<Array<Maybe<VoteUpdateManyWithWhereWithoutFromInput>>>;
  upsert?: Maybe<Array<Maybe<VoteUpsertWithWhereUniqueWithoutFromInput>>>;
};

export type VoteUpdateManyWithoutToInput = {
  connect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<VoteCreateOrConnectWithoutToInput>>>;
  create?: Maybe<Array<Maybe<VoteCreateWithoutToInput>>>;
  createMany?: Maybe<VoteCreateManyToInputEnvelope>;
  delete?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  deleteMany?: Maybe<Array<Maybe<VoteScalarWhereInput>>>;
  disconnect?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<VoteWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<VoteUpdateWithWhereUniqueWithoutToInput>>>;
  updateMany?: Maybe<Array<Maybe<VoteUpdateManyWithWhereWithoutToInput>>>;
  upsert?: Maybe<Array<Maybe<VoteUpsertWithWhereUniqueWithoutToInput>>>;
};

export type VoteUpdateWithWhereUniqueWithoutFromInput = {
  data: VoteUncheckedUpdateWithoutFromInput;
  where: VoteWhereUniqueInput;
};

export type VoteUpdateWithWhereUniqueWithoutToInput = {
  data: VoteUncheckedUpdateWithoutToInput;
  where: VoteWhereUniqueInput;
};

export type VoteUpdateWithoutFromInput = {
  to?: Maybe<UserUpdateOneRequiredWithoutVotedInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUpdateWithoutToInput = {
  from?: Maybe<UserUpdateOneRequiredWithoutVotesInput>;
  vote?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VoteUpsertWithWhereUniqueWithoutFromInput = {
  create: VoteUncheckedCreateWithoutFromInput;
  update: VoteUncheckedUpdateWithoutFromInput;
  where: VoteWhereUniqueInput;
};

export type VoteUpsertWithWhereUniqueWithoutToInput = {
  create: VoteUncheckedCreateWithoutToInput;
  update: VoteUncheckedUpdateWithoutToInput;
  where: VoteWhereUniqueInput;
};

export type VoteWhereInput = {
  AND?: Maybe<Array<Maybe<VoteWhereInput>>>;
  NOT?: Maybe<Array<Maybe<VoteWhereInput>>>;
  OR?: Maybe<Array<Maybe<VoteWhereInput>>>;
  from?: Maybe<UserWhereInput>;
  fromId?: Maybe<IntFilter>;
  to?: Maybe<UserWhereInput>;
  toId?: Maybe<IntFilter>;
  vote?: Maybe<IntFilter>;
};

export type VoteWhereUniqueInput = {
  fromId_toId?: Maybe<VoteFromIdToIdCompoundUniqueInput>;
};

export type AddressFragmentFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'placeId' | 'address' | 'unit' | 'number' | 'street' | 'suburb' | 'state' | 'zip' | 'country' | 'city' | 'lat' | 'lng'>
);

export type AccessibilityFragmentFragment = (
  { __typename?: 'Accessibility' }
  & Pick<Accessibility, 'id' | 'phone' | 'website' | 'email'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragmentFragment
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragmentFragment
  )> }
);

export type FullUserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'phone' | 'step2' | 'image' | 'username' | 'email' | 'verified' | 'type' | 'dateOfBirth' | 'representativesName' | 'representativesPosition' | 'tax' | 'description' | 'totalVotes' | 'totalVoted' | 'notification' | 'accountNumber' | 'bsb' | 'accountName' | 'status'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragmentFragment
  )>, accessibility?: Maybe<(
    { __typename?: 'Accessibility' }
    & AccessibilityFragmentFragment
  )> }
);

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type SignUp1MutationVariables = Exact<{
  data: SignUp1Input;
}>;


export type SignUp1Mutation = (
  { __typename?: 'Mutation' }
  & { signUp1: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type SignUp2MutationVariables = Exact<{
  data: SignUp2Input;
}>;


export type SignUp2Mutation = (
  { __typename?: 'Mutation' }
  & { signUp2: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type SignUp2DataQueryVariables = Exact<{ [key: string]: never; }>;


export type SignUp2DataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragmentFragment
  )> }
);

export type VerifyCodeMutationVariables = Exact<{
  data: VerifyCodeInput;
}>;


export type VerifyCodeMutation = (
  { __typename?: 'Mutation' }
  & { verifyCode: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type ResendCodeMutationVariables = Exact<{
  data: ResendCodeInput;
}>;


export type ResendCodeMutation = (
  { __typename?: 'Mutation' }
  & { resendCode: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type PasswordResetMutationVariables = Exact<{
  data: PasswordResetInput;
}>;


export type PasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { passwordReset: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type PasswordForgotMutationVariables = Exact<{
  data: PasswordForgotInput;
}>;


export type PasswordForgotMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'passwordForgot'>
);

export type PasswordUpdateMutationVariables = Exact<{
  data: PasswordUpdateInput;
}>;


export type PasswordUpdateMutation = (
  { __typename?: 'Mutation' }
  & { passwordUpdate: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type VerifyTokenMutationVariables = Exact<{
  tokenCode: Scalars['String'];
}>;


export type VerifyTokenMutation = (
  { __typename?: 'Mutation' }
  & { verifyToken: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type EmailsQueryVariables = Exact<{
  where?: Maybe<EmailWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmailOrderByWithRelationInput>> | Maybe<EmailOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type EmailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'emailsCount'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'subject' | 'createdAt'>
  )> }
);

export type EmailQueryVariables = Exact<{
  emailId: Scalars['Int'];
}>;


export type EmailQuery = (
  { __typename?: 'Query' }
  & { email: (
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'body'>
  ) }
);

export type EmailNewMutationVariables = Exact<{
  data: EmailNewInput;
}>;


export type EmailNewMutation = (
  { __typename?: 'Mutation' }
  & { emailNewMutation: (
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'subject' | 'body'>
  ) }
);

export type ResetFundMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetFundMutation = (
  { __typename?: 'Mutation' }
  & { resetFund: (
    { __typename?: 'Fund' }
    & Pick<Fund, 'available' | 'distributedAt' | 'totalVotes' | 'updatedAt'>
  ) }
);

export type UpdateFundMutationVariables = Exact<{
  data: UpdateFundInput;
}>;


export type UpdateFundMutation = (
  { __typename?: 'Mutation' }
  & { updateFund: (
    { __typename?: 'Fund' }
    & Pick<Fund, 'available' | 'totalVotes' | 'updatedAt'>
  ) }
);

export type FindFundQueryVariables = Exact<{ [key: string]: never; }>;


export type FindFundQuery = (
  { __typename?: 'Query' }
  & { findFund?: Maybe<(
    { __typename?: 'Fund' }
    & Pick<Fund, 'available' | 'totalVotes' | 'updatedAt'>
  )> }
);

export type PurchaseFragmanetFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'date' | 'description' | 'price' | 'tax' | 'balance'>
  & { seller: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'username'>
  ) }
);

export type PurchasesQueryVariables = Exact<{
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationInput>> | Maybe<TransactionOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type PurchasesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'purchasesCount'>
  & { purchases: Array<(
    { __typename?: 'Transaction' }
    & PurchaseFragmanetFragment
  )> }
);

export type SalesFragmanetFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'date' | 'description' | 'price' | 'tax' | 'balance'>
  & { buyer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'username'>
  ) }
);

export type SalesQueryVariables = Exact<{
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationInput>> | Maybe<TransactionOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type SalesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'salesCount'>
  & { sales: Array<(
    { __typename?: 'Transaction' }
    & SalesFragmanetFragment
  )> }
);

export type SellNewMutationVariables = Exact<{
  data: SellNewInput;
}>;


export type SellNewMutation = (
  { __typename?: 'Mutation' }
  & { sellNew: (
    { __typename?: 'Transaction' }
    & SalesFragmanetFragment
  ) }
);

export type TaxNewMutationVariables = Exact<{
  data: TaxNewInput;
}>;


export type TaxNewMutation = (
  { __typename?: 'Mutation' }
  & { taxNew: (
    { __typename?: 'Transaction' }
    & SalesFragmanetFragment
  ) }
);

export type UsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UsernameQuery = (
  { __typename?: 'Query' }
  & { username?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'username'>
    & { accessibility?: Maybe<(
      { __typename?: 'Accessibility' }
      & Pick<Accessibility, 'id' | 'phone'>
    )> }
  )> }
);

export type UsersFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'createdAt' | 'username' | 'status' | 'phone' | 'type' | 'name' | 'description' | 'totalSales' | 'totalTaxes' | 'totalBalance' | 'totalVoted' | 'totalVotes'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragmentFragment
  )>, accessibility?: Maybe<(
    { __typename?: 'Accessibility' }
    & AccessibilityFragmentFragment
  )> }
);

export type UsersQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>> | Maybe<UserOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'usersCount'>
  & { users: Array<(
    { __typename?: 'User' }
    & UsersFragment
  )> }
);

export type UsersFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'name' | 'phone' | 'image' | 'description' | 'accountNumber' | 'bsb' | 'accountName' | 'status'>
  & { accessibility?: Maybe<(
    { __typename?: 'Accessibility' }
    & AccessibilityFragmentFragment
  )> }
);

export type SellersFragmentFragment = (
  { __typename?: 'User' }
  & UsersFragmentFragment
);

export type NotForProfitFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'totalVoted' | 'totalVotes'>
  & { voted?: Maybe<Array<(
    { __typename?: 'Vote' }
    & Pick<Vote, 'fromId' | 'toId' | 'vote'>
  )>> }
  & UsersFragmentFragment
);

export type NotForProfitQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>> | Maybe<UserOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  distance?: Maybe<DistanceInput>;
}>;


export type NotForProfitQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'notForProfitCount'>
  & { notForProfit: Array<(
    { __typename?: 'User' }
    & NotForProfitFragmentFragment
  )> }
);

export type SellersQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>> | Maybe<UserOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  distance?: Maybe<DistanceInput>;
}>;


export type SellersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sellersCount'>
  & { sellers: Array<(
    { __typename?: 'User' }
    & { favored: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
    & SellersFragmentFragment
  )> }
);

export type FavoritesQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>> | Maybe<UserOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  distance?: Maybe<DistanceInput>;
}>;


export type FavoritesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'favoritesCount'>
  & { favorites: Array<(
    { __typename?: 'User' }
    & SellersFragmentFragment
  )> }
);

export type FavoriteAddMutationVariables = Exact<{
  sellerId: Scalars['Int'];
}>;


export type FavoriteAddMutation = (
  { __typename?: 'Mutation' }
  & { favoriteAdd: (
    { __typename?: 'User' }
    & SellersFragmentFragment
  ) }
);

export type FavoriteRemoveMutationVariables = Exact<{
  sellerId: Scalars['Int'];
}>;


export type FavoriteRemoveMutation = (
  { __typename?: 'Mutation' }
  & { favoriteRemove: (
    { __typename?: 'User' }
    & SellersFragmentFragment
  ) }
);

export type UserUpdateMutationVariables = Exact<{
  userId: Scalars['Int'];
  data: SignUp2Input;
}>;


export type UserUpdateMutation = (
  { __typename?: 'Mutation' }
  & { userUpdate: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type UserNewMutationVariables = Exact<{
  data: UserNewInput;
}>;


export type UserNewMutation = (
  { __typename?: 'Mutation' }
  & { userNew: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type UsernameUpdateMutationVariables = Exact<{
  userId: Scalars['Int'];
  data: UsernameInput;
}>;


export type UsernameUpdateMutation = (
  { __typename?: 'Mutation' }
  & { usernameUpdate: (
    { __typename?: 'User' }
    & FullUserFragmentFragment
  ) }
);

export type UserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragmentFragment
  )> }
);

export type TotalVotesQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalVotesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalVotes'>
);

export type VotesQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationInput>> | Maybe<UserOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  distance?: Maybe<DistanceInput>;
}>;


export type VotesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'votesCount'>
  & { votes: Array<(
    { __typename?: 'User' }
    & NotForProfitFragmentFragment
  )> }
);

export type VoteAddMutationVariables = Exact<{
  sellerId: Scalars['Int'];
  vote: Scalars['Int'];
}>;


export type VoteAddMutation = (
  { __typename?: 'Mutation' }
  & { voteAdd: (
    { __typename?: 'Vote' }
    & Pick<Vote, 'fromId' | 'toId' | 'vote'>
  ) }
);

export const AddressFragmentFragmentDoc = gql`
    fragment AddressFragment on Address {
  id
  placeId
  address
  unit
  number
  street
  suburb
  state
  zip
  country
  city
  lat
  lng
}
    `;
export const AccessibilityFragmentFragmentDoc = gql`
    fragment AccessibilityFragment on Accessibility {
  id
  address {
    ...AddressFragment
  }
  phone
  website
  email
}
    ${AddressFragmentFragmentDoc}`;
export const FullUserFragmentFragmentDoc = gql`
    fragment FullUserFragment on User {
  id
  name
  phone
  step2
  image
  username
  email
  verified
  type
  dateOfBirth
  address {
    ...AddressFragment
  }
  representativesName
  representativesPosition
  tax
  description
  accessibility {
    ...AccessibilityFragment
  }
  totalVotes
  totalVoted
  notification
  accountNumber
  bsb
  accountName
  status
}
    ${AddressFragmentFragmentDoc}
${AccessibilityFragmentFragmentDoc}`;
export const PurchaseFragmanetFragmentDoc = gql`
    fragment PurchaseFragmanet on Transaction {
  id
  date
  seller {
    id
    name
    username
  }
  description
  price
  tax
  balance
}
    `;
export const SalesFragmanetFragmentDoc = gql`
    fragment SalesFragmanet on Transaction {
  id
  date
  buyer {
    id
    name
    username
  }
  description
  price
  tax
  balance
}
    `;
export const UsersFragmentDoc = gql`
    fragment Users on User {
  id
  createdAt
  username
  status
  phone
  type
  name
  description
  totalSales
  totalTaxes
  totalBalance
  address {
    ...AddressFragment
  }
  accessibility {
    ...AccessibilityFragment
  }
  totalVoted
  totalVotes
}
    ${AddressFragmentFragmentDoc}
${AccessibilityFragmentFragmentDoc}`;
export const UsersFragmentFragmentDoc = gql`
    fragment UsersFragment on User {
  id
  username
  name
  phone
  image
  description
  accessibility {
    ...AccessibilityFragment
  }
  accountNumber
  bsb
  accountName
  status
}
    ${AccessibilityFragmentFragmentDoc}`;
export const SellersFragmentFragmentDoc = gql`
    fragment SellersFragment on User {
  ...UsersFragment
}
    ${UsersFragmentFragmentDoc}`;
export const NotForProfitFragmentFragmentDoc = gql`
    fragment NotForProfitFragment on User {
  ...UsersFragment
  totalVoted
  totalVotes
  voted {
    fromId
    toId
    vote
  }
}
    ${UsersFragmentFragmentDoc}`;
export const MeDocument = gql`
    query me {
  me {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
    mutation login($data: LoginInput!) {
  login(data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignUp1Document = gql`
    mutation signUp1($data: SignUp1Input!) {
  signUp1(data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useSignUp1Mutation__
 *
 * To run a mutation, you first call `useSignUp1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUp1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUp1Mutation, { data, loading, error }] = useSignUp1Mutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUp1Mutation(baseOptions?: Apollo.MutationHookOptions<SignUp1Mutation, SignUp1MutationVariables>) {
        return Apollo.useMutation<SignUp1Mutation, SignUp1MutationVariables>(SignUp1Document, baseOptions);
      }
export type SignUp1MutationHookResult = ReturnType<typeof useSignUp1Mutation>;
export type SignUp1MutationResult = Apollo.MutationResult<SignUp1Mutation>;
export type SignUp1MutationOptions = Apollo.BaseMutationOptions<SignUp1Mutation, SignUp1MutationVariables>;
export const SignUp2Document = gql`
    mutation signUp2($data: SignUp2Input!) {
  signUp2(data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useSignUp2Mutation__
 *
 * To run a mutation, you first call `useSignUp2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUp2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUp2Mutation, { data, loading, error }] = useSignUp2Mutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUp2Mutation(baseOptions?: Apollo.MutationHookOptions<SignUp2Mutation, SignUp2MutationVariables>) {
        return Apollo.useMutation<SignUp2Mutation, SignUp2MutationVariables>(SignUp2Document, baseOptions);
      }
export type SignUp2MutationHookResult = ReturnType<typeof useSignUp2Mutation>;
export type SignUp2MutationResult = Apollo.MutationResult<SignUp2Mutation>;
export type SignUp2MutationOptions = Apollo.BaseMutationOptions<SignUp2Mutation, SignUp2MutationVariables>;
export const SignUp2DataDocument = gql`
    query signUp2Data {
  me {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useSignUp2DataQuery__
 *
 * To run a query within a React component, call `useSignUp2DataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignUp2DataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignUp2DataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignUp2DataQuery(baseOptions?: Apollo.QueryHookOptions<SignUp2DataQuery, SignUp2DataQueryVariables>) {
        return Apollo.useQuery<SignUp2DataQuery, SignUp2DataQueryVariables>(SignUp2DataDocument, baseOptions);
      }
export function useSignUp2DataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignUp2DataQuery, SignUp2DataQueryVariables>) {
          return Apollo.useLazyQuery<SignUp2DataQuery, SignUp2DataQueryVariables>(SignUp2DataDocument, baseOptions);
        }
export type SignUp2DataQueryHookResult = ReturnType<typeof useSignUp2DataQuery>;
export type SignUp2DataLazyQueryHookResult = ReturnType<typeof useSignUp2DataLazyQuery>;
export type SignUp2DataQueryResult = Apollo.QueryResult<SignUp2DataQuery, SignUp2DataQueryVariables>;
export const VerifyCodeDocument = gql`
    mutation verifyCode($data: VerifyCodeInput!) {
  verifyCode(data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useVerifyCodeMutation__
 *
 * To run a mutation, you first call `useVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCodeMutation, { data, loading, error }] = useVerifyCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyCodeMutation(baseOptions?: Apollo.MutationHookOptions<VerifyCodeMutation, VerifyCodeMutationVariables>) {
        return Apollo.useMutation<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument, baseOptions);
      }
export type VerifyCodeMutationHookResult = ReturnType<typeof useVerifyCodeMutation>;
export type VerifyCodeMutationResult = Apollo.MutationResult<VerifyCodeMutation>;
export type VerifyCodeMutationOptions = Apollo.BaseMutationOptions<VerifyCodeMutation, VerifyCodeMutationVariables>;
export const ResendCodeDocument = gql`
    mutation resendCode($data: ResendCodeInput!) {
  resendCode(data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useResendCodeMutation__
 *
 * To run a mutation, you first call `useResendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCodeMutation, { data, loading, error }] = useResendCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResendCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendCodeMutation, ResendCodeMutationVariables>) {
        return Apollo.useMutation<ResendCodeMutation, ResendCodeMutationVariables>(ResendCodeDocument, baseOptions);
      }
export type ResendCodeMutationHookResult = ReturnType<typeof useResendCodeMutation>;
export type ResendCodeMutationResult = Apollo.MutationResult<ResendCodeMutation>;
export type ResendCodeMutationOptions = Apollo.BaseMutationOptions<ResendCodeMutation, ResendCodeMutationVariables>;
export const PasswordResetDocument = gql`
    mutation passwordReset($data: PasswordResetInput!) {
  passwordReset(data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, baseOptions);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const PasswordForgotDocument = gql`
    mutation passwordForgot($data: PasswordForgotInput!) {
  passwordForgot(data: $data)
}
    `;

/**
 * __usePasswordForgotMutation__
 *
 * To run a mutation, you first call `usePasswordForgotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordForgotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordForgotMutation, { data, loading, error }] = usePasswordForgotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePasswordForgotMutation(baseOptions?: Apollo.MutationHookOptions<PasswordForgotMutation, PasswordForgotMutationVariables>) {
        return Apollo.useMutation<PasswordForgotMutation, PasswordForgotMutationVariables>(PasswordForgotDocument, baseOptions);
      }
export type PasswordForgotMutationHookResult = ReturnType<typeof usePasswordForgotMutation>;
export type PasswordForgotMutationResult = Apollo.MutationResult<PasswordForgotMutation>;
export type PasswordForgotMutationOptions = Apollo.BaseMutationOptions<PasswordForgotMutation, PasswordForgotMutationVariables>;
export const PasswordUpdateDocument = gql`
    mutation passwordUpdate($data: PasswordUpdateInput!) {
  passwordUpdate(data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __usePasswordUpdateMutation__
 *
 * To run a mutation, you first call `usePasswordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordUpdateMutation, { data, loading, error }] = usePasswordUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePasswordUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PasswordUpdateMutation, PasswordUpdateMutationVariables>) {
        return Apollo.useMutation<PasswordUpdateMutation, PasswordUpdateMutationVariables>(PasswordUpdateDocument, baseOptions);
      }
export type PasswordUpdateMutationHookResult = ReturnType<typeof usePasswordUpdateMutation>;
export type PasswordUpdateMutationResult = Apollo.MutationResult<PasswordUpdateMutation>;
export type PasswordUpdateMutationOptions = Apollo.BaseMutationOptions<PasswordUpdateMutation, PasswordUpdateMutationVariables>;
export const VerifyTokenDocument = gql`
    mutation verifyToken($tokenCode: String!) {
  verifyToken(tokenCode: $tokenCode) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      tokenCode: // value for 'tokenCode'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, baseOptions);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const EmailsDocument = gql`
    query emails($where: EmailWhereInput, $orderBy: [EmailOrderByWithRelationInput], $skip: Int, $take: Int) {
  emails(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    id
    subject
    createdAt
  }
  emailsCount(where: $where)
}
    `;

/**
 * __useEmailsQuery__
 *
 * To run a query within a React component, call `useEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useEmailsQuery(baseOptions?: Apollo.QueryHookOptions<EmailsQuery, EmailsQueryVariables>) {
        return Apollo.useQuery<EmailsQuery, EmailsQueryVariables>(EmailsDocument, baseOptions);
      }
export function useEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailsQuery, EmailsQueryVariables>) {
          return Apollo.useLazyQuery<EmailsQuery, EmailsQueryVariables>(EmailsDocument, baseOptions);
        }
export type EmailsQueryHookResult = ReturnType<typeof useEmailsQuery>;
export type EmailsLazyQueryHookResult = ReturnType<typeof useEmailsLazyQuery>;
export type EmailsQueryResult = Apollo.QueryResult<EmailsQuery, EmailsQueryVariables>;
export const EmailDocument = gql`
    query email($emailId: Int!) {
  email(emailId: $emailId) {
    id
    body
  }
}
    `;

/**
 * __useEmailQuery__
 *
 * To run a query within a React component, call `useEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailQuery({
 *   variables: {
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useEmailQuery(baseOptions: Apollo.QueryHookOptions<EmailQuery, EmailQueryVariables>) {
        return Apollo.useQuery<EmailQuery, EmailQueryVariables>(EmailDocument, baseOptions);
      }
export function useEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailQuery, EmailQueryVariables>) {
          return Apollo.useLazyQuery<EmailQuery, EmailQueryVariables>(EmailDocument, baseOptions);
        }
export type EmailQueryHookResult = ReturnType<typeof useEmailQuery>;
export type EmailLazyQueryHookResult = ReturnType<typeof useEmailLazyQuery>;
export type EmailQueryResult = Apollo.QueryResult<EmailQuery, EmailQueryVariables>;
export const EmailNewDocument = gql`
    mutation emailNew($data: EmailNewInput!) {
  emailNewMutation(data: $data) {
    id
    subject
    body
  }
}
    `;

/**
 * __useEmailNewMutation__
 *
 * To run a mutation, you first call `useEmailNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailNewMutation, { data, loading, error }] = useEmailNewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEmailNewMutation(baseOptions?: Apollo.MutationHookOptions<EmailNewMutation, EmailNewMutationVariables>) {
        return Apollo.useMutation<EmailNewMutation, EmailNewMutationVariables>(EmailNewDocument, baseOptions);
      }
export type EmailNewMutationHookResult = ReturnType<typeof useEmailNewMutation>;
export type EmailNewMutationResult = Apollo.MutationResult<EmailNewMutation>;
export type EmailNewMutationOptions = Apollo.BaseMutationOptions<EmailNewMutation, EmailNewMutationVariables>;
export const ResetFundDocument = gql`
    mutation resetFund {
  resetFund {
    available
    distributedAt
    totalVotes
    updatedAt
  }
}
    `;

/**
 * __useResetFundMutation__
 *
 * To run a mutation, you first call `useResetFundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetFundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetFundMutation, { data, loading, error }] = useResetFundMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetFundMutation(baseOptions?: Apollo.MutationHookOptions<ResetFundMutation, ResetFundMutationVariables>) {
        return Apollo.useMutation<ResetFundMutation, ResetFundMutationVariables>(ResetFundDocument, baseOptions);
      }
export type ResetFundMutationHookResult = ReturnType<typeof useResetFundMutation>;
export type ResetFundMutationResult = Apollo.MutationResult<ResetFundMutation>;
export type ResetFundMutationOptions = Apollo.BaseMutationOptions<ResetFundMutation, ResetFundMutationVariables>;
export const UpdateFundDocument = gql`
    mutation updateFund($data: UpdateFundInput!) {
  updateFund(data: $data) {
    available
    totalVotes
    updatedAt
  }
}
    `;

/**
 * __useUpdateFundMutation__
 *
 * To run a mutation, you first call `useUpdateFundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFundMutation, { data, loading, error }] = useUpdateFundMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFundMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFundMutation, UpdateFundMutationVariables>) {
        return Apollo.useMutation<UpdateFundMutation, UpdateFundMutationVariables>(UpdateFundDocument, baseOptions);
      }
export type UpdateFundMutationHookResult = ReturnType<typeof useUpdateFundMutation>;
export type UpdateFundMutationResult = Apollo.MutationResult<UpdateFundMutation>;
export type UpdateFundMutationOptions = Apollo.BaseMutationOptions<UpdateFundMutation, UpdateFundMutationVariables>;
export const FindFundDocument = gql`
    query findFund {
  findFund {
    available
    totalVotes
    updatedAt
  }
}
    `;

/**
 * __useFindFundQuery__
 *
 * To run a query within a React component, call `useFindFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFundQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindFundQuery(baseOptions?: Apollo.QueryHookOptions<FindFundQuery, FindFundQueryVariables>) {
        return Apollo.useQuery<FindFundQuery, FindFundQueryVariables>(FindFundDocument, baseOptions);
      }
export function useFindFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFundQuery, FindFundQueryVariables>) {
          return Apollo.useLazyQuery<FindFundQuery, FindFundQueryVariables>(FindFundDocument, baseOptions);
        }
export type FindFundQueryHookResult = ReturnType<typeof useFindFundQuery>;
export type FindFundLazyQueryHookResult = ReturnType<typeof useFindFundLazyQuery>;
export type FindFundQueryResult = Apollo.QueryResult<FindFundQuery, FindFundQueryVariables>;
export const PurchasesDocument = gql`
    query purchases($where: TransactionWhereInput, $orderBy: [TransactionOrderByWithRelationInput], $skip: Int, $take: Int) {
  purchases(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    ...PurchaseFragmanet
  }
  purchasesCount(where: $where, orderBy: $orderBy)
}
    ${PurchaseFragmanetFragmentDoc}`;

/**
 * __usePurchasesQuery__
 *
 * To run a query within a React component, call `usePurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePurchasesQuery(baseOptions?: Apollo.QueryHookOptions<PurchasesQuery, PurchasesQueryVariables>) {
        return Apollo.useQuery<PurchasesQuery, PurchasesQueryVariables>(PurchasesDocument, baseOptions);
      }
export function usePurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchasesQuery, PurchasesQueryVariables>) {
          return Apollo.useLazyQuery<PurchasesQuery, PurchasesQueryVariables>(PurchasesDocument, baseOptions);
        }
export type PurchasesQueryHookResult = ReturnType<typeof usePurchasesQuery>;
export type PurchasesLazyQueryHookResult = ReturnType<typeof usePurchasesLazyQuery>;
export type PurchasesQueryResult = Apollo.QueryResult<PurchasesQuery, PurchasesQueryVariables>;
export const SalesDocument = gql`
    query sales($where: TransactionWhereInput, $orderBy: [TransactionOrderByWithRelationInput], $skip: Int, $take: Int) {
  sales(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    ...SalesFragmanet
  }
  salesCount(where: $where, orderBy: $orderBy)
}
    ${SalesFragmanetFragmentDoc}`;

/**
 * __useSalesQuery__
 *
 * To run a query within a React component, call `useSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSalesQuery(baseOptions?: Apollo.QueryHookOptions<SalesQuery, SalesQueryVariables>) {
        return Apollo.useQuery<SalesQuery, SalesQueryVariables>(SalesDocument, baseOptions);
      }
export function useSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesQuery, SalesQueryVariables>) {
          return Apollo.useLazyQuery<SalesQuery, SalesQueryVariables>(SalesDocument, baseOptions);
        }
export type SalesQueryHookResult = ReturnType<typeof useSalesQuery>;
export type SalesLazyQueryHookResult = ReturnType<typeof useSalesLazyQuery>;
export type SalesQueryResult = Apollo.QueryResult<SalesQuery, SalesQueryVariables>;
export const SellNewDocument = gql`
    mutation sellNew($data: SellNewInput!) {
  sellNew(data: $data) {
    ...SalesFragmanet
  }
}
    ${SalesFragmanetFragmentDoc}`;

/**
 * __useSellNewMutation__
 *
 * To run a mutation, you first call `useSellNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellNewMutation, { data, loading, error }] = useSellNewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSellNewMutation(baseOptions?: Apollo.MutationHookOptions<SellNewMutation, SellNewMutationVariables>) {
        return Apollo.useMutation<SellNewMutation, SellNewMutationVariables>(SellNewDocument, baseOptions);
      }
export type SellNewMutationHookResult = ReturnType<typeof useSellNewMutation>;
export type SellNewMutationResult = Apollo.MutationResult<SellNewMutation>;
export type SellNewMutationOptions = Apollo.BaseMutationOptions<SellNewMutation, SellNewMutationVariables>;
export const TaxNewDocument = gql`
    mutation taxNew($data: TaxNewInput!) {
  taxNew(data: $data) {
    ...SalesFragmanet
  }
}
    ${SalesFragmanetFragmentDoc}`;

/**
 * __useTaxNewMutation__
 *
 * To run a mutation, you first call `useTaxNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxNewMutation, { data, loading, error }] = useTaxNewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTaxNewMutation(baseOptions?: Apollo.MutationHookOptions<TaxNewMutation, TaxNewMutationVariables>) {
        return Apollo.useMutation<TaxNewMutation, TaxNewMutationVariables>(TaxNewDocument, baseOptions);
      }
export type TaxNewMutationHookResult = ReturnType<typeof useTaxNewMutation>;
export type TaxNewMutationResult = Apollo.MutationResult<TaxNewMutation>;
export type TaxNewMutationOptions = Apollo.BaseMutationOptions<TaxNewMutation, TaxNewMutationVariables>;
export const UsernameDocument = gql`
    query username($username: String!) {
  username(username: $username) {
    id
    name
    username
    accessibility {
      id
      phone
    }
  }
}
    `;

/**
 * __useUsernameQuery__
 *
 * To run a query within a React component, call `useUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameQuery(baseOptions: Apollo.QueryHookOptions<UsernameQuery, UsernameQueryVariables>) {
        return Apollo.useQuery<UsernameQuery, UsernameQueryVariables>(UsernameDocument, baseOptions);
      }
export function useUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameQuery, UsernameQueryVariables>) {
          return Apollo.useLazyQuery<UsernameQuery, UsernameQueryVariables>(UsernameDocument, baseOptions);
        }
export type UsernameQueryHookResult = ReturnType<typeof useUsernameQuery>;
export type UsernameLazyQueryHookResult = ReturnType<typeof useUsernameLazyQuery>;
export type UsernameQueryResult = Apollo.QueryResult<UsernameQuery, UsernameQueryVariables>;
export const UsersDocument = gql`
    query users($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput], $skip: Int, $take: Int) {
  users(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    ...Users
  }
  usersCount(where: $where)
}
    ${UsersFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const NotForProfitDocument = gql`
    query notForProfit($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput], $skip: Int, $take: Int, $distance: DistanceInput) {
  notForProfit(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
    distance: $distance
  ) {
    ...NotForProfitFragment
  }
  notForProfitCount(where: $where, distance: $distance)
}
    ${NotForProfitFragmentFragmentDoc}`;

/**
 * __useNotForProfitQuery__
 *
 * To run a query within a React component, call `useNotForProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotForProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotForProfitQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      distance: // value for 'distance'
 *   },
 * });
 */
export function useNotForProfitQuery(baseOptions?: Apollo.QueryHookOptions<NotForProfitQuery, NotForProfitQueryVariables>) {
        return Apollo.useQuery<NotForProfitQuery, NotForProfitQueryVariables>(NotForProfitDocument, baseOptions);
      }
export function useNotForProfitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotForProfitQuery, NotForProfitQueryVariables>) {
          return Apollo.useLazyQuery<NotForProfitQuery, NotForProfitQueryVariables>(NotForProfitDocument, baseOptions);
        }
export type NotForProfitQueryHookResult = ReturnType<typeof useNotForProfitQuery>;
export type NotForProfitLazyQueryHookResult = ReturnType<typeof useNotForProfitLazyQuery>;
export type NotForProfitQueryResult = Apollo.QueryResult<NotForProfitQuery, NotForProfitQueryVariables>;
export const SellersDocument = gql`
    query sellers($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput], $skip: Int, $take: Int, $distance: DistanceInput) {
  sellers(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
    distance: $distance
  ) {
    ...SellersFragment
    favored {
      id
    }
  }
  sellersCount(where: $where, distance: $distance)
}
    ${SellersFragmentFragmentDoc}`;

/**
 * __useSellersQuery__
 *
 * To run a query within a React component, call `useSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      distance: // value for 'distance'
 *   },
 * });
 */
export function useSellersQuery(baseOptions?: Apollo.QueryHookOptions<SellersQuery, SellersQueryVariables>) {
        return Apollo.useQuery<SellersQuery, SellersQueryVariables>(SellersDocument, baseOptions);
      }
export function useSellersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellersQuery, SellersQueryVariables>) {
          return Apollo.useLazyQuery<SellersQuery, SellersQueryVariables>(SellersDocument, baseOptions);
        }
export type SellersQueryHookResult = ReturnType<typeof useSellersQuery>;
export type SellersLazyQueryHookResult = ReturnType<typeof useSellersLazyQuery>;
export type SellersQueryResult = Apollo.QueryResult<SellersQuery, SellersQueryVariables>;
export const FavoritesDocument = gql`
    query favorites($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput], $skip: Int, $take: Int, $distance: DistanceInput) {
  favorites(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
    distance: $distance
  ) {
    ...SellersFragment
  }
  favoritesCount(where: $where, distance: $distance)
}
    ${SellersFragmentFragmentDoc}`;

/**
 * __useFavoritesQuery__
 *
 * To run a query within a React component, call `useFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoritesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      distance: // value for 'distance'
 *   },
 * });
 */
export function useFavoritesQuery(baseOptions?: Apollo.QueryHookOptions<FavoritesQuery, FavoritesQueryVariables>) {
        return Apollo.useQuery<FavoritesQuery, FavoritesQueryVariables>(FavoritesDocument, baseOptions);
      }
export function useFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FavoritesQuery, FavoritesQueryVariables>) {
          return Apollo.useLazyQuery<FavoritesQuery, FavoritesQueryVariables>(FavoritesDocument, baseOptions);
        }
export type FavoritesQueryHookResult = ReturnType<typeof useFavoritesQuery>;
export type FavoritesLazyQueryHookResult = ReturnType<typeof useFavoritesLazyQuery>;
export type FavoritesQueryResult = Apollo.QueryResult<FavoritesQuery, FavoritesQueryVariables>;
export const FavoriteAddDocument = gql`
    mutation favoriteAdd($sellerId: Int!) {
  favoriteAdd(sellerId: $sellerId) {
    ...SellersFragment
  }
}
    ${SellersFragmentFragmentDoc}`;

/**
 * __useFavoriteAddMutation__
 *
 * To run a mutation, you first call `useFavoriteAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteAddMutation, { data, loading, error }] = useFavoriteAddMutation({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useFavoriteAddMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteAddMutation, FavoriteAddMutationVariables>) {
        return Apollo.useMutation<FavoriteAddMutation, FavoriteAddMutationVariables>(FavoriteAddDocument, baseOptions);
      }
export type FavoriteAddMutationHookResult = ReturnType<typeof useFavoriteAddMutation>;
export type FavoriteAddMutationResult = Apollo.MutationResult<FavoriteAddMutation>;
export type FavoriteAddMutationOptions = Apollo.BaseMutationOptions<FavoriteAddMutation, FavoriteAddMutationVariables>;
export const FavoriteRemoveDocument = gql`
    mutation favoriteRemove($sellerId: Int!) {
  favoriteRemove(sellerId: $sellerId) {
    ...SellersFragment
  }
}
    ${SellersFragmentFragmentDoc}`;

/**
 * __useFavoriteRemoveMutation__
 *
 * To run a mutation, you first call `useFavoriteRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteRemoveMutation, { data, loading, error }] = useFavoriteRemoveMutation({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useFavoriteRemoveMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteRemoveMutation, FavoriteRemoveMutationVariables>) {
        return Apollo.useMutation<FavoriteRemoveMutation, FavoriteRemoveMutationVariables>(FavoriteRemoveDocument, baseOptions);
      }
export type FavoriteRemoveMutationHookResult = ReturnType<typeof useFavoriteRemoveMutation>;
export type FavoriteRemoveMutationResult = Apollo.MutationResult<FavoriteRemoveMutation>;
export type FavoriteRemoveMutationOptions = Apollo.BaseMutationOptions<FavoriteRemoveMutation, FavoriteRemoveMutationVariables>;
export const UserUpdateDocument = gql`
    mutation userUpdate($userId: Int!, $data: SignUp2Input!) {
  userUpdate(userId: $userId, data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, baseOptions);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserNewDocument = gql`
    mutation userNew($data: UserNewInput!) {
  userNew(data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useUserNewMutation__
 *
 * To run a mutation, you first call `useUserNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userNewMutation, { data, loading, error }] = useUserNewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserNewMutation(baseOptions?: Apollo.MutationHookOptions<UserNewMutation, UserNewMutationVariables>) {
        return Apollo.useMutation<UserNewMutation, UserNewMutationVariables>(UserNewDocument, baseOptions);
      }
export type UserNewMutationHookResult = ReturnType<typeof useUserNewMutation>;
export type UserNewMutationResult = Apollo.MutationResult<UserNewMutation>;
export type UserNewMutationOptions = Apollo.BaseMutationOptions<UserNewMutation, UserNewMutationVariables>;
export const UsernameUpdateDocument = gql`
    mutation usernameUpdate($userId: Int!, $data: UsernameInput!) {
  usernameUpdate(userId: $userId, data: $data) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useUsernameUpdateMutation__
 *
 * To run a mutation, you first call `useUsernameUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsernameUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usernameUpdateMutation, { data, loading, error }] = useUsernameUpdateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUsernameUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UsernameUpdateMutation, UsernameUpdateMutationVariables>) {
        return Apollo.useMutation<UsernameUpdateMutation, UsernameUpdateMutationVariables>(UsernameUpdateDocument, baseOptions);
      }
export type UsernameUpdateMutationHookResult = ReturnType<typeof useUsernameUpdateMutation>;
export type UsernameUpdateMutationResult = Apollo.MutationResult<UsernameUpdateMutation>;
export type UsernameUpdateMutationOptions = Apollo.BaseMutationOptions<UsernameUpdateMutation, UsernameUpdateMutationVariables>;
export const UserDocument = gql`
    query user($where: UserWhereUniqueInput!) {
  user(where: $where) {
    ...FullUserFragment
  }
}
    ${FullUserFragmentFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const TotalVotesDocument = gql`
    query totalVotes {
  totalVotes
}
    `;

/**
 * __useTotalVotesQuery__
 *
 * To run a query within a React component, call `useTotalVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalVotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalVotesQuery(baseOptions?: Apollo.QueryHookOptions<TotalVotesQuery, TotalVotesQueryVariables>) {
        return Apollo.useQuery<TotalVotesQuery, TotalVotesQueryVariables>(TotalVotesDocument, baseOptions);
      }
export function useTotalVotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalVotesQuery, TotalVotesQueryVariables>) {
          return Apollo.useLazyQuery<TotalVotesQuery, TotalVotesQueryVariables>(TotalVotesDocument, baseOptions);
        }
export type TotalVotesQueryHookResult = ReturnType<typeof useTotalVotesQuery>;
export type TotalVotesLazyQueryHookResult = ReturnType<typeof useTotalVotesLazyQuery>;
export type TotalVotesQueryResult = Apollo.QueryResult<TotalVotesQuery, TotalVotesQueryVariables>;
export const VotesDocument = gql`
    query votes($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput], $skip: Int, $take: Int, $distance: DistanceInput) {
  votes(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
    distance: $distance
  ) {
    ...NotForProfitFragment
  }
  votesCount(where: $where, distance: $distance)
}
    ${NotForProfitFragmentFragmentDoc}`;

/**
 * __useVotesQuery__
 *
 * To run a query within a React component, call `useVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      distance: // value for 'distance'
 *   },
 * });
 */
export function useVotesQuery(baseOptions?: Apollo.QueryHookOptions<VotesQuery, VotesQueryVariables>) {
        return Apollo.useQuery<VotesQuery, VotesQueryVariables>(VotesDocument, baseOptions);
      }
export function useVotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VotesQuery, VotesQueryVariables>) {
          return Apollo.useLazyQuery<VotesQuery, VotesQueryVariables>(VotesDocument, baseOptions);
        }
export type VotesQueryHookResult = ReturnType<typeof useVotesQuery>;
export type VotesLazyQueryHookResult = ReturnType<typeof useVotesLazyQuery>;
export type VotesQueryResult = Apollo.QueryResult<VotesQuery, VotesQueryVariables>;
export const VoteAddDocument = gql`
    mutation voteAdd($sellerId: Int!, $vote: Int!) {
  voteAdd(sellerId: $sellerId, vote: $vote) {
    fromId
    toId
    vote
  }
}
    `;

/**
 * __useVoteAddMutation__
 *
 * To run a mutation, you first call `useVoteAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteAddMutation, { data, loading, error }] = useVoteAddMutation({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *      vote: // value for 'vote'
 *   },
 * });
 */
export function useVoteAddMutation(baseOptions?: Apollo.MutationHookOptions<VoteAddMutation, VoteAddMutationVariables>) {
        return Apollo.useMutation<VoteAddMutation, VoteAddMutationVariables>(VoteAddDocument, baseOptions);
      }
export type VoteAddMutationHookResult = ReturnType<typeof useVoteAddMutation>;
export type VoteAddMutationResult = Apollo.MutationResult<VoteAddMutation>;
export type VoteAddMutationOptions = Apollo.BaseMutationOptions<VoteAddMutation, VoteAddMutationVariables>;