import React from "react"
import {InView} from 'react-intersection-observer';

const InfinityScroll = ({onEnd, loading}: { onEnd: () => Promise<any>, loading?: boolean }) => {
    // @ts-ignore
    return (<InView
        initialInView={false}
        skip={loading}
        rootMargin={'400px'}
        onChange={async (inView, entry) => {
            if (inView) {
                await onEnd()
            }
        }}>
        {''}
    </InView>)
}
export default InfinityScroll



