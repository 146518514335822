import {Link, useHistory, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {Dropdown, Nav, Navbar, NavDropdown, NavDropdownProps, NavLinkProps} from 'react-bootstrap';
import {DropdownItemProps} from 'react-bootstrap/DropdownItem';
import UserAvatar from "../../common/UserAvatar";
import {useMeQuery} from "lib/src/generated";
import {useApolloClient} from "@apollo/client";
import {CachePersistor, LocalStorageWrapper} from "apollo3-cache-persist";
import confirmModal from "../../common/ConfirmModal";
import { useIdleTimer } from 'react-idle-timer'
const POLL_INTERVAL=1000*60*60
const Header = () => {
    const {data, startPolling, stopPolling, refetch}=useMeQuery({pollInterval: POLL_INTERVAL})
    const handleOnIdle = ()=> {
        stopPolling()
    }
    const handleOnActive = ()=> {
        refetch().then(()=>startPolling(POLL_INTERVAL))
    }
    useIdleTimer({
        timeout: 1000*60*2,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 750
    })
    const [expanded, setExpanded] = useState(false);
    const client = useApolloClient();
    const history = useHistory();
    const logout = async () => {
        document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        const persist = new CachePersistor({
            cache: client.cache,
            // @ts-ignore
            storage: new LocalStorageWrapper(window.localStorage),
        });
        await persist.purge()
        await client.clearStore()
        setTimeout(async () => {
            await client.resetStore()
            await history.push('/sign-in')

        })


    };
    const user=data && data.me && data.me
    const {verified, username, tax, step2} = user || {}
    const profile = !verified ? '/register' : '/member/account/profile-update';
    const admin = username === 'fecadmin'
    const {pathname} = useLocation();
    useEffect(() => {
        if (expanded) {
            setExpanded(false)
        }
        window.scrollTo(0, 0);
    }, [pathname]);
    if (data && data.me && data.me.status!='Active'){
        stopPolling()
        confirmModal({
            backdropClassName: 'black-backdrop',
            title: `Your account has been ${data.me.status}`,
            content: <p>
                {`Your account has been ${data.me.status}`}<br/>
                Please contact us for support
                </p>,
            okText: 'Close',
            cancelText: '',
            onConfirm: ()=>null,
        }).render()
        setTimeout(async () => {
            logout().catch(console.error)
        })
        return null
    }
    return (
        <Navbar expanded={expanded} bg="light" expand="lg">
            <Link to={'/'}>
                <Navbar.Brand>
                    <img src={'/images/logo.png'} className={'d-none d-lg-block  d-lg-block"'}
                         style={{width: 'auto', height: 56}} height={56} width={200}
                         alt={'Logo'}/>
                    <img src={'/images/logo-xs.png'} className={'d-lg-none'} style={{width: 'auto', height: 56}}
                         height={56} width={77}
                         alt={'Logo'}/>
                </Navbar.Brand>
            </Link>
            {user && <Navbar.Text className=" justify-content-center text-center" style={{flex: 50}}>
                <h3 className={'text-center'}><span className={'d-none d-md-inline'}>Your ID: </span><span
                    className={'text-uppercase'}>{user.username}</span></h3>
            </Navbar.Text>}
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>

            <Navbar.Collapse id="basic-navbar-nav" className=" justify-content-end">
                <Nav>
                    <ActiveLink to="/directory">
                        Directory
                    </ActiveLink>
                    <ActiveLink to="/directory-not-for-profit">
                        Not-For-Profits
                    </ActiveLink>
                    {user ? (
                        <>
                            {admin && <ActiveLink to="/admin/members" disabled={!verified}>
                                Members
                            </ActiveLink>}
                            {admin && <ActiveLink to="/admin/votes" disabled={!verified}>
                                Votes
                            </ActiveLink>}
                            {!admin && verified && step2 && tax && <ActiveLink to="/member/sales" disabled={!verified}>
                                Sales
                            </ActiveLink>}
                            {!admin && verified && step2 && <ActiveLink to="/member/purchases" disabled={!verified}>
                                Purchases
                            </ActiveLink>}
                            <ActiveNavDropdown
                                to="/member/account"
                                alignRight
                                title="Account"
                                id="basic-nav-dropdown"
                            >
                                <Dropdown.Header className={'avatar'}>
                                    <UserAvatar {...user} />
                                    <span className={'text-uppercase'}>{user.username}</span>
                                </Dropdown.Header>
                                <NavDropdown.Divider/>
                                <ActiveDropdown to={profile}>
                                    Update Profile
                                </ActiveDropdown>
                                <ActiveDropdown disabled={!verified} to="/member/account/password-update">
                                    Change Password
                                </ActiveDropdown>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={()=>logout()}>
                                    Sign-out
                                </NavDropdown.Item>
                            </ActiveNavDropdown>
                        </>
                    ) : (
                        <>
                            <ActiveLink to="/sign-in">Sign In</ActiveLink>
                            <ActiveLink to="/register">Register</ActiveLink>
                        </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

interface ActiveProps {
    to: string;
}


function ActiveDropdown({children, to, ...props}: ActiveProps & DropdownItemProps) {
    const router = useLocation();
    const active = router.pathname === to;
    return (
        <NavDropdown.Item as={Link} to={to} href={to} active={active} {...props}>
            {children}
        </NavDropdown.Item>
    );
}


function ActiveNavDropdown({children, to, ...props}: ActiveProps & NavDropdownProps) {
    const router = useLocation();
    const active = router.pathname.indexOf(to) >= 0;
    return (
        <NavDropdown active={active} {...props}>
            {children}
        </NavDropdown>
    );
}


function ActiveLink({children, to, ...props}: ActiveProps & NavLinkProps) {
    const location = useLocation();
    const active = location.pathname === to;
    return (
        <Nav.Link as={Link} to={to} active={active} {...props}>
            {children}
        </Nav.Link>
    );
}


export default Header;
function useCallBack(arg0: () => void, arg1: string[]) {
    throw new Error('Function not implemented.');
}

