import {FieldFilterType} from "../../../Components/common/Table/Filters";
import {defaultLabel} from "../../../Components/common/Table/Table";
import CellAccessibility from "../../../Components/common/CellAccessibility";
import FavoriteButton from "../../../Components/common/FavoriteButton";


export const directoryFields: FieldFilterType[] = [
    {
        field: 'username',
        plainLabel: 'Member ID',
        label: defaultLabel,
        type: 'String',
        style: {width: 200, textTransform: "uppercase"},
        noFilter: true,
    },
    {
        field: 'name',
        label: defaultLabel,
        plainLabel: `Seller's Name`,
        type: 'String',
        style: {width: 200},
        noFilter: true,
    },
    {field: 'description', label: defaultLabel, plainLabel: 'Description', type: 'String', noFilter: true,},
    {
        field: 'accessibility',
        label: 'Contact',
        type: 'String',
        render: CellAccessibility,
        filterResolver: function ({value: contains}) {
            return {
                OR: [
                    {accessibility: {address: {address: {contains}}}},
                    {accessibility: {phone: {contains}}},
                    {accessibility: {website: {contains}}},
                    {accessibility: {email: {contains}}},
                    //{accessibility: {outcall: {contains}}},
                ]
            }
        }
    },
    {
        field: 'favored.id', label: defaultLabel, plainLabel: 'Fav', type: 'Int', style: {width: 90},
        render: FavoriteButton,
        noFilter: true,
    },
]
