import React, {useEffect, useRef, useState,} from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import {Button, ButtonGroup, Form} from 'react-bootstrap';
import {VscAccount, VscCheck, VscDebugStepBack, VscDebugStepOver, VscTrash} from 'react-icons/vsc';
import {connectField} from "uniforms";
import {wrapField} from "uniforms-bootstrap4";
import classnames from "classnames";

export const SIZE = 250;
const BORDER = 1;
const TOTAL_SIZE = SIZE + 2 * BORDER;


const UploadAvatarField = ({value, onChange, saveButton=false, ...props}: any) => {
  const [image, setImage] = useState<File | string | null>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const wrapper = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const save = () => {
    const editor = editorRef.current;
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const file = canvasScaled.toDataURL('image/png')
      onChange(file)
      if (saveButton){
        setImage(null)
      }

    }
  };
  const wrapperCurrent=wrapper.current
  useEffect(() => {
    const wrapperCurrent=wrapper.current
    if (!wrapperCurrent) return;
    const updateScale = function (e: Event) {
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newScale = scale + e.deltaY / 10;
      setScale(newScale < 1 ? 1 : newScale);
    };
    wrapperCurrent.addEventListener('mousewheel', updateScale, {passive: false});
    return function () {
      if (!wrapperCurrent) return;
      wrapperCurrent.removeEventListener('mousewheel', updateScale);
    };
  },[wrapperCurrent,scale]);

  const style = {
    width: TOTAL_SIZE,
    height: TOTAL_SIZE
  }
  return (wrapField(props as any,
          <Dropzone onDrop={(dropped) => {
            setImage(dropped[0]);
          }} accept={['image/*']} noClick={!!image} noKeyboard>
            {({getRootProps, getInputProps}) => {
              // @ts-ignore
              return (
                  <div>
                    {!!image && (
                        <>
                          <div ref={wrapper} className={'content-centered'}
                               style={{width: SIZE + 2, overflow: 'scroll'}}>
                            <AvatarEditor
                                onImageReady={()=> !saveButton && save()}
                                onImageChange={()=> !saveButton && save()}
                                ref={editorRef}
                                border={BORDER}
                                borderRadius={250}
                                rotate={rotate}
                                width={SIZE}
                                height={SIZE}
                                image={image || value}
                                scale={scale}
                            />
                          </div>
                          <div style={{width: TOTAL_SIZE, height: 34}} className={'content-centered'}>
                            <Form.Control
                                type="range"
                                value={(scale - 1) * 5}
                                onChange={(e) => {
                                  const v = Number(e.target.value);
                                  setScale(1 + (20 * v) / 100);
                                }}
                            />
                            <ButtonGroup size={'sm'}>
                              <Button variant={'default'} onClick={() => setRotate(rotate - 90)}>
                                <VscDebugStepBack size={15}/>
                              </Button>
                              <Button variant={'default'} onClick={() => setRotate(rotate + 90)}>
                                <VscDebugStepOver size={15}/>
                              </Button>
                              <Button variant={'default'} onClick={() => {
                                setRotate(0)
                                setScale(1)
                                setImage(null)
                                onChange(null)
                              }}>
                                <VscTrash size={15} color={'red'}/>
                              </Button>
                              {saveButton && <Button variant={'default'} onClick={() => {
                                save()
                              }}>
                                <VscCheck size={15} color={'green'}/>
                              </Button>}
                            </ButtonGroup>
                          </div>
                        </>
                    )}
                    {!image && (
                        <>
                          {/*@ts-ignore */}
                          <div
                              style={{
                                ...style,
                                outline: `${BORDER}px solid rgba(0,0,0,.5)`,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              {...getRootProps()}
                              className={'pointer '}
                          >
                            <div className={classnames('dropzone-content', {'with-image': !!value})}>
                              {value && <img
                                  className={'dropzone-image'}
                                  src={value}
                                  alt={'Dropzone'}
                                  style={style}/>}
                              <p className={'dropzone-text'} style={style}>
                                <VscAccount size={50}/>
                                <br/>
                                Drag 'n' drop some files here, or click to select files
                              </p>
                            </div>
                          </div>
                          {/*@ts-ignore */}
                          <input {...getInputProps()} />
                          <div style={{width: TOTAL_SIZE, height: 34}} className={'content-centered'}>
                            {value && <ButtonGroup size={'sm'}>
                              <Button variant={'default'} onClick={() => {
                                setRotate(0)
                                setScale(1)
                                setImage(null)
                                onChange(null)
                              }}>
                                <VscTrash size={15} color={'red'}/>
                              </Button>
                            </ButtonGroup>}
                          </div>
                        </>
                    )}
                  </div>
              );
            }}
          </Dropzone>)
  );
};
export default connectField(UploadAvatarField);
