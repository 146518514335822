import React, {ReactNode, useEffect, useRef, useState} from 'react';
import Table, {FieldType} from "./Table";
import {FieldFilterType, Filter} from "./Filters";
import InfinityScroll from "./InfinityScroll";
import Cards from "./Cards";
import {Button, ButtonGroup, ButtonToolbar, Col, Row} from "react-bootstrap";
import {VscListFlat, VscSplitVertical} from 'react-icons/vsc';

interface TableWrapperProps {
    fields: FieldFilterType[],
    take?: number
    rows: any[]
    total?: number
    refetch: any
    fetchMore: any
    variables: any
    loading: boolean
    error?: any
    rightTop?: ReactNode
    noFilter?: boolean

}

export interface WrappedProps {
    loading?: boolean
    data?: any[],
    fields: FieldType[]
    page?: number
    total?: number
}

export function Wrapper({
                            fields,
                            error,
                            rows,
                            total,
                            refetch,
                            fetchMore,
                            loading,
                            variables,
                            rightTop,
                            Component,
                            noFilter = false,
                        }: TableWrapperProps & { Component: React.FC<WrappedProps> }) {
    const {orderBy, where, skip, take} = variables
    const originalWhere = useRef(where)
    fields = fields.map(field => {
        const clone = {...field}
        if (typeof clone.label === 'function') {
            clone.label = clone.label({refetch, fetchMore, orderBy, skip, loading, take, rows, total})
        }
        return clone
    })


    const onFilter = (filters: any[]) => {
        const where = (filters.length) ? {AND: [...filters,originalWhere.current], } : originalWhere.current
        refetch({where, skip: 0})
    }
    return (
        <>
            {!noFilter && <Filter onFilter={onFilter} fields={fields} clean={rows && rows.length === 0}/>}
            <Row>
                <Col>
                    <span className={'total'}>Total Results: {total === undefined ? '...' : total}</span>
                </Col>
                <Col>
                    {/*// @ts-ignore*/}
                    {rightTop}
                </Col>
            </Row>
            <Component page={take} total={total} data={rows} loading={loading} fields={fields}/>
            <InfinityScroll loading={loading} onEnd={async () => {
                if (loading || error || total === undefined || !rows || rows.length >= total || skip > total) return
                const newSkip = skip + take
                return fetchMore({variables:{skip: newSkip, take}})
            }}/>
        </>
    );
}


export function ResponsiveWrapper({
                                      fields,
                                      error,
                                      rows,
                                      total,
                                      refetch,
                                      fetchMore,
                                      loading,
                                      variables
                                  }: TableWrapperProps) {
    const [overflow, setOverflow] = useState(null)
    useEffect(() => {
        function updateSize() {
            const tables = document.getElementsByTagName('table')
            const table = tables && tables[0]
            if (table) {
                const parent = table.parentElement
                const isOverflow = table.clientWidth > parent.clientWidth
                if (overflow === null) {
                    setOverflow(isOverflow)
                }
            }
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [overflow]);
    const Component = overflow ? Cards : Table
    const rightTop = <ButtonToolbar style={{justifyContent: 'flex-end'}}>
        <ButtonGroup size={"sm"} onClick={() => setOverflow(!overflow)}>
            <Button variant={overflow || overflow === null ? "light" : "primary"}>
                <VscSplitVertical/>
            </Button>
            <Button variant={!overflow ? "light" : "primary"}>
                <VscListFlat/>
            </Button>
        </ButtonGroup>
    </ButtonToolbar>
    return <div style={{opacity: overflow === null ? 0 : 1}}>
        <Wrapper rightTop={rightTop} Component={Component} {...{
            fields,
            error,
            rows,
            total,
            refetch,
            fetchMore,
            loading,
            variables
        }}/>
    </div>
}

function TableWrapper({fields, error, rows, total, refetch, fetchMore, loading, variables}: TableWrapperProps) {
    return <Wrapper Component={Table} {...{fields, error, rows, total, refetch, fetchMore, loading, variables}}/>
}

export default TableWrapper


export const getRowsAndTotal = (data: Object) => {
    if (!data) return {}
    let rows: any, total: number | undefined
    const keys = Object.keys(data)
    if (keys.length < 2) console.warn('no total detected')
    if (keys.length > 2) console.warn('too many queries')
    keys.forEach(key => {
        const val = data[key]
        if (typeof val === 'number') total = val
        if (Array.isArray(val)) rows = val
    })
    return {rows, total}
}

