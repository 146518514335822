import React, {FC} from 'react';
import {Col, Row} from "react-bootstrap";
import {VscInbox} from "react-icons/vsc";
import {WrappedProps} from "./Table/TableWrapper";
import FavoriteButton from "../common/FavoriteButton";
import {User} from "lib/src/generated";
import UserAvatar from "../common/UserAvatar";
import './directory-cards.scss'
import ContactDetailButton from './ContactDetailButton';



interface DirectoriesCardProps{
    user: User
    totalVotes?: number
    isVotesList?: boolean
    showVoteBtn?: boolean
    ActionComponent: FC<User>
}

export function DirectoriesCard({user,totalVotes,showVoteBtn, ActionComponent}: DirectoriesCardProps) {
    const {name, username, description, voted} = user;
    return <div className={'directory-container'}>
        <div className={'directory-image'}>
            <div>
                <UserAvatar {...user}/>
                <span className={'text-uppercase'}>{username}</span>
            </div>
        </div>
        <div className={'directory-description'}>
            <div className={'content'}>
                <h2>{name}</h2>
                <p>{description}</p>
            </div>
            {!!(showVoteBtn && totalVotes &&  voted && voted[0]) && <div className={'assigned-funds'}>
                {(100 * voted[0].vote / totalVotes).toFixed(2)}% of your
                available funds are assigned to this Not-For-Profit"
            </div>}
        </div>
        <div className={'directory-favorite'}>
            {showVoteBtn && <div className={'icon-text'}>
                <ActionComponent {...user}/>
            </div>}
            {user.accessibility && <div className={'icon-text'}>
                <ContactDetailButton accessibility={user.accessibility}/>
                Contact
            </div>}
        </div>
    </div>
}

export default function DirectoriesCards({loading, page = 50, data, total, ActionComponent}: WrappedProps & {ActionComponent: FC<User>}) {
    let len = Math.min(0, page, data ? total - data.length : page)
    if (len < 1) len = 1
    const mock = new Array(len).fill((data && data[0]) || {});
    return <div style={{marginTop: 10}} className={'directory-list'}>
        {data && data.map((item: any, index) => (<ActionComponent key={index} {...item}/>))}
        {data && data.length === 0 && !loading && <Row>
            <Col className={'no-data'}>
                <b>No Results</b>
                <br/>
                <VscInbox size={60}/>
            </Col>
        </Row>}
        <div className={'spinner-child'}>{loading && mock.map((item: any, index) => (<ActionComponent key={index} {...item}/>))}</div>
    </div>

}

