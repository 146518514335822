import React, {useCallback, useMemo, useRef, useState} from "react";
import {buildASTSchema} from "graphql";
import {parse} from "graphql/language/parser";
import {GraphQLType} from "graphql/type/definition";
import GraphQLBridge from "uniforms-bridge-graphql";
import {AutoField, AutoForm} from "uniforms-bootstrap4";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {VscSearch, VscTrash} from "react-icons/vsc";
import './filter.scss'
import {FieldType} from "./Table";
import {
    FilterResolver,
    getAnyTextFieldResolver,
    getDefaultFilterResolver,
    getDefaultOperator,
    OperatorType
} from "./utils";


export type FieldFilterType =
    FieldType
    & { noFilter?: boolean, filterResolver?: FilterResolver, plainLabel?: string, extra?: any }

export function Filter({onFilter, clean, fields}: { fields: FieldFilterType[], onFilter: any, clean?: boolean }) {
    const filterCount = fields.filter(({noFilter}) => !noFilter).length
    const hasFilter = filterCount > 0
    const anyField = useMemo(() => ({
        field: 'Any Text Field',
        label: 'Any Text Field',
        plainLabel: 'Any Text Field',
        extra: {},
        filterResolver: getAnyTextFieldResolver(fields, !hasFilter),
        type: 'String',
    }), [fields, hasFilter])
    const finalFields = useMemo(() => [anyField, ...fields.filter(({noFilter}) => !noFilter)], [anyField, fields])
    const [operator, setOperator] = useState<OperatorType>(getDefaultOperator(finalFields[0]));
    const [field, setField] = useState<string>(finalFields[0].field);
    const currentFilters = useRef([]);
    let {type, extra} = finalFields.find(f => f.field === field)
    if (type === 'DateTime') type = 'Float'
    const schema = buildASTSchema(parse(`scalar DateTime\ninput FI{ filter: ${type} }`)).getType('FI') as GraphQLType
    const filterSchema = new GraphQLBridge(schema, () => null, {filter: extra})
    const isSelect = !!(extra && extra.allowedValues)
    const _onFilter = useCallback((filters) => {
        currentFilters.current = filters
        const finalFilters = []
        filters.forEach((filter) => {
            const confField = finalFields.find(f => f.field === filter.field)
            if (!confField) return
            const filterResolver = confField.filterResolver || getDefaultFilterResolver(confField)
            finalFilters.push(filterResolver(filter))
        })
        onFilter(finalFilters)
    }, [finalFields, onFilter])
    const form = useRef<any>(null);
    return (
        <AutoForm autosave={isSelect} ref={form} onSubmit={(model: any) => {

            const value = model.filter
            if (!value) return
            if (clean) {
                _onFilter([{field, operator, value}])
            } else {
                _onFilter([...currentFilters.current, {field, operator, value}])
            }
            if (isSelect) {
                setOperator(getDefaultOperator(finalFields[0]))
                setField(finalFields[0].field)
            }
            form.current.reset()
        }} label={false} schema={filterSchema}>
            <FormGroup>
                <div className={'filters'}>
                    {hasFilter && <div className={'fields'}>
                        <Form.Control as="select" value={field} onChange={(e) => {
                            const value = e.target.value
                            setField(value)
                            setOperator(getDefaultOperator(finalFields.find(f => f.field === value)))
                        }} custom>
                            {finalFields.map((field, index) => {
                                    return <option value={field.field} key={field.field}>{
                                       ( field.plainLabel || field.label || '').toString()
                                    }</option>;
                                }
                            )}
                        </Form.Control>
                    </div>}
                    {/*{hasFilter && !isSelect && <div className={'operators'}>*/}
                    {/*    <Form.Control as="select" value={operator}*/}
                    {/*                  onChange={(e) => setOperator(e.target.value as OperatorType)}*/}
                    {/*                  custom>*/}
                    {/*        {type === 'String' && <option value={'contain'}>Contain</option>}*/}
                    {/*        <option value={'equal'}>Equal</option>*/}
                    {/*        <option value={'gt'}>Greater than</option>*/}
                    {/*        <option value={'lt'}>Less than</option>*/}
                    {/*    </Form.Control>*/}
                    {/*</div>}*/}
                    <div className={'filter'}>
                        <AutoField name={'filter'}/>
                    </div>
                    {!isSelect && <div className={'submit'}>
                        <Button onClick={() => form.current.submit()}><VscSearch/></Button>
                    </div>}
                </div>
                <Row>
                    <Col>
                        {currentFilters.current.map(({field, operator, value, ...rest}, index) => {
                                const label = finalFields.find(ff => ff.field === field).plainLabel
                                return <Button
                                    onClick={() => {
                                        const clone = [...currentFilters.current]
                                        clone.splice(index, 1)
                                        _onFilter(clone)
                                    }}
                                    style={{marginRight: 4}}
                                    key={index}
                                    size={'sm'}
                                    variant="primary">{label} {operator} {value} <VscTrash color={'red'}/></Button>;
                            }
                        )}
                    </Col>
                </Row>
            </FormGroup>
        </AutoForm>
    )
}


export interface RefetchVariablesInterface {
    skip?: number,
    orderBy?: any //NexusGenInputs[Type + 'OrderByInput'],
    where?: any,
}

// export interface DefaultLabelOrder<any> {
//     onClick: any
//     label: any
//     // orderBy: Dispatch<SetStateAction<any>>,
//     // setOrderBy: Dispatch<SetStateAction<any>>,
//     // setSkip: Dispatch<SetStateAction<any>>,
//     // refetch: (variables?: { variables: RefetchVariablesInterface }) => Promise<ApolloQueryResult<TData>>
// }

