import {History} from 'history';
import {User} from "lib/src/generated";

export const redirect = async ({
                                 tax,
                                 type,
                                 step2,
                                 verified
                               }: Pick<User, 'tax' | 'type' | 'step2' | 'verified'>, history: History) => {
  if (type === 'Admin') return history.replace('/admin/members');
  if (step2 && verified) {
    if (tax) {
      await history.replace('/member/sales');
    } else {
      await history.replace('/member/purchases');
    }
  } else {
    await history.replace('/register');
  }
};
