import React, {useState} from 'react'
import {Carousel} from 'react-bootstrap'

function AddPWAIosInstructions() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const next = () => {
        if (index >= 2) return setIndex(0)
        setIndex(index + 1)
    }
    const ratio = 1125 / 2000
    return <div style={{display: 'flex', alignItems: 'center', flexDirection: "column"}}>
            <div style={{width: window.innerHeight / 1.3 * ratio, position: 'relative', textAlign: 'center'}}>
            <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                onClick={next}
            >
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/images/screenshots/1.png"
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/images/screenshots/2.png"
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/images/screenshots/3.png"
                        alt="First slide"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
        </div>
}

export default AddPWAIosInstructions