import React from "react"
import {User} from "lib/src/generated";
import { VscAccount } from "react-icons/vsc";
import {SIZE} from "../common/UploadAvatarField";

const UserAvatar = ({image}: Pick<User, 'image'>) => {
    return <div  className={'avatar'} >
        {image ? <img src={image} alt={'Avatar'} /> : <VscAccount size={SIZE}/>}
    </div>
}
export default UserAvatar

