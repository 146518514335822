import {get} from "lodash"
import {FieldType} from "./common/Table/Table";

export function showDateTime(date: Date | string) {
    if (!date) return ''
    if (!(date instanceof Date)) date= new Date(date)
    return Intl.DateTimeFormat('en-AU',{
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
    }).format(date)
}

export function showDate(date: Date | string) {
    if (!date) return ''
    if (!(date instanceof Date)) date= new Date(date)
    return Intl.DateTimeFormat('en-AU',{
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
    }).format(date)
}


export function renderCurrency(this:FieldType, item: any) {
    const val=get(item,this.field)
    return val!==undefined && val!==null  ? `$${format2Dec(val)}` : ''

}export function format2Dec( val: number | undefined | null) {
    return val!==undefined && val!==null  ? `${
        new Intl.NumberFormat('en-AU',{minimumFractionDigits: 2,maximumFractionDigits:2}).format(Number(val))
    }` : ''

}

export function showCurrency(amount: number){
    return `$${
      new Intl.NumberFormat('en-AU',{minimumFractionDigits: 2,maximumFractionDigits:2}).format(Number(amount))
    }`
}

