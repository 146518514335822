import React, {CSSProperties, ReactNode} from 'react';
import {Button, Spinner, Table} from "react-bootstrap";
import {VscChevronDown, VscChevronUp, VscInbox} from "react-icons/vsc";
import './table.scss'
import {get, set} from 'lodash'
import {FieldFilterType} from './Filters';
import {WrappedProps} from "./TableWrapper";



type AccessorType = string | ((val: any) => ReactNode)

export type FieldType = {
    label:  ReactNode | ((val: any) => ReactNode)
    style?: CSSProperties,
    field: string,
    type: string,
    hidden?: boolean,
    render?: AccessorType
    [other: string]: any
}
export function ShowItem({field, item}: { field: FieldType, item: any }) {
    return typeof field.render === 'function' ?
        item ? field.render(item) : <>&nbsp;</>
        :
        (get(item, field.field) || null)
}



function Rows({data, fields, mocking}: { data: any, fields: FieldType[], mocking?:boolean }) {
    if (!data) return null
    return data.map((item: any, indexTr) => {
        return (
            <tr key={indexTr}>
                {fields.map((field, indexTd) => {
                    if (field.hidden) {
                        return null
                    }
                    return <td key={indexTd}>
                        {mocking && indexTd === 0 && indexTr === 0 &&
                        <div className={'loading-tfoot-spinner'}>
                            <Spinner animation={"border"}/>
                        </div>
                        }
                        <ShowItem item={item} field={field}/>
                    </td>;
                })}
            </tr>
        );
    })
}

export default function MyTable({loading, page = 50, data, total, fields}: WrappedProps)  {
    const len = Math.min(page, data ? total - data.length : page) || 1
    const mock = new Array(len).fill(data && data[0]);
    return <Table bordered size="lg" responsive>
        <thead>
        <tr>
            {fields.map((field, index) => {
                if (field.hidden) {
                    return null
                }
                return <th key={index} style={field.style}>
                    {
                        typeof field.label === 'function' ?
                          field.label(field)
                          :
                          field.label
                    }
                </th>;
            })}
        </tr>
        </thead>
        <tbody>
        <Rows data={data} fields={fields}/>
        {data && data.length === 0 && !loading && <tr>
            <td colSpan={fields.length} className={'no-data'}>
                <b>No Data</b>
                <br/>
                <VscInbox size={60}/>
            </td>
        </tr>}
        </tbody>
        <tfoot className={'loading-tfoot'}>
        {loading && <Rows data={mock} fields={fields}/>}
        </tfoot>

    </Table>

}



export function defaultLabel(this: FieldFilterType, {refetch, orderBy: actualOrderBy}: any){
    let actualDirection = get(actualOrderBy, this.field)
    return <Button variant={'link'} onClick={() => {
        const direction = actualDirection && actualDirection === 'asc' ? 'desc' : 'asc'
        const orderBy: any = set({}, this.field, direction)
        refetch({orderBy,skip:0})
    }
    }>{this.plainLabel}
        { actualDirection && (actualDirection === 'asc' ? <VscChevronUp/> :
            <VscChevronDown/>)}
    </Button>
}


