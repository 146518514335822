import React, {useRef} from "react";
import {AutoForm, TextField} from "uniforms-bootstrap4";
import {buildASTSchema} from "graphql";
import {parse} from "graphql/language/parser";
import {GraphQLType} from "graphql/type/definition";
import GraphQLBridge from "uniforms-bridge-graphql";
import SearchDistanceField from './DistanceSearchField'
import {SellersQuery, SellersQueryVariables} from "lib/src/generated";
import {ApolloQueryResult} from "@apollo/client";
import './directory-search.scss'
import {directoryFields} from "./directoryFields";
import {getAnyTextFieldResolver} from "../../../Components/common/Table/utils";
import {VscClose, VscSearch} from "react-icons/vsc";
import {Button, Col, Row} from "react-bootstrap";

const astSchema = (parse(`
input AddressDistanceInput {
  address: String!
  city: String!
  country: String!
  id: Int!
  lat: Float!
  lng: Float!
  number: String!
  placeId: String!
  state: String!
  street: String!
  suburb: String
  unit: String
  zip: String!
}
input DistanceInput{ 
    address: AddressDistanceInput!
    range: Int! 
}
input DirectorySearchInput{
    query: String
    distance: DistanceInput
}

`))
const schema = buildASTSchema(astSchema).getType('DirectorySearchInput') as GraphQLType
const bridge = new GraphQLBridge(schema, (model) => {
    if (model.distance && model.distance.address && model.distance.address.address && !model.distance.address.lat) {
        return {details: [{name: 'distance.address', message: 'Please click a location from the list powered by Google'}]}
    }
    return null
}, {})


const resolver = getAnyTextFieldResolver(directoryFields, true)

function DirectorySearch({
                             placeholder="Search",
                             refetch,
                             variables,
                         }: any & { refetch: (variables?: Partial<SellersQueryVariables>) => Promise<ApolloQueryResult<SellersQuery>> }) {
    const form = useRef(null)
    const querys = variables.where && variables.where.OR && variables.where.OR[0] && variables.where.OR[0].OR && variables.where.OR[0].OR[0] && variables.where.OR[0].OR[0].username && variables.where.OR[0].OR
    const query = (querys && querys.map(({username}) => username.contains).join(' ')) || ''
    const model = {distance: {range: 0, address: {}, ...variables.distance,}, query,}
    const distance = variables.distance && variables.distance.address && variables.distance.address.address
    const search = (model: any) => {
        const ors = resolver({value: model.query, operator: 'contains'})
        const where = model.query ? ors : {}
        if (!model.distance || !model.distance.address) {
            refetch({
                skip: 0,
                where,
                distance: undefined,
            })
        } else {
            refetch({
                skip: 0,
                where,
                distance: {
                    address: {
                        lat: Number(model.distance.address.lat),
                        lng: Number(model.distance.address.lng),
                        ...model.distance.address
                    },
                    range: parseInt(model.distance.range)
                }
            })
        }
    }
    return <div className={'marginBottom2'}><AutoForm schema={bridge}
                                                      showInlineError
                                                      model={model}
                                                      ref={form}
        // autosave
        // autosaveDelay={500}
                                                      onSubmit={async (model) => {
                                                          form.current.reset()
                                                          search(model)
                                                      }}
    >
        <Row className={'no-gutters all-bordered'}>
            <Col xs={12} sm={12} lg={5} xl={5}>
                <div className={'search-inline shadow'}>
                    <TextField placeholder={placeholder} label={false} name={'query'}/>
                    {model.query && <button
                        type={'button'}
                        aria-label={'Clean query search'}
                        className={'form-control-like clear-query'}
                        onClick={(e) => {
                            const model = {...variables}
                            delete model.query
                            search(model)
                        }}><VscClose/></button>}
                </div>
            </Col>
            <Col xs={12}  sm={12} lg={6} xl={6}>
                <div className={'search-inline shadow'}>
                    <SearchDistanceField placeholder={'Enter a suburb or other location'} label={false}
                                         name={'distance'}/>
                </div>
            </Col>
            <Col xs={12}  sm={12} lg={1} xl={1} >
                <Button type={'input'} block onClick={() => form.current.submit()}
                        aria-label={'search'}><VscSearch/></Button>
            </Col>
        </Row>
    </AutoForm>
        <div style={{height: 10}}/>
        {(query || distance) &&
        <p
            style={{marginRight: 4}}
        >{query && `Showing result for "${query}"`} {distance && `within ${variables.distance.range / 1000} km from ${variables.distance.address.address}`}</p>}

        {/*{variables.distance && variables.distance.address && variables.distance.address.address && <Button*/}
        {/*    type={'button'}*/}
        {/*    onClick={() => {*/}
        {/*        const model = {...variables}*/}
        {/*        delete model.distance*/}
        {/*        search(model)*/}
        {/*    }}*/}
        {/*    style={{marginRight: 4}}*/}
        {/*    size={'sm'}*/}
        {/*    variant="primary"> Within {variables.distance.range / 1000} km from {variables.distance.address.address}*/}
        {/*    <VscChromeClose*/}
        {/*        style={{marginLeft: 15}}*/}
        {/*        color={'red'}/></Button>}*/}
    </div>
}


export default DirectorySearch;

