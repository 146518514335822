import React from "react"
import ShowShortAddress from "./ShowShortAddress"

const CellAccessibility = ({accessibility}: any) => {
    if (!accessibility) return null
    return <p>
        {accessibility.address && <>
            <b>Address: </b>
            <ShowShortAddress {...accessibility.address}/><br/>
        </>}
        {accessibility.phone && <>
            <b>Phone: </b>
            <a target={'_blank'} rel="noreferrer"  href={`tel:${accessibility.phone}`}> {accessibility.phone}</a><br/>
        </>}
        {accessibility.email && <>
            <b>Email: </b>
            <a target={'_blank'} rel="noreferrer"  href={`mailto:${accessibility.email}`}> {accessibility.email}</a><br/>
        </>}
        {accessibility.website && <>
            <b>Website: </b>
            <a target={'_blank'} rel="noreferrer"   href={`${addProtocol(accessibility.website)}`}> {accessibility.website}</a><br/>
        </>}
        {/*{accessibility.outcall && <>*/}
        {/*    <b>Outcall: </b> {accessibility.outcall}<br/>*/}
        {/*</>}*/}
    </p>

}
export default CellAccessibility


const addProtocol=(url:string)=>{
    url=url.toLowerCase()
    if (url.indexOf('http')!==0){
        return `http://${url}`
    }
    return url
}
