import React, {ReactChild} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';
import './spinner-container.css'

interface SpinnerContainerInterface {
  loading?: boolean;
  children: ReactChild;
  visible?: boolean;
  className?: string;
}

const SpinnerContainer:
    React.FC<JSX.IntrinsicElements['div'] &
        SpinnerContainerInterface> = ({
                               loading,
                               children,
                               visible = true,
                               className='',
                               ...props
                             }) => {
  return (
      <div className={`spinner-container ${className}`} {...props}>
          {(!loading || visible) && <div className={classnames({'spinner-child': !!loading})}>{children}</div>}
        {loading && <Spinner animation={'border'}/>}
      </div>
  );
};
export default SpinnerContainer;
