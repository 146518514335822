import React from 'react'
import {Col, Container, Row} from "react-bootstrap";

function Footer() {
    return <footer className={'dark-footer'}>
        <span className={'version'}>V 1.0.3</span>
        <Container>
            <Row>
                <Col xs={12} sm={3}>
                    <a href={'https://fractaleconomy.coop/terms-and-conditions/'} target={'_blank'} rel={'noopener'}>
                        Terms & Conditions
                    </a>
                </Col>
                <Col xs={12} sm={3}>
                    <a href={'https://fractaleconomy.coop/privacy-policy/'} target={'_blank'} rel={'noopener'}>
                        Privacy Policy
                    </a>
                </Col>
                <Col xs={12} sm={3}>
                    <a href={'https://fractaleconomy.coop/contact-us/'} target={'_blank'} rel={'noopener'}>
                        Contact Us
                    </a>
                </Col>
                <Col xs={12} sm={3}>
                    <a href={'https://fractaleconomy.coop'} target={'_blank'} rel={'noopener'}>
                        FEC Homepage
                    </a>
                </Col>
            </Row>
        </Container>
    </footer>
}

export default Footer