import React, {PropsWithChildren} from 'react';

interface AuthProps {
  title: string;
  subTitle?: string;
}

const AuthContainer: React.FC<PropsWithChildren<AuthProps>> = ({ subTitle, title, children }) => {
  return (
    <div>
      <br />
      <h1>{title}</h1>
      {subTitle && <p>{subTitle}</p>}
      <br />
      {children}
    </div>
  );
};
export default AuthContainer;
